import {
	ProductObj,
	SetExpectedProfitParam,
	SetProductParam,
	SetSummaryTableParam,
} from '@peoplefund/slices/pl-investing.model';
import { FOURTY_PERCENT } from '@peoplefund/constants/pl-investing';
import { floorByDefaultUnit } from '@peoplefund/utils/pl-investing-calculator.util';
import { PageData } from '@peoplefund/slices/common-investing';

export type ProductListServerProps = {
	uri: string;
	loan_application_id: number;
	loan_application_purpose: string;
	loan_application_term: number;
	loan_application_amount: number;
	interest_rate: number;
	pf_grade: string;
	nice_credit_score: number;
	funding_rate: number;
	user_investment_amount: number | null;
	total_investment_amount: number;
	underlying_asset_id: number;
	investment_id: number | null;
	product_page_url?: string;
	base_yield_rate_type: string;
};

export type ProfitInfoServerProps = {
	total_interest: number;
	total_interest_percent: number;
	total_interest_with_tax: number;
	total_interest_with_tax_percent: number;
	total_loss_amount: number;
	total_platform_fee: number;
	total_tax: number;
};

export type ProductServerProps = {
	funding_rate: number;
	funded_amount: number;
	total_funding_amount: number;
};

export const LIMIT_PAGE_SIZE = 10;

export const convertProductListResponse = (
	response: ProductListServerProps[],
	productInvestLimit: number
): ProductObj => {
	const result: ProductObj = {};

	response.forEach((item) => {
		const {
			loan_application_id: id,
			loan_application_purpose: loanPurpose,
			loan_application_term: investTerm,
			loan_application_amount: targetAmount,
			interest_rate: interestRate,
			pf_grade: pfGrade,
			nice_credit_score: creditScore,
			funding_rate: fundingRate,
			user_investment_amount: investedAmount,
			total_investment_amount: accumulatedAmount,
			underlying_asset_id: underlyingAssetId,
			investment_id: investment_id,
			product_page_url: productPageUrl,
			base_yield_rate_type: baseYieldRateType,
		} = item;

		const remainTargetAmount = targetAmount - accumulatedAmount;
		let calculatedProductInvestLimit = productInvestLimit;
		if (productInvestLimit === FOURTY_PERCENT) {
			// 기관/법인 투자자의 경우 모집금액 * 0.4
			calculatedProductInvestLimit = targetAmount * 0.4;
		}
		const investmentId = (investment_id || '').toString(); // null 인 경우 초기값

		const temporaryInvestableAmount = Math.min(remainTargetAmount, calculatedProductInvestLimit);
		// 10만원 단위로 수정
		const productInvestableAmount = floorByDefaultUnit(temporaryInvestableAmount);

		result[id] = {
			id: id.toString(),
			loanPurpose,
			interestRate,
			investTerm,
			pfGrade,
			creditScore,
			fundingRate,
			targetAmount,
			accumulatedAmount,
			remainTargetAmount,
			invested: Boolean(investedAmount), // 투자금액 있으면, 취소상품군에 있으면 항상 true 보장
			investedAmount: investedAmount || 0,
			productInvestLimit: calculatedProductInvestLimit,
			productInvestableAmount,
			underlyingAssetId,
			investmentId,
			productPageUrl,
			baseYieldRateType,
		};
	});

	return result;
};

export const convertProductListIdsResponse = (response: ProductListServerProps[]): string[] => {
	return response.map((item) => String(item.loan_application_id));
};

export const filterSaveKey = (userId: number) => Buffer.from(`${userId}`).toString('base64');

export const convertProfitResponse = (response: ProfitInfoServerProps): SetExpectedProfitParam => {
	const result: SetExpectedProfitParam = {
		totalInterest: response.total_interest,
		totalInterestPercent: response.total_interest_percent,
		totalInterestWithTax: response.total_interest_with_tax,
		totalInterestWithTaxPercent: response.total_interest_with_tax_percent,
		totalLossAmount: response.total_loss_amount,
		totalPlatformFee: response.total_platform_fee,
		totalTax: response.total_tax,
	};
	return result;
};

export const convertProductResponse = (response: ProductServerProps): SetProductParam => {
	const result: SetProductParam = {
		fundedAmount: response.funded_amount,
		totalFundingAmount: response.total_funding_amount,
		fundingRate: response.funding_rate,
	};

	return result;
};

export const convertSummaryResponse = (response: { count: number; total_amount: number }): SetSummaryTableParam => {
	const { count, total_amount } = response;
	return {
		count,
		amount: total_amount,
	};
};

/**
 * pageDatas를 탐색해서 componentName키의 값이 params의 componentName과 같은 객체의 property를
 * params의 property로 교체합니다.
 *
 * @param pageDatas
 * @param componentName
 * @param property
 */
export const findComponentInPageData = (pageDatas: PageData[], componentName: string, property: any) => {
	pageDatas.forEach((pageData) => {
		if (pageData.componentType === componentName) {
			pageData.property = property;
		} else {
			if (pageData.property?.children && Array.isArray(pageData.property.children)) {
				findComponentInPageData(pageData.property?.children, componentName, property);
			}
		}
	});
};
