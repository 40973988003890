import actions from '@peoplefund/actions';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { concat, of } from 'rxjs';
import { ofAction } from '@peoplefund/utils/redux.util';
import { CometEpic } from '@peoplefund/epics/constants.util';
import { Action } from 'redux';
import ObjectUtil from '@peoplefund/utils/object.util';
import { getSignInResult } from '@peoplefund/utils/jwt.util';
import { sendAgreements as sendAgreementsForMlLoan } from '@peoplefund/slices/ml-loan';
import { encryptObservable } from './account/index.util';
import { MARKETING_AGREEMENT_VALUE } from '@peoplefund/constants/ls-select-options';
import { startChangingMarketingAgreementConfig } from '@peoplefund/slices/authentication';

export const applySendSMS: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(actions.lsAuthentication.verifySendSMS.started),
		withLatestFrom(state$),
		mergeMap(([{ payload }, { account }]) =>
			concat(
				of(actions.layout.startLoading()),
				encryptObservable(
					cometAjax,
					{
						name: payload.name,
						rrn: `${payload.rrnFull}`,
						telecom: payload.mobileServiceProviderCode,
						phone_number: payload.mobilePhoneNumber,
						country: 'KR',
						channel_code: 'CREDIT',
					},
					account.auth.token
				).pipe(
					mergeMap(({ eData, headers }) =>
						cometAjax.pfUser
							.post('/auth/v1/cert/sms-otp/send-rrn', {
								token: account.auth.token,
								headers,
								body: { eData },
							})
							.pipe(
								map((response) => {
									return actions.lsAuthentication.verifySendSMS.done({
										params: payload,
										result: {
											svcTxSeqno: response.transaction_number,
										},
									});
								}),
								catchError((error) =>
									of(
										actions.lsAuthentication.verifySendSMS.failed({
											params: payload,
											error,
										})
									)
								)
							)
					)
				),
				of(actions.layout.endLoading())
			)
		)
	);

const applyVerify: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(actions.lsAuthentication.verifySignUp.started),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					lsAuthentication: {
						verify: { sendParam, svcTxSeqno },
					},
					account: { auth },
					mlLoan: {
						apply: { agreements },
					},
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					cometAjax.auth
						.post(
							'/sign-up/identity',
							ObjectUtil.filterUndefined({
								body: {
									phone_number: sendParam?.mobilePhoneNumber?.trim() ?? '',
									transaction_number: svcTxSeqno,
									verification_code: payload.smsVerificationCode.trim(),
									platform: 'PEOPLEFUND',
									sub_platform: 'WEB',
									channel_code: 'CREDIT',
								},
								token: auth?.token,
							})
						)
						.pipe(
							mergeMap((response) => {
								const nextActions: Action[] = [
									actions.account.signIn(getSignInResult(response)),
									actions.lsAuthentication.verifySignUp.done({
										params: payload,
										result: {},
									}),
								];

								const marketingAgreed = Boolean(agreements.find((item) => item === MARKETING_AGREEMENT_VALUE));
								nextActions.push(
									startChangingMarketingAgreementConfig({
										smsAgreement: marketingAgreed,
										emailAgreement: marketingAgreed,
									})
								);

								if (payload.loanType === 'mortgage') {
									nextActions.push(sendAgreementsForMlLoan());
								}

								if (payload.postVerifyAction) {
									nextActions.push(payload.postVerifyAction);
								}

								return nextActions;
							}),
							catchError((error) =>
								of(
									actions.lsAuthentication.verifySignUp.failed({
										params: payload,
										error,
									})
								)
							)
						),
					of(actions.layout.endLoading())
				);
			}
		)
	);

const verifyComplete: CometEpic = (action$, state$) =>
	action$.pipe(
		ofAction(actions.lsAuthentication.verifyComplete),
		withLatestFrom(state$),
		mergeMap(
			([
				,
				{
					lsApplyInfo: { agreedItems, disagreedItems, agreementKey: sceneName },
				},
			]) => {
				const marketingAgreed = Boolean(agreedItems.find((item) => item === MARKETING_AGREEMENT_VALUE));
				return concat(
					of(
						actions.layout.startLoading(),
						actions.lsCommon.sendAgreements.started({
							code: sceneName,
							sceneName,
							agreementIds: agreedItems,
							disagreementIds: disagreedItems,
						}),
						startChangingMarketingAgreementConfig({
							smsAgreement: marketingAgreed,
							emailAgreement: marketingAgreed,
						}),
						actions.layout.endLoading()
					)
				);
			}
		)
	);

export default [applySendSMS, applyVerify, verifyComplete];
