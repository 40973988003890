import { concat, finalize, Observable, of, zip } from 'rxjs';
import actions from '@peoplefund/actions';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ofAction } from '@peoplefund/utils/redux.util';
import { CometEpic } from '@peoplefund/epics/constants.util';
import { removeSessionCookie, setSessionCookie } from '@peoplefund/utils/cookie.util';
import { PHOENIX_SESSION_ID } from '@peoplefund/constants/session-id';
import { Action } from 'redux';
import { getSignInResult, getUserId } from '@peoplefund/utils/jwt.util';
import {
	checkInvestorRegisterAvailable,
	convertPeoplefundMaxInvestableAmount,
	encryptObservable,
	fetchAMLStatus,
	fetchInvestorStatus,
	getUnsecuredInvestmentStatus,
	getUserInfo,
} from './index.util';
import {
	AlertCommonError,
	convertInAPICommonErrorToAlertCommonError,
	InAPICommonError,
} from '@peoplefund/constants/error/type';
import { PFUserErrorCode, PhoenixErrorCode } from '@peoplefund/constants/error/code';
import { gtag } from '@peoplefund/utils/marketing-script/gtag.util';
import {
	changePasswordAction,
	changePhoneNumberAction,
	checkExternalUser as checkExternalUserAction,
	checkResignableAction,
	corporateSignupAction,
	fetchCompletedInformation,
	findEmail,
	finishCompletedInformationAction,
	getChangingMarketingAgreementConfigResult,
	getChangingPasswordResult,
	getChangingPhoneNumberResult,
	getCheckingExternalUserResult,
	getCheckResignableResult,
	getInitializingPasswordResult,
	getMarketingAgreementConfigResult,
	getResigningResult,
	getSettingEmailResult,
	getSettingPasswordResult,
	getSignupForInvestingResult,
	getTemporaryPasswordUsedResult,
	getVerifyingPasswordResult,
	initPassword,
	initStateAll as authenticationSliceInitialize,
	setError,
	setFoundEmail,
	setLoginInfoAction,
	setPasswordAction,
	signupForInvestingAction,
	startChangingMarketingAgreementConfig,
	startMarketingAgreementConfig,
	startResigning,
	startSettingEmail,
	verifyPasswordAction,
} from '@peoplefund/slices/authentication';
import LogRocketUtil from '@peoplefund/utils/logrocket.util';
import PageUrls from '@peoplefund/constants/page-urls';
import { AGREEMENT_SCENE_SIGNUP_FOR_INVEST, MARKETING_AGREEMENT_VALUE } from '@peoplefund/constants/ls-select-options';
import { initialize as lsApplyJobInfoSliceInitialize } from '@peoplefund/slices/lsApplyJobInfoSlice';
import { initialize as smsVerifySliceInitialize } from '@peoplefund/slices/sms-verify';
import { initialize as mlLoanSliceInitialize } from '@peoplefund/slices/ml-loan';
import { initialize as loansAutopaySliceInitialize } from '@peoplefund/slices/loans-autopay';
import { BankInfo, initialize as commonInvestingSliceInitialize } from '@peoplefund/slices/common-investing';
import { initialize as mlInvestingSliceInitialize } from '@peoplefund/slices/ml-investing';
import { initialize as plInvestingSliceInitialize } from '@peoplefund/slices/pl-investing';
import { initialize as voteInitialize } from '@peoplefund/slices/vote';
import { initialize as twoPlusOneInitialize } from '@peoplefund/slices/two-plus-one-verify';
import { initialize as amlInitialize } from '@peoplefund/slices/aml';
import { FETCH_STATUS } from '@peoplefund/slices/ml-loan.model';
import { CompletedInformation, SignInResponse } from './index.model';
import { AMLStatus } from '@peoplefund/reducers/account/index.model';
import ObjectUtil from '@peoplefund/utils/object.util';
import { pinRegisterEpic, pinVerifyEpic } from './pin';
import { fetchBankListObservable } from '../bankCode.util';

export const NotificationSettingApiUrl = '/account/users/:user_id/notification-setting/';
const signInByEmailEpic: CometEpic<SignInResponse> = (action$, _state$, { cometAjax }) =>
	action$.pipe(
		ofAction(actions.account.signInByEmail.started),
		mergeMap(({ payload }) =>
			concat(
				of(actions.layout.startLoading()),
				encryptObservable(cometAjax, {
					email: payload.email,
					password: payload.password,
					platform: 'WEB',
				}).pipe(
					mergeMap(({ eData, headers }) =>
						cometAjax.pfUser
							.post('/auth/v1/email/signin', {
								headers,
								body: { eData },
							})
							.pipe(
								mergeMap((response) => {
									return [
										actions.account.signInByEmail.done({
											params: payload,
											result: {},
										}),
										actions.account.signIn({
											...getSignInResult(response),
											temporaryPasswordUsed: response.temporary_password_used,
										}),
									];
								}),
								catchError((error) => {
									const data: { title: string; content: string; confirmText?: string; nextPage?: string } = {
										title: '로그인에 실패했어요',
										content: '서버와의 통신이 불안정해요\n잠시 후 다시 시도해주세요',
									};

									if (error.code) {
										switch (error.code) {
											/**
											 * 히스토리: https://pfcoworkspace.slack.com/archives/C026RLG5BEX/p1667204738171979
											 */
											case PFUserErrorCode.AUTHENTICATION_EXPIRED_PASSWORD:
												data.title = '비밀번호가 만료되었어요';
												data.content = '임시 비밀번호를 발급 받은 후\n비밀번호를 다시 설정해 주세요';
												data.confirmText = '발급하러 가기';
												data.nextPage = PageUrls.auth.FIND_PASSWORD;
												break;
											case PFUserErrorCode.AUTHENTICATION_EMAIL_OR_PASSWORD_NOT_MATCHED:
												data.title = '일치하는 회원 정보가 없어요';
												data.content = '아이디와 비밀번호를\n다시 한번 확인해주세요';
												break;
											case PFUserErrorCode.AUTHENTICATION_IS_LOCKED:
												data.content = '연속으로 로그인에 실패하여\n잠시 로그인이 제한됩니다';
												break;
										}
									}
									return of(
										actions.account.signInByEmail.failed({
											params: payload,
											error: new InAPICommonError(data.content, error.code, data),
										})
									);
								})
							)
					)
				),

				of(actions.layout.endLoading())
			)
		)
	);

const signInEpic: CometEpic = (action$, state$) =>
	action$.pipe(
		ofAction(actions.account.signIn),
		withLatestFrom(state$),
		mergeMap(([{ payload }]) => {
			setSessionCookie(PHOENIX_SESSION_ID, payload);

			const resultActions: Action[] = [
				actions.account.fetchUserInfo.started({}),
				actions.lsLoan.checkHasLoans(),
				getTemporaryPasswordUsedResult({
					temporaryPasswordUsed: Boolean(payload.temporaryPasswordUsed),
					status: payload.temporaryPasswordUsed ? 'success' : 'fail',
				}),
			];

			const { accessToken } = payload;
			const userId = getUserId(accessToken);
			if (userId) {
				resultActions.push(
					actions.common.logRocketIdentify({
						userId,
						traits: { accessToken },
					})
				);
				gtag({ user_id: userId });
			}

			return resultActions;
		})
	);

const fetchUserInfoEpic: CometEpic = (action$, state$, { cometAjax }) => {
	const checkExternalUser = (token: string): Observable<{ status: FETCH_STATUS; integrationUser: boolean }> => {
		return cometAjax.pfUser
			.get(`/user/v1/integ/external-user`, {
				token,
			})
			.pipe(
				map((response) => {
					/**
					 * 통합 대상인 경우
					 * - users=list
					 * - is_integration_user=false
					 * 통합 대상 아닌 경우
					 * - users= "빈 리스트"
					 * - is_integration_user=true
					 */
					return { integrationUser: !Boolean(response.is_integration_user), status: 'success' as FETCH_STATUS };
				}),
				catchError((error) =>
					of({
						integrationUser: false,
						status: (error?.code === PFUserErrorCode.INTEG_NOT_FOUND_EXTERNAL_USER
							? 'success'
							: 'fail' ?? 'fail') as FETCH_STATUS,
					})
				)
			);
	};

	return action$.pipe(
		ofAction(actions.account.fetchUserInfo.started),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token = '' },
					},
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					of(checkExternalUserAction()),
					zip(getUserInfo(cometAjax, token), fetchAMLStatus(token), checkExternalUser(token)).pipe(
						mergeMap(([{ corporateInfo, ...userInfo }, amlResult, externalUserResponse]) => {
							const commonAction: Action[] = [
								actions.account.fetchUserInfo.done({
									params: payload,
									result: userInfo,
								}),
								actions.account.setAmlStatus({
									...amlResult,
									fetched: true,
								}),
								getCheckingExternalUserResult(externalUserResponse),
							];

							if (userInfo.userType === 'CORPORATION' && corporateInfo) {
								commonAction.push(actions.account.setCorporateInfo(corporateInfo));
								return commonAction;
							}

							return commonAction;
						}),
						catchError((error) =>
							of(
								actions.account.fetchUserInfo.failed({
									params: payload,
									error,
								}),
								actions.account.setAmlStatus({
									status: AMLStatus.INITIAL,
									fetched: true,
								})
							)
						)
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const signOutEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(actions.account.signOut.started),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token = '', refreshToken = '' },
					},
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					encryptObservable(
						cometAjax,
						{
							refresh_token: refreshToken,
						},
						token
					).pipe(
						mergeMap(({ eData, headers }) =>
							cometAjax.pfUser
								.post('/auth/v1/signout', {
									headers,
									body: { eData },
									token,
								})
								.pipe(map(() => actions.account.signOut.done({ params: payload, result: {} })))
						),
						finalize(() => {
							removeSessionCookie(PHOENIX_SESSION_ID);
							LogRocketUtil.endSession();
							gtag({ user_id: undefined });
						})
					),
					of(
						actions.account.init(),
						actions.lsApplyInfo.init(),
						actions.lsAuthentication.init(),
						actions.lsFinancialProduct.init(),
						actions.lsLoan.init(),
						actions.smsVerification.init(),
						actions.unsecuredInvestorRegistration.init(),
						loansAutopaySliceInitialize(),
						authenticationSliceInitialize(),
						commonInvestingSliceInitialize(),
						lsApplyJobInfoSliceInitialize(),
						mlInvestingSliceInitialize(),
						mlLoanSliceInitialize(),
						plInvestingSliceInitialize(),
						smsVerifySliceInitialize(),
						voteInitialize(),
						twoPlusOneInitialize(),
						amlInitialize()
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const updateInvestorInfoEpic: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(actions.account.updateInvestorInfo.started),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token = '' },
					},
				},
			]) =>
				concat(
					of(actions.layout.startLoading()),
					cometAjax.inapi
						.get('/account/users/:user_id/unsecured-investment/status/', {
							token,
						})
						.pipe(
							map((response) =>
								actions.account.updateInvestorInfo.done({
									params: payload,
									result: {
										remainP2PInvestableAmount: convertPeoplefundMaxInvestableAmount(
											response.peoplefund_max_investable_amount
										),
										accountBalance: response.able_money,
									},
								})
							),
							catchError((error) => {
								return of(
									actions.account.updateInvestorInfo.failed({
										params: payload,
										error,
									})
								);
							})
						),
					of(actions.layout.endLoading())
				)
		)
	);

const checkInvestorStatusEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(actions.account.checkInvestorRegisterAvailable.started),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token = '', loggedIn },
					},
				},
			]) => {
				if (!loggedIn) {
					return of(
						actions.account.checkInvestorRegisterAvailable.failed({
							params: payload,
							error: new InAPICommonError('로그인 후 투자자 등록이 가능합니다.', PhoenixErrorCode.LOGIN_REQUIRED),
						})
					);
				}
				return concat(
					of(actions.layout.startLoading()),
					fetchInvestorStatus(cometAjax, token).pipe(
						mergeMap(({ amlStatus, investorStatus, kycExpireDate }) =>
							getUnsecuredInvestmentStatus(cometAjax, token).pipe(
								mergeMap((result) => {
									const error = checkInvestorRegisterAvailable(investorStatus ?? result.investorStatus);
									if (error) {
										throw error;
									}

									return [
										actions.account.checkInvestorRegisterAvailable.done({
											params: payload,
											result: {},
										}),
										actions.account.setAmlStatus({
											status: amlStatus,
											kycExpireDate,
											fetched: true,
										}),
									];
								})
							)
						),
						catchError((error) =>
							of(
								actions.account.checkInvestorRegisterAvailable.failed({
									params: payload,
									error,
								}),
								actions.account.setAmlStatus({
									status: AMLStatus.INITIAL,
									fetched: true,
								})
							)
						)
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const findUserEmailEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(findEmail),
		withLatestFrom(state$),
		mergeMap(([{ payload }]) => {
			return concat(
				of(actions.layout.startLoading()),
				encryptObservable(cometAjax, {
					name: payload.name,
					phone_number: payload.mobileNumber,
				}).pipe(
					mergeMap(({ eData, headers }) =>
						cometAjax.pfUser
							.post(`/user/v1/member/exist-id`, {
								headers,
								body: { eData },
							})
							.pipe(
								map((response) => {
									/**
									 * 앱 가입자는 이메일이 없음 (빈 문자열로 표기) 그렇기 때문에 실제 존재하는 이메일만 표기
									 * 이 경우에도 에러로 처리한다.
									 */
									if (!response.emails.filter((email: string) => email).length) {
										throw new AlertCommonError('등록된 이메일이 없어요', PhoenixErrorCode.EMAIL_NOT_FOUND);
									}
									return setFoundEmail({
										userEmails: response.emails,
										hasUserId: response.has_user_id,
										status: 'success',
									});
								}),
								catchError((error) => {
									let title = '이메일 조회에 실패했어요';
									let content = '서버와의 통신이 불안정해요\n잠시 후 다시 시도해주세요';
									const data: {
										cancelButton: boolean;
										cancelText?: string;
										confirmText?: string;
										nextPage?: string;
									} = {
										cancelButton: false,
									};

									if (error.code) {
										switch (error.code) {
											case PFUserErrorCode.USER_NOT_FOUND:
												title = '입력하신 정보와 일치하는\n회원 정보가 없어요';
												content = '정보를 확인하신 후\n다시 한번 시도해주세요';
												break;
											case PhoenixErrorCode.EMAIL_NOT_FOUND:
												title = '등록된 이메일이 없어요';
												content = '휴대폰 본인인증을 통해\n로그인을 진행해 주세요';
												data.cancelButton = true;
												data.cancelText = '닫기';
												data.confirmText = '본인인증하기';
												data.nextPage = PageUrls.auth.SMS_LOGIN;

												break;
										}
									}
									return of(
										setError(new AlertCommonError(content, error.code, title, data)),
										setFoundEmail({ userEmails: [], hasUserId: false, status: 'fail' })
									);
								})
							)
					)
				),
				of(actions.layout.endLoading())
			);
		})
	);
};

const initializingPasswordEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(initPassword),
		withLatestFrom(state$),
		mergeMap(([{ payload }]) => {
			return concat(
				of(actions.layout.startLoading()),
				encryptObservable(cometAjax, {
					email: payload.email,
				}).pipe(
					mergeMap(({ eData, headers }) =>
						cometAjax.pfUser
							.post(`/auth/v1/password/init`, {
								headers,
								body: { eData },
							})
							.pipe(
								map(() => {
									return getInitializingPasswordResult({
										status: 'success',
									});
								}),
								catchError((error) => {
									let title = '이메일 조회에 실패했어요';
									let content = '서버와의 통신이 불안정해요\n잠시 후 다시 시도해주세요';

									if (error.code) {
										switch (error.code) {
											case PFUserErrorCode.USER_NOT_FOUND:
												title = '입력하신 정보와 일치하는\n회원 정보가 없어요';
												content = '정보를 확인하신 후\n다시 한번 시도해주세요';
												break;
										}
									}
									return of(
										setError(new AlertCommonError(content, error.code, title)),
										getInitializingPasswordResult({ status: 'fail' })
									);
								})
							)
					)
				),
				of(actions.layout.endLoading())
			);
		})
	);
};

const changingPasswordEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(changePasswordAction),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					encryptObservable(
						cometAjax,
						{
							password: payload.password,
							new_password: payload.newPassword,
						},
						token
					).pipe(
						mergeMap(({ eData, headers }) =>
							cometAjax.pfUser
								.put(`/auth/v1/password`, {
									headers,
									body: { eData },
									token,
								})
								.pipe(
									map(() => {
										return getChangingPasswordResult({
											status: 'success',
										});
									}),
									catchError((error) => {
										let message = '서버와의 통신이 불안정해요\n잠시 후 다시 시도해주세요';
										let title = '비밀번호 변경에 실패했어요';

										if (error.code) {
											switch (error.code) {
												case PFUserErrorCode.AUTHENTICATION_PASSWORD_NOT_CHANGED:
													title = '현재 비밀번호와\n다른 비밀번호를 입력해 주세요';
													message = '';
													break;
												case PFUserErrorCode.AUTHENTICATION_EMAIL_OR_PASSWORD_NOT_MATCHED:
													title = '입력하신 현재 비밀번호가\n올바르지 않아요';
													message = '현재 비밀번호를 다시 입력해 주세요';
													break;
												case PFUserErrorCode.USER_NOT_FOUND:
													title = '입력하신 정보와 일치하는\n회원 정보가 없어요';
													message = '정보를 확인하신 후\n다시 한번 확인해주세요';
													break;
												case PFUserErrorCode.AUTHORIZATION_FAILED:
													title = '로그인이 만료되었어요';
													message = '로그인 후 다시 시도해 주세요';
													break;
											}
										}

										return of(
											setError(new AlertCommonError(message, error.code, title)),
											getChangingPasswordResult({ status: 'fail' })
										);
									})
								)
						)
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const settingPasswordEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(setPasswordAction),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					encryptObservable(
						cometAjax,
						{
							password: payload.password,
						},
						token
					).pipe(
						mergeMap(({ eData, headers }) =>
							cometAjax.pfUser
								.put(`/user/v1/integ/external-user/password`, {
									headers,
									body: { eData },
									token,
								})
								.pipe(
									map(() => {
										return getSettingPasswordResult({
											status: 'success',
										});
									}),
									catchError((error) => {
										let title = '본인인증에 실패했어요';
										let content = '서버와의 통신이 불안정해요\n잠시 후 다시 시도해주세요';

										if (error.code) {
											switch (error.code) {
												case PFUserErrorCode.INVALID_PARAMETER:
													title = '비밀번호 형식에 맞지 않아요';
													content = '영문, 숫자, 특수문자(!%*#?&@) 포함\n10자 이상 입력해주세요';
													break;
											}
										}
										return of(
											setError(new AlertCommonError(content, error.code, title)),
											getSettingPasswordResult({ status: 'fail' })
										);
									})
								)
						)
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const getMarketingAgreementConfigEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(startMarketingAgreementConfig),
		withLatestFrom(state$),
		mergeMap(
			([
				,
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					cometAjax.inapi
						.get(NotificationSettingApiUrl, {
							token,
						})
						.pipe(
							map((response) => {
								const result: { sms: boolean; email: boolean; updatedDateTime: string; status: FETCH_STATUS } = {
									sms: false,
									email: false,
									updatedDateTime: '',
									status: 'success',
								};
								if (response.marketing) {
									const { allow_sms, allow_email } = response.marketing;

									result.sms = allow_sms;
									result.email = allow_email;
								}

								if (response.updated_at) {
									result.updatedDateTime = response.updated_at;
								}

								return getMarketingAgreementConfigResult(result);
							}),
							catchError((error) =>
								of(
									setError(error),
									getMarketingAgreementConfigResult({ sms: false, email: false, updatedDateTime: '', status: 'fail' })
								)
							)
						),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const changeMarketingAgreementConfigEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(startChangingMarketingAgreementConfig),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					cometAjax.inapi
						.put(NotificationSettingApiUrl, {
							body: {
								marketing: {
									allow_sms: payload.smsAgreement,
									allow_email: payload.emailAgreement,
									agree: payload.smsAgreement || payload.emailAgreement,
								},
							},
							token,
						})
						.pipe(
							mergeMap(() => {
								return [
									getChangingMarketingAgreementConfigResult({
										status: 'success',
									}),
									startMarketingAgreementConfig(),
								];
							}),
							catchError((error) => of(setError(error), getChangingMarketingAgreementConfigResult({ status: 'fail' })))
						),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const checkingResignableEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(checkResignableAction),
		withLatestFrom(state$),
		mergeMap(
			([
				,
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					encryptObservable(cometAjax, {}, token).pipe(
						mergeMap(({ headers }) =>
							cometAjax.pfUser
								.get(`/user/v1/member/resign-check`, {
									headers,
									token,
								})
								.pipe(
									map((response: { code?: string; is_resignable: boolean }) => {
										if (!response.is_resignable) {
											throw new AlertCommonError('', response.code, '');
										}
										return getCheckResignableResult({
											resignable: response.is_resignable,
											status: 'success',
										});
									}),
									catchError((error) => {
										return of(
											setError(convertInAPICommonErrorToAlertCommonError(error)),
											getCheckResignableResult({ resignable: false, status: 'fail' })
										);
									})
								)
						)
					),

					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const resignEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(startResigning),
		withLatestFrom(state$),
		mergeMap(
			([
				,
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					encryptObservable(cometAjax, {}, token).pipe(
						mergeMap(({ headers }) =>
							cometAjax.pfUser
								.delete(`/user/v1/member/resign`, {
									headers,
									token,
								})
								.pipe(
									map(() => {
										return getResigningResult({
											status: 'success',
										});
									}),
									catchError((error) => of(setError(error), getResigningResult({ status: 'fail' })))
								)
						)
					),

					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const settingEmailEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(startSettingEmail),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					encryptObservable(
						cometAjax,
						{
							email: payload.email,
						},
						token
					).pipe(
						mergeMap(({ headers, eData }) =>
							cometAjax.pfUser
								.patch(`/user/v1/member/email`, {
									body: {
										eData,
									},
									headers,
									token,
								})
								.pipe(
									mergeMap(() => {
										return [
											getSettingEmailResult({
												status: 'success',
											}),
											actions.account.setEmail(payload.email),
											actions.account.fetchUserInfo.started({}),
										];
									}),
									catchError((error) => {
										let title = '이메일 등록에 실패했어요';
										let content = '서버와의 통신이 불안정해요\n새로고침 후 다시 시도해주세요';

										if (error.code && error.code === PFUserErrorCode.AUTHENTICATION_EMAIL_DUPLICATED) {
											title = '이미 사용 중인 이메일입니다';
											content = '다른 이메일을 등록해주세요';
										}

										return of(
											setError(new AlertCommonError(content, error.code, title)),
											getSettingEmailResult({ status: 'fail' })
										);
									})
								)
						)
					),

					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const verifyingPasswordEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(verifyPasswordAction),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					encryptObservable(
						cometAjax,
						{
							password: payload.password,
						},
						token
					).pipe(
						mergeMap(({ eData, headers }) =>
							cometAjax.pfUser
								.post(`/auth/v1/password/verify`, {
									headers,
									body: { eData },
									token,
								})
								.pipe(
									map(() => {
										return getVerifyingPasswordResult({
											status: 'success',
										});
									}),
									catchError((error) => {
										let message = '서버와의 통신이 불안정해요\n잠시 후 다시 시도해주세요';
										let title = '비밀번호 확인에 실패했어요';

										if (error.code) {
											switch (error.code) {
												case PFUserErrorCode.INVALID_PARAMETER:
												case PFUserErrorCode.AUTHENTICATION_EMAIL_OR_PASSWORD_NOT_MATCHED:
													title = '비밀번호가 일치하지 않아요';
													message = '다시 한 번 확인해주세요';
													break;
											}
										}

										return of(
											setError(new AlertCommonError(message, error.code, title)),
											getVerifyingPasswordResult({ status: 'fail' })
										);
									})
								)
						)
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const changingPhoneNumberEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(changePhoneNumberAction),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					encryptObservable(
						cometAjax,
						{
							phone_number: payload.phoneNumber,
							transaction_number: payload.transactionNumber,
							verification_code: payload.verificationCode,
						},
						token
					).pipe(
						mergeMap(({ eData, headers }) =>
							cometAjax.pfUser
								.patch(`/user/v1/member/phone-number`, {
									headers,
									body: { eData },
									token,
								})
								.pipe(
									map(() => {
										return getChangingPhoneNumberResult({
											status: 'success',
										});
									}),
									catchError((error) => {
										let message = '잠시 후 다시 시도해주세요';
										let title = '본인 인증에 실패했어요';

										if (error.code) {
											switch (error.code) {
												case PFUserErrorCode.IDENTITY_VERIFICATION_FAILED:
													title = '인증번호가 잘못되었어요';
													message = '인증번호를 다시 확인해주세요';
											}
										}

										return of(
											setError(new AlertCommonError(message, error.code, title)),
											getChangingPhoneNumberResult({ status: 'fail' })
										);
									})
								)
						)
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const signUpBySMSEpic: CometEpic<SignInResponse> = (action$, _state$, { cometAjax }) =>
	action$.pipe(
		ofAction(signupForInvestingAction),
		mergeMap(({ payload }) =>
			concat(
				of(actions.layout.startLoading()),
				encryptObservable(cometAjax, {
					email: payload.email,
					password: payload.password,
					promotion_code: payload.promotionCode,
					phone_number: payload.phoneNumber,
					transaction_number: payload.transactionNumber,
					verification_code: payload.verificationCode,
					platform: 'PEOPLEFUND',
					sub_platform: 'WEB',
					channel_code: 'CREDIT',
				}).pipe(
					mergeMap(({ eData, headers }) =>
						cometAjax.pfUser
							.post('/auth/v1/email/signup', {
								headers,
								body: { eData },
							})
							.pipe(
								mergeMap((response) => {
									const marketingAgreed = payload.agreements.some((item) => item === MARKETING_AGREEMENT_VALUE);

									return of(
										actions.account.signIn(getSignInResult(response)),
										actions.lsCommon.sendAgreements.started({
											code: AGREEMENT_SCENE_SIGNUP_FOR_INVEST,
											sceneName: AGREEMENT_SCENE_SIGNUP_FOR_INVEST,
											agreementIds: payload.agreements,
											disagreementIds: payload.disagreements,
										}),
										getSignupForInvestingResult({
											status: 'success',
										}),
										startChangingMarketingAgreementConfig({
											smsAgreement: marketingAgreed,
											emailAgreement: marketingAgreed,
										})
									);
								}),
								catchError((error) => {
									const data: {
										title: string;
										content: string;
										nextPage?: string;
										confirmText?: string;
										cancelText?: string;
									} = {
										title: '본인 인증에 실패했어요',
										content: '잠시 후 다시 시도해 주세요',
									};

									if (error.code) {
										switch (error.code) {
											case PFUserErrorCode.AUTHENTICATION_USER_ALREADY_REGISTERED:
												data.title = '이미 가입된 계정이 있어요';
												data.content = '';
												data.nextPage = PageUrls.auth.LOGIN;
												data.confirmText = '로그인 하기';
												data.cancelText = '이메일 찾기';
												break;
											case PFUserErrorCode.IDENTITY_VERIFICATION_FAILED:
												data.title = '본인인증에 실패했어요';
												data.content = '인증번호를 다시 한 번 확인해 주세요';
												break;
										}
									}

									return of(
										setError(new AlertCommonError(data.content, error.code, data.title, data)),
										getSignupForInvestingResult({
											status: 'fail',
										})
									);
								})
							)
					)
				),
				of(actions.layout.endLoading())
			)
		)
	);

const setLoginInfoActionEpic: CometEpic<SignInResponse> = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(setLoginInfoAction),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token = '' },
					},
				},
			]) =>
				concat(
					of(actions.layout.startLoading()),
					encryptObservable(
						cometAjax,
						ObjectUtil.filterUndefined({
							email: payload.email,
							password: payload.password,
							promotion_code: payload.promotionCode,
						}),
						token
					).pipe(
						mergeMap(({ eData, headers }) =>
							cometAjax.pfUser
								.put('/auth/v1/auth-info/email-password', {
									headers,
									token,
									body: { eData },
								})
								.pipe(
									mergeMap(() => {
										return of(
											// todo: 나중에 다 제거
											getSignupForInvestingResult({
												status: 'success',
											})
										);
									}),
									catchError((error) => {
										const data: {
											title: string;
											content: string;
											nextPage?: string;
											confirmText?: string;
											cancelText?: string;
										} = {
											title: '로그인 정보 설정에 실패했습니다',
											content: '',
										};

										switch (error.code) {
											case 400:
												data.title = `이미 가입이 완료된 계정입니다.\n가입하신 계정으로 로그인합니다.`;
												data.confirmText = '확인';
												break;

											case 409:
												data.title = '이미 등록된 이메일입니다';
												data.content = error?.message ?? '다른 이메일을 입력해주세요';
												break;

											default:
												data.content = error?.message ?? '잠시 후 다시 시도해 주세요';
										}

										return of(
											setError(new AlertCommonError(data.content, error.code, data.title, data)),
											getSignupForInvestingResult({
												status: 'fail',
											})
										);
									})
								)
						)
					),
					of(actions.layout.endLoading())
				)
		)
	);

const fetchAMLStatusEpic: CometEpic = (action$, state$) =>
	action$.pipe(
		ofAction(actions.account.fetchAMLStatus.started),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token = '' },
					},
				},
			]) =>
				concat(
					of(actions.layout.startLoading()),
					fetchAMLStatus(token).pipe(
						mergeMap(({ status }) =>
							of(
								actions.account.fetchAMLStatus.done({
									params: payload,
									result: { fetched: true, status },
								})
							)
						),
						catchError((error) => {
							return of(
								actions.account.fetchAMLStatus.failed({
									params: payload,
									error,
								}),
								actions.account.setAmlStatus({
									status: AMLStatus.INITIAL,
									fetched: true,
								})
							);
						})
					),
					of(actions.layout.endLoading())
				)
		)
	);

const signUpForCorporateUser: CometEpic<SignInResponse> = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(corporateSignupAction),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { email, password },
				},
				{
					authentication: { corporateSignup },
				},
			]) => {
				const { agentInfo, code, agreements, disagreements } = corporateSignup;

				const { name, representativePhoneNumber } = agentInfo;

				const field = {
					email,
					password,
					contact: {
						name,
						/**
						 * TODO: phone_number는 필요없는거 아닌지? - 서버 문의 필요.
						 * 다만 논의하지 않고 바로 진행하는 이유는 이미 법인투자 회원가입에 대해서 꽤 많이 밀려있기 때문.
						 * 일단 플로우 개시 할 수 있도록 작업하고 스레드로 관리 예정.
						 */
						phone_number: representativePhoneNumber,
						representative_phone_number: representativePhoneNumber,
						email: agentInfo.email,
					},
					platform: 'PEOPLEFUND',
					sub_platform: 'WEB',
					channel_code: 'CREDIT',
				};

				return concat(
					of(actions.layout.startLoading()),
					encryptObservable(cometAjax, field).pipe(
						mergeMap(({ eData, headers }) =>
							cometAjax.pfUser
								.post('/auth/v1/corp-user/sign-up', {
									headers,
									body: { eData },
								})
								.pipe(
									mergeMap((response) => {
										return cometAjax.terms
											.post('/v2/agreements', {
												token: response.access_token,
												body: {
													set_code: code,
													agree_codes: agreements,
													disagree_codes: disagreements,
												},
											})
											.pipe(
												mergeMap(() => {
													return of(
														actions.account.signIn(getSignInResult(response)),
														getSignupForInvestingResult({
															status: 'success',
														})
													);
												})
											);
									}),
									catchError((error) => {
										if (error.code === PFUserErrorCode.AUTHENTICATION_EMAIL_DUPLICATED) {
											return of(
												setError(
													new AlertCommonError(
														'',
														error.code,
														`작성 중인 내역이 있어요\n로그인 후 이어서 진행할까요?`,
														{
															confirmText: '이어서 쓰기',
															cancelText: '새로쓰기',
															nextPage: PageUrls.auth.LOGIN,
														}
													)
												)
											);
										}
										return of(
											setError(new AlertCommonError('')),
											getSignupForInvestingResult({
												status: 'fail',
											})
										);
									})
								)
						)
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);

const fetchCompletedInformationEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(fetchCompletedInformation),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { action },
				},
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					fetchBankListObservable(cometAjax, false).pipe(
						mergeMap((bankCodes: BankInfo[]) => {
							return cometAjax.pfUser
								.get('/user/v1/investor/cert/completed-information', {
									token,
								})
								.pipe(
									map((response: CompletedInformation) => {
										const selectedBank = bankCodes.find((bank) => bank.bankCode === response.bank_code);

										const { name, account_number } = response;
										action({
											accountInvestBankName: selectedBank?.bankName ?? '',
											accountInvestHolder: name,
											accountInvestNumFormatted: account_number,
											networkStatus: 'success',
										});
										return finishCompletedInformationAction();
									})
								);
						}),
						catchError((error) => {
							action({
								accountInvestBankName: '',
								accountInvestHolder: '',
								accountInvestNumFormatted: '',
								networkStatus: 'fail',
							});
							return of(setError(error), finishCompletedInformationAction());
						})
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

export default [
	signInByEmailEpic,
	signInEpic,
	signUpBySMSEpic,
	signOutEpic,
	resignEpic,
	fetchUserInfoEpic,
	updateInvestorInfoEpic,
	checkInvestorStatusEpic,
	findUserEmailEpic,
	initializingPasswordEpic,
	changingPasswordEpic,
	settingPasswordEpic,
	getMarketingAgreementConfigEpic,
	changeMarketingAgreementConfigEpic,
	settingEmailEpic,
	verifyingPasswordEpic,
	changingPhoneNumberEpic,
	checkingResignableEpic,
	fetchAMLStatusEpic,
	setLoginInfoActionEpic,
	pinVerifyEpic,
	pinRegisterEpic,
	signUpForCorporateUser,
	fetchCompletedInformationEpic,
] as CometEpic[];
