import { AlertCommonError } from '@peoplefund/constants/error/type';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	AddCartApiServerParameterInCamelCase,
	ChangeInvestmentAmountServerRequestInCamelCase,
	DeleteSelectedCartProductListServerRequestInCamelCase,
	FetchProductListParams,
	FundingStartNotificationServerParamsInCamelCase,
	GetCartProductInformationServerResponseInCamelCase,
	MaxInvestableAmountAndDepositApiServerResponseInCamelCase,
	MinInvestableAmountAndInvestmentUnitApiServerResponseInCamelCase,
	MortgageInvestmentProductFilterServerResponseInCamelCase,
	MortgageInvestmentTagDescriptionItem,
	NewProductNotificationSettingResponseInCamelCase,
	PointApiServerResponseInCamelCase,
	Product,
	ProductList,
	UserAccountExtraInformationServerResponseInCamelCase,
	UserAccountInformationApiServerResponseInCamelCase,
} from './ml-investing.model';
import { PageData } from './common-investing';
import { FETCH_STATUS } from './ml-loan.model';
import { WithNetworkStatus } from '@peoplefund/reducers';

const SLICE_NAME = 'mlInvesting';

type StartAddCartProps = AddCartApiServerParameterInCamelCase;
export type StartInvestChangeProps = ChangeInvestmentAmountServerRequestInCamelCase & {
	action: (props: WithNetworkStatus<{ changed: boolean }>) => void;
	loanApplicationId: number;
};
export interface MortgageInvestProductListItem {
	cartId: number;
	productId?: number;
	name: string;
	uri: string;
	status: '투자가능' | '오픈예정' | '마감';
	statusName: string;
	yieldRate: number;
	investmentTerm: number;
	ltv: number;
	targetAmount: number;
	amount: number;
	loanApplicationId: number;
	title: string;
	fundingStartDatetime: string;
	hasInvestedSucceed?: boolean;
	productType: string;
	isNotifiedOpenProduct: boolean;
	limitType: '부동산' | '기타';
}

export interface CartListInfo {
	list: CartList;
	status: FETCH_STATUS;
}

export type CartList = MortgageInvestProductListItem[];
export interface MLInvesting {
	pageDatas: PageData[];
	productList: ProductList | null;
	cartListCount: number /**GNB를 위해서 사용하기 위해 따로 빼두었습니다. */;
	selectedCartList: CartListInfo;
	tagGuideList: MortgageInvestmentTagDescriptionItem[] | null;
	productFilterOptions: MortgageInvestmentProductFilterServerResponseInCamelCase | null;
	maxInvestableAmountAndDeposit: MaxInvestableAmountAndDepositApiServerResponseInCamelCase | null;
	pointInfo: PointApiServerResponseInCamelCase | null;
	minInvestableAmountAndInvestmentUnit: MinInvestableAmountAndInvestmentUnitApiServerResponseInCamelCase | null;
	userAccountInformation: UserAccountInformationApiServerResponseInCamelCase | null;
	cartProductInformation: GetCartProductInformationServerResponseInCamelCase | null;
	userAccountExtraInformation: UserAccountExtraInformationServerResponseInCamelCase | null;
	newProductNotificationSetting: NewProductNotificationSettingResponseInCamelCase | null;
	error?: AlertCommonError;
	onNewProductNotificationSetting: { assetProductType: string };
	newProductNotificationSettingRequestParam: { assetProductType: string } | null;
}

export const mlInvestingInitialState: MLInvesting = {
	pageDatas: [],
	productList: null,
	cartListCount: 0,
	selectedCartList: {
		list: [],
		status: 'idle',
	},
	tagGuideList: null,
	productFilterOptions: null,
	maxInvestableAmountAndDeposit: null,
	pointInfo: null,
	minInvestableAmountAndInvestmentUnit: null,
	userAccountInformation: null,
	userAccountExtraInformation: null,
	cartProductInformation: null,
	newProductNotificationSetting: null,
	onNewProductNotificationSetting: {
		assetProductType: 'all',
	},
	newProductNotificationSettingRequestParam: {
		assetProductType: 'all',
	},
};

export const finishDeleteSelectedCartItems = createAction(`${SLICE_NAME}/finishDeleteSelectedCartItems`);
export const finishNotificateSelectedCartItem = createAction(`${SLICE_NAME}/finishNotificateSelectedCartItem`);

export const mlInvestingSlice = createSlice({
	name: 'mlInvesting',
	initialState: mlInvestingInitialState,
	reducers: {
		initialize: (state) => Object.assign(state, mlInvestingInitialState),
		setError: (state, action: PayloadAction<AlertCommonError | undefined>) => {
			state.error = action.payload;
		},
		resetError: (state) => {
			state.error = undefined;
		},
		setPageDatas: (state, action: PayloadAction<{ pageDatas: PageData[] }>) => {
			state.pageDatas = action.payload.pageDatas;
		},
		startInvestCancel: (
			state,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			_: PayloadAction<{ action: (props: WithNetworkStatus<{ canceled: boolean }>) => void; loanApplicationId: number }>
		) => {
			state.error = undefined;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		startInvestChange: (state, _: PayloadAction<StartInvestChangeProps>) => {
			state.error = undefined;
		},
		startDeleteSelectedCartItems: (
			state,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			_: PayloadAction<{
				action: (props: WithNetworkStatus<{ deleted: boolean }>) => void;
				idList: DeleteSelectedCartProductListServerRequestInCamelCase['idList'];
			}>
		) => {
			state.error = undefined;
		},
		startNotificateSelectedCartItem: (
			state,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			_: PayloadAction<{
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				action: (props: WithNetworkStatus<NonNullable<unknown>>) => void;
				params: FundingStartNotificationServerParamsInCamelCase & { productId: number };
			}>
		) => {
			state.error = undefined;
		},

		startAddCart: (
			state,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			_: PayloadAction<{
				action: (props: WithNetworkStatus<{ added: boolean }>) => void;
				productInformation: StartAddCartProps;
			}>
		) => {
			state.error = undefined;
		},
		finishInvestCancelDone: () => {
			return;
		},
		finishInvestChanged: () => {
			return;
		},
		fetchProductInfo: (
			state,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			_: PayloadAction<{ action: (props: WithNetworkStatus<{ value: Product | null }>) => void; uri: string }>
		) => {
			state.error = undefined;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		fetchPointInfo: (state, _) => {
			state.pointInfo = null;
			state.error = undefined;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		fetchMinInvestableAmountAndInvestmentUnit: (state, _: PayloadAction<{ uri: string }>) => {
			state.error = undefined;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		fetchProductDealpageInfo: (state, _: PayloadAction<{ uri: string }>) => {
			state.error = undefined;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		fetchUserAccountInfo: (state, _) => {
			state.error = undefined;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		fetchCartProductInformation: (state, _: PayloadAction<{ cartId: number }>) => {
			state.error = undefined;
		},
		fetchCartList: (
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			__,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			_: PayloadAction<{ action: (props: WithNetworkStatus<{ value: CartList }>) => void }>
		) => {
			return;
		},
		fetchUserAccountExtraInformation: (state) => {
			state.error = undefined;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		fetchProductList: (state, _: PayloadAction<FetchProductListParams>) => {
			state.error = undefined;
			state.productList = null;
		},
		fetchTagGuideList: (state) => {
			state.error = undefined;
			state.tagGuideList = null;
		},
		fetchProductFilterOptions: (state) => {
			state.error = undefined;
			state.productFilterOptions = null;
		},
		fetchNewProductNotificationSetting: (
			state,
			action: PayloadAction<{
				action: (props: { isAgreed: boolean; isOn: boolean; bannerImage: string }) => void;
				assetProductType: string;
			}>
		) => {
			state.error = undefined;
			state.newProductNotificationSettingRequestParam = action.payload;
		},
		postNewProductNotificationSetting: (
			state,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			_: PayloadAction<{ action: (props: WithNetworkStatus<object>) => void; assetProductType: string }>
		) => {
			state.error = undefined;
		},
		setNewProductNotificationSetting: (
			state,
			action: PayloadAction<NewProductNotificationSettingResponseInCamelCase>
		) => {
			state.error = undefined;
			state.newProductNotificationSetting = action.payload;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		fetchMaxInvestableAmountAndDeposit: (state, _: PayloadAction<{ loanApplicationId: number }>) => {
			state.error = undefined;
		},
		fetchServerTimeForInvestment: (
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			__,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			_: PayloadAction<{ action: (props: WithNetworkStatus<{ time: string }>) => void }>
		) => {
			return;
		},
		finishProductInfo: () => {
			return;
		},
		setMaxInvestableAmountAndDeposit: (
			state,
			action: PayloadAction<MaxInvestableAmountAndDepositApiServerResponseInCamelCase | null>
		) => {
			state.maxInvestableAmountAndDeposit = action.payload;
		},
		setPointInfo: (state, action: PayloadAction<PointApiServerResponseInCamelCase | null>) => {
			state.pointInfo = action.payload;
		},
		sethMinInvestableAmountAndInvestmentUnit: (
			state,
			action: PayloadAction<MinInvestableAmountAndInvestmentUnitApiServerResponseInCamelCase | null>
		) => {
			state.minInvestableAmountAndInvestmentUnit = action.payload;
		},
		setUserAccountInfo: (state, action: PayloadAction<UserAccountInformationApiServerResponseInCamelCase | null>) => {
			state.userAccountInformation = action.payload;
		},
		setCartProductInformation: (
			state,
			action: PayloadAction<GetCartProductInformationServerResponseInCamelCase | null>
		) => {
			state.cartProductInformation = action.payload;
		},
		setUserAccountExtraInformation: (
			state,
			action: PayloadAction<UserAccountExtraInformationServerResponseInCamelCase | null>
		) => {
			state.userAccountExtraInformation = action.payload;
		},
		setProductList: (state, action: PayloadAction<ProductList | null>) => {
			state.productList = action.payload;
		},
		setTagGuideList: (state, action: PayloadAction<MortgageInvestmentTagDescriptionItem[] | null>) => {
			state.tagGuideList = action.payload;
		},
		setProductFilterOptions: (
			state,
			action: PayloadAction<MortgageInvestmentProductFilterServerResponseInCamelCase | null>
		) => {
			state.productFilterOptions = action.payload;
		},
		finishFetchCartList: () => {
			return;
		},
		setCartListCount: (state, action: PayloadAction<number>) => {
			state.cartListCount = action.payload;
		},
		setSelectedCartList: (state, action: PayloadAction<CartListInfo>) => {
			state.selectedCartList = action.payload;
		},
		finishAddCartResult: () => {
			return;
		},
		finishFetchServerTimeForInvestment: () => {
			return;
		},
	},
});

export const {
	initialize,
	setError,
	resetError,
	setPageDatas,
	startInvestCancel,
	startDeleteSelectedCartItems,
	startInvestChange,
	startAddCart,
	startNotificateSelectedCartItem,
	finishInvestCancelDone,
	finishInvestChanged,
	fetchProductInfo,
	fetchProductDealpageInfo,
	fetchPointInfo,
	fetchMinInvestableAmountAndInvestmentUnit,
	fetchUserAccountInfo,
	fetchCartProductInformation,
	fetchCartList,
	fetchNewProductNotificationSetting,
	setNewProductNotificationSetting,
	postNewProductNotificationSetting,
	fetchUserAccountExtraInformation,
	fetchProductList,
	fetchTagGuideList,
	fetchProductFilterOptions,
	fetchMaxInvestableAmountAndDeposit,
	fetchServerTimeForInvestment,
	finishProductInfo,
	setCartProductInformation,
	setUserAccountExtraInformation,
	setProductList,
	setTagGuideList,
	setProductFilterOptions,
	finishFetchCartList,
	setSelectedCartList,
	setMaxInvestableAmountAndDeposit,
	setPointInfo,
	finishAddCartResult,
	setUserAccountInfo,
	setCartListCount,
	finishFetchServerTimeForInvestment,
} = mlInvestingSlice.actions;

export default mlInvestingSlice.reducer;
