import { LSFinancialProductApplicationStatus, LSLoanStatus } from '@peoplefund/constants/ls-loan-status';
import { FinancialProductDetail, Information, NotificationInfo } from '@peoplefund/constants/ls-financial-detail-types';
import { AlertCommonError } from '@peoplefund/constants/error/type';
import { AdditionalDocsResultParam, RejectAffiliate } from '@peoplefund/actions/ls-loan';
import { ProductCommissionInformation } from '@peoplefund/actions/ls-financial-product';
import { TagColor } from '@peoplefund/components/common/ColorTag';

export enum CommissionTypeCode {
	ADVANCE = 'PF0001', //선수취
	INSTALLMENT = 'PF0002', //분할수취
}

export type IntroKey = 'loansCompare';

export interface Institution {
	id: string;
	name: string;
	logoUrl: string;
	maxAmount: number;
	maxInterestRate: number;
	stapleProduct?: string;
}

export interface FinancialProduct extends FinancialProductDetail {
	/** 상품 Id */
	readonly id: string;
	/** 상품명 */
	readonly name: string;
	/** 상품 코드 */
	readonly code: string;
	/** 플랫폼 이용료 수취방식 */
	readonly commissionTypeCode?: CommissionTypeCode; //TODO: 플래그등으로 개선
	/** 기관 정보 */ // TODO : institutionId로 institution 정보 가져오던 것 수정
	readonly institution: {
		readonly logoUrl: string;
		readonly name: string;
	};
}

export type Badge = { name: string; color: TagColor };
type FinancialProductApplicationLabel = {
	image: string;
	title: string;
	tooltip: string | null;
	value: string;
};
export type InterestRateInfo = {
	/** 라벨 (변동금리 여부 표시를 함) */
	readonly label: string;
	/** 이자율 관련 툴팁 */
	readonly toolTip: string;
	/** 이자율 추가 설명 (플랫폼 포함 여부 등) */
	readonly tip: string;
	/** 기타 설명 */
	readonly disclaimer: string[];
	/** 통합 이자율 관련 툴팁 */
	readonly integratedToolTip: string[];
};

export const defaultFinancialProduct: FinancialProduct = {
	id: '',
	name: '',
	code: '',
	institution: {
		logoUrl: '',
		name: '',
	},
	information: [],
};

export type CommonFinancialProductApplication = {
	/** fpa Id */
	readonly id: string;
	/** pfloan 여부 */
	readonly pfloan: boolean;
	/** 상품 정보 */
	readonly financialProductId: string;
	/** 상태 */
	readonly status: LSFinancialProductApplicationStatus;
	/** 한도 */
	readonly maxAmount: number;
	/** 신청금액 */
	readonly amount: number;
	/** 수수료 */
	readonly commissionRate: number;
	/** 이자 */
	readonly interestRate: number;
	/** 통합금리: 이자 + 수수료 */
	readonly integratedInterestRate: number;
	/** 우대금리 */
	readonly interestRateDiscount: number;
	/** 이자 관련 안내(툴팁, 라벨 등등) */
	readonly interestRateInfo?: InterestRateInfo;
	/** 신청금액 변경 요청시 완료 여부 **/
	readonly applyCompleted: boolean;
	/** 계약 타입 */
	readonly processType: 'internal' | 'external';
	/** 계약 연계 url */
	readonly externalContractUrl?: string;
	/** 뱃지 표시 **/
	readonly badges: Badge[];
	/** 진행중표시여부 */
	readonly applyInProcess: boolean;

	/** 상품 기타 정보 */
	readonly notification: NotificationInfo | null;

	readonly error?: AlertCommonError;
};

export type FinancialProductApplication = CommonFinancialProductApplication & {
	/** 최소 신청 금액 */
	readonly minimumApplyAmount: number;
	/** 신청 금액 단위 */
	readonly applyAmountUnit: number;
	/** 상품 안내 라벨 */
	readonly labels: FinancialProductApplicationLabel[];
	/** 상품 안내 안내 사항 */
	readonly informations: Information[];
};

export const defaultFinancialProductApplication: FinancialProductApplication = {
	id: '',
	pfloan: true,
	financialProductId: '',
	status: LSFinancialProductApplicationStatus.UNKNOWN,
	maxAmount: 0,
	amount: 0,
	commissionRate: 0,
	interestRate: 0,
	integratedInterestRate: 0,
	interestRateDiscount: 0,
	processType: 'internal',
	applyCompleted: false,
	badges: [],
	applyInProcess: false,
	minimumApplyAmount: 0,
	applyAmountUnit: 1,
	labels: [],
	informations: [],
	notification: null,
};

export interface Loan {
	readonly id: string;
	readonly pfOnly: boolean; //pfloan만 조회했는지(원앱), 제휴사 조회도 같이했는지(론샷)
	readonly status: LSLoanStatus;
	readonly expire: string;
	readonly hasApprovedFPA: boolean; //본심사 승인된 FPA 있는지 여부
	readonly rejectAffiliate?: RejectAffiliate;
	readonly rejectAffiliateChecked: boolean;
	readonly additionalDocs?: AdditionalDocs;
	readonly preScreening: {
		readonly inProgress: boolean;
		readonly remainSecond: number;
	};
	readonly error?: AlertCommonError;
}

export interface FinancialProducts {
	[id: string]: FinancialProduct;
}

export interface FinancialProductApplications {
	[id: string]: FinancialProductApplication;
}

export type CommissionInfo = Record<CommissionTypeCode, ProductCommissionInformation>;

export enum FlowType {
	LOANSHOT = 'loanshot',
	PFLOAN = 'pfloan',
}

export interface SelectOption<ValueType = string> {
	key: ValueType;
	content: string;
}

interface AdditionalDocs extends AdditionalDocsResultParam {
	submitted: boolean;
}

/* 가심사 직업 코드 */
export enum INCOME_TYPE_CODE {
	OFFICE_WORKER = 'IT001', // 직장인
	PUBLIC_OFFICER = 'IT002', // 공무원
	BUSINESS_MAN = 'IT003', // 개인사업자
	ETC = 'IT099', // 기타
}

export enum EMPLOYMENT_TYPE_CODE {
	REGULAR = 'ET001', //정규직
	CONTRACT = 'ET002', //계약직
	DISPATCH = 'ET003', //파견직
	ETC = 'ET099', // 기타
}
