import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const SLICE_NAME = 'mortgageLoanDraftChic';

export type DocumentScanningRequest = {
	id: number;
	document_type_label: string;
	document_type_value: string;
	status: 'done' | '';
};

export interface Document {
	id: number;
	document_type_label: string;
	status: string;
}

export interface MortgageLoanDocumentScanningState {
	lpaId: string;
	scanningRequests: DocumentScanningRequest[];
}
const Unknown = '';

export const mortgageLoanDocumentScanningInitState: MortgageLoanDocumentScanningState = {
	lpaId: Unknown,
	scanningRequests: [],
};

export const mortgageLoanDraftSlice = createSlice({
	name: SLICE_NAME,
	initialState: mortgageLoanDocumentScanningInitState,
	reducers: {
		// reset 겸용
		initialize: (state) => {
			state.lpaId = mortgageLoanDocumentScanningInitState.lpaId;
			state.scanningRequests = mortgageLoanDocumentScanningInitState.scanningRequests;
		},
		setLoanProductApplicationId: (state, { payload }: PayloadAction<string>) => {
			state.lpaId = `${payload}`;
		},
		setDocumentScanningRequests: (state, { payload }: PayloadAction<DocumentScanningRequest[]>) => {
			state.scanningRequests = payload;
		},
		updateDocumentScanningRequestStatus: (state, { payload }: PayloadAction<number>) => {
			state.scanningRequests = state.scanningRequests.map((document) => {
				if (document.id === payload) {
					document.status = 'done';
				}
				return document;
			});
		},
	},
});

export const {
	initialize,
	setLoanProductApplicationId,
	setDocumentScanningRequests,
	updateDocumentScanningRequestStatus,
} = mortgageLoanDraftSlice.actions;

export default mortgageLoanDraftSlice.reducer;
