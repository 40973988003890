import actions from '@peoplefund/actions';
import { catchError, debounceTime, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { concat, Observable, of } from 'rxjs';
import { ofAction } from '@peoplefund/utils/redux.util';
import { CometEpic } from '@peoplefund/epics/constants.util';
import { convertExistingLoanResult } from '@peoplefund/epics/ls-apply-info.util';
import { convertGetJobInfoFromCretop } from '@peoplefund/epics/ls-info.util';
import { AlertCommonError, convertInAPICommonErrorToAlertCommonError } from '@peoplefund/constants/error/type';
import { CometAjax } from './ajax.util';
import { FinancialProductApplicationResponse, LoanInProgressResponse } from '@peoplefund/epics/ls-loan.model';
import { PhoenixErrorCode } from '@peoplefund/constants/error/code';
import { FlowType } from '@peoplefund/constants/ls-loan-types';
import * as lsApplyJobInfoSlice from '@peoplefund/slices/lsApplyJobInfoSlice';
import { Action } from 'redux';
import { CompanyInfoState } from '@peoplefund/actions/ls-apply-info';
import { formatDate } from '@peoplefund/utils/date.util';

const fetchExistingLoan: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(actions.lsApplyInfo.fetchExistingLoan.started),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token = '' },
					},
				},
			]) =>
				cometAjax.loan
					.get('/v1/users/existing-loans', {
						token,
					})
					.pipe(
						map((response) => {
							return actions.lsApplyInfo.fetchExistingLoan.done({
								params: payload,
								result: {
									list: convertExistingLoanResult(response),
								},
							});
						}),
						catchError((error) =>
							of(
								actions.lsApplyInfo.fetchExistingLoan.failed({
									params: payload,
									error: convertInAPICommonErrorToAlertCommonError(error),
								})
							)
						)
					)
		)
	);

const createExistingLoan: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(actions.lsApplyInfo.createExistingLoan.started),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token = '' },
					},
				},
			]) =>
				concat(
					of(actions.layout.startLoading()),
					cometAjax.loan
						.post('/v1/users/existing-loans/create', {
							body: {
								institution_type: payload.institutionType,
								balance: payload.balance,
							},
							token,
						})
						.pipe(
							mergeMap(() => [
								actions.lsApplyInfo.createExistingLoan.done({
									params: payload,
									result: {},
								}),
								actions.lsApplyInfo.fetchExistingLoan.started({}),
							]),
							catchError((error) =>
								of(
									actions.lsApplyInfo.createExistingLoan.failed({
										params: payload,
										error: convertInAPICommonErrorToAlertCommonError(error),
									})
								)
							)
						),
					of(actions.layout.endLoading())
				)
		)
	);

const deleteExistingLoan: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(actions.lsApplyInfo.deleteExistingLoan.started),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token = '' },
					},
				},
			]) =>
				concat(
					of(actions.layout.startLoading()),
					cometAjax.loan
						.post(`/v1/users/existing-loans/${payload.id}/delete`, {
							token,
						})
						.pipe(
							mergeMap(() => [
								actions.lsApplyInfo.deleteExistingLoan.done({
									params: payload,
									result: {},
								}),
								actions.lsApplyInfo.fetchExistingLoan.started({}),
							]),
							catchError((error) =>
								of(
									actions.lsApplyInfo.deleteExistingLoan.failed({
										params: payload,
										error: convertInAPICommonErrorToAlertCommonError(error),
									})
								)
							)
						),
					of(actions.layout.endLoading())
				)
		)
	);

const getJobInfoFromCretop: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(actions.lsApplyInfo.getJobInfoFromCretop.started),
		debounceTime(350),
		withLatestFrom(state$),
		switchMap(
			([
				{ payload },
				{
					account: {
						auth: { token = '' },
					},
				},
			]) =>
				cometAjax.loan
					.get('/v1/jobs/search_company', {
						body: {
							company_name: payload.companyName.trim(),
						},
						token,
					})
					.pipe(
						map((response) => {
							return actions.lsApplyInfo.getJobInfoFromCretop.done({
								params: payload,
								result: {
									result: convertGetJobInfoFromCretop(response.results),
								},
							});
						}),
						catchError((error) =>
							of(
								actions.lsApplyInfo.getJobInfoFromCretop.failed({
									params: payload,
									error: convertInAPICommonErrorToAlertCommonError(error),
								})
							)
						)
					)
		)
	);

const checkApplyAvailable: CometEpic = (action$, state$, { cometAjax }) => {
	const checkHasLoan = (
		cometAjax: CometAjax<LoanInProgressResponse | FinancialProductApplicationResponse>,
		token: string,
		flowType: FlowType
	): Observable<void> =>
		cometAjax.loan.get('/v1/personal-loan/loan-application/in-progress', { token }).pipe(
			map((response) => {
				const { loan_application, financial_product_application } = response as LoanInProgressResponse;

				if (financial_product_application?.id) {
					throw new AlertCommonError(
						'앱에서 이어서 진행해주세요.',
						PhoenixErrorCode.HAS_LOAN_APPLICATION_IN_PROGRESS,
						'계약중인 대출이 있어요!'
					);
				} else if (loan_application && loan_application.id) {
					const { is_pf_only } = loan_application;
					//flowType과 기존 대출 플로우가 같은 경우
					if ((is_pf_only && flowType === FlowType.PFLOAN) || (!is_pf_only && flowType === FlowType.LOANSHOT)) {
						throw new AlertCommonError(
							'기존에 신청하신 대출 결과로 이동합니다.',
							PhoenixErrorCode.HAS_LOAN_APPLICATION,
							'신청중인 대출이 있어요!'
						);
					} else {
						if (flowType === FlowType.PFLOAN) {
							throw new AlertCommonError(
								'취소하고 개인신용대출 서비스를 이용하시겠어요?',
								PhoenixErrorCode.HAS_LOAN_APPLICATION_DIFFERENT_FLOW,
								'진행중인 대출이 있어요!',
								loan_application.id
							);
						} else {
							throw new AlertCommonError(
								'취소하고 대출비교서비스를 이용하시겠어요?',
								PhoenixErrorCode.HAS_LOAN_APPLICATION_DIFFERENT_FLOW,
								'진행중인 대출이 있어요!',
								loan_application.id
							);
						}
					}
				}
			})
		);

	return action$.pipe(
		ofAction(actions.lsApplyInfo.checkApplyAvailable.started),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					lsCommon: { flowType },
					account: {
						auth: { token = '' },
					},
				},
			]) => {
				if (token) {
					return concat(
						of(actions.layout.startLoading()),
						checkHasLoan(cometAjax, token, flowType).pipe(
							map(() => actions.lsApplyInfo.checkApplyAvailable.done({ params: payload, result: {} })),
							catchError((error) => of(actions.lsApplyInfo.checkApplyAvailable.failed({ params: payload, error })))
						),
						of(actions.layout.endLoading())
					);
				} else {
					return of(actions.lsApplyInfo.checkApplyAvailable.done({ params: payload, result: {} }));
				}
			}
		)
	);
};

const loadReuseMaterial: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(actions.lsApplyInfo.loadReuseMaterial.started),
		withLatestFrom(state$),
		mergeMap(
			([
				,
				{
					account: {
						auth: { token = '' },
						userInfo,
					},
					lsCommon: { flowType, employmentType, houseOwnershipType, medicalInsuranceType, sectorsType, incomeType },
				},
			]) => {
				const notExistErrorAction = of(
					actions.lsApplyInfo.loadReuseMaterial.failed({
						params: {},
						error: new AlertCommonError('', PhoenixErrorCode.REUSABLE_MATERIAL_NOT_EXIST, undefined),
					})
				);

				if (flowType === FlowType.PFLOAN) {
					return notExistErrorAction;
				}

				return concat(
					of(actions.layout.startLoading()),
					cometAjax.loan
						.get('/v1/personal-loan/pre-screening-material', {
							token,
						})
						.pipe(
							mergeMap((response) => {
								const material = response.pre_screening_material;

								if (!material) {
									return notExistErrorAction;
								}

								const resultAction: Action[] = [
									lsApplyJobInfoSlice.setSelectedIncomeType(material.income_type),
									lsApplyJobInfoSlice.setHasIncome(Number(material.annual_income) > 0),
									lsApplyJobInfoSlice.setAnnualIncome(Number(material.annual_income)),
									actions.lsApplyInfo.updateExtraInfo({
										houseOwnership: material.house_ownership_type,
										loanPurpose: material.loan_purpose,
										medicalInsurance: material.medical_insurance_type,
										partnerCode: '',
										carNumber: material.car_number || '',
										reuseMaterial: true,
									}),
								];

								if (material.date_joined && userInfo) {
									resultAction.push(
										lsApplyJobInfoSlice.setDateJoined({
											dateJoined: formatDate(material.date_joined, 'YYYY.MM'),
											minDate: userInfo.birthDate,
										})
									);
								}

								if (material.employment_type) {
									resultAction.push(lsApplyJobInfoSlice.setEmploymentType(material.employment_type));
								}

								if (material.sector_type) {
									resultAction.push(lsApplyJobInfoSlice.setSectorType(material.sector_type));
								}

								if (material.job) {
									resultAction.push(
										lsApplyJobInfoSlice.confirmCompanySearchModal({
											companyName: material.job.company_name,
											cretopInfo: material.job as CompanyInfoState,
										})
									);
								}

								if (!employmentType || !houseOwnershipType || !medicalInsuranceType || !sectorsType || !incomeType) {
									resultAction.push(actions.lsCommon.fetchEnums.started({}));
								}

								return resultAction;
							}),
							catchError((error) =>
								of(
									actions.lsApplyInfo.loadReuseMaterial.failed({
										params: {},
										error: convertInAPICommonErrorToAlertCommonError(error),
									})
								)
							)
						),
					of(actions.layout.endLoading())
				);
			}
		)
	);

export default [
	fetchExistingLoan,
	createExistingLoan,
	getJobInfoFromCretop,
	deleteExistingLoan,
	loadReuseMaterial,
	checkApplyAvailable,
	loadReuseMaterial,
];
