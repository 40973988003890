import { useMemo } from 'react';
import Bowser from 'bowser';

export interface BrowserInfo {
	name: string;
	isMobile: boolean;
	platform: string;
	isAndroid: boolean;
	isSamsung: boolean;
}

export const getBrowserInfo = (userAgent = 'SSR') => {
	// TODO userAgent 를 밖에서 받는데, 여기서 window 객체를 사용해야 할까?
	let ua = userAgent;
	if (typeof window !== 'undefined') {
		ua = window.navigator.userAgent;
	}

	const browser = Bowser.parse(ua);

	const name = () => {
		const matched = browser?.browser?.name?.match(/safari|chrome|edge|trident|opera|samsung/i);
		if (matched && matched.length) {
			return matched[0].toLowerCase() ?? '';
		} else {
			return '';
		}
	};
	const isMobile = () => {
		const mobileDevices = ['android', 'ios', 'windowsphone'];
		return mobileDevices.includes(platform().replace(/\s/g, '').toLowerCase());
	};

	const platform = () => {
		const matched = browser?.os?.name?.match(/windows|ios|android|mac|windows phone/i);
		if (matched && matched.length) {
			return matched[0].toLowerCase() ?? '';
		} else {
			return '';
		}
	};

	const isAndroid = () => {
		return platform().replace(/\s/g, '').toLowerCase() === 'android';
	};
	const isSamsung = () => {
		return name().replace(/\s/g, '').toLowerCase() === 'samsung';
	};

	return {
		name,
		platform,
		isMobile,
		isAndroid,
		isSamsung,
	};
};

interface UseBrowserInfoProps {
	userAgent?: string;
}

export const useBrowserInfo: (props?: UseBrowserInfoProps) => BrowserInfo = ({ userAgent } = {}) => {
	return useMemo(() => {
		const { name, platform, isMobile, isAndroid, isSamsung } = getBrowserInfo(userAgent);
		return {
			get name() {
				return name();
			},
			get isMobile() {
				return isMobile();
			},
			get platform() {
				return platform();
			},
			get isAndroid() {
				return isAndroid();
			},
			get isSamsung() {
				return isSamsung();
			},
		};
	}, [userAgent]);
};
