import { Action, Store } from 'redux';
import { State } from '@peoplefund/reducers';
import actions from '@peoplefund/actions';
import { TokenPayload } from '@peoplefund/utils/axios/TokenStrategy/index';
import AxiosTokenStrategy from '@peoplefund/utils/axios/TokenStrategy/AxiosTokenStrategy';

export default class ReduxTokenStrategy extends AxiosTokenStrategy {
	private store: Store<State, Action> | undefined;

	constructor(defaultAccessToken?: string, store?: Store<State>) {
		super(defaultAccessToken);
		this.store = store;
	}

	getAccessToken(): string | undefined {
		const { store, defaultAccessToken } = this;
		if (!store) {
			return;
		}

		return store.getState().account.auth?.token || defaultAccessToken;
	}

	getRefreshToken(): string | undefined {
		const { store } = this;
		if (!store) {
			return;
		}

		return store.getState().account.auth?.refreshToken;
	}

	protected setToken(payload: TokenPayload): void {
		const { store } = this;
		if (!store) {
			return;
		}

		store.dispatch(actions.account.setToken(payload));
	}

	protected resetToken(): void {
		const { store } = this;
		if (!store) {
			return;
		}

		store.dispatch(actions.layout.tokenExpired());
		store.dispatch(actions.account.resetToken());
	}

	refreshSuccessHandler(payload: TokenPayload): void {
		this.setToken(payload);
	}

	refreshFailHandler(): void {
		const { store } = this;
		if (!store) {
			return;
		}

		this.resetToken();
	}

	setStore(store?: Store<State>) {
		this.store = store;
	}
}
