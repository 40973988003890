import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import WebviewHeaderStrategy from '@peoplefund/utils/axios/HeaderStrategy/WebviewHeaderStrategy';
import AxiosTokenStrategy, { RefreshResponse } from '@peoplefund/utils/axios/TokenStrategy/AxiosTokenStrategy';

export default class ApiClient<
	ApiClientTokenStrategy extends AxiosTokenStrategy,
	ApiClientHeaderStrategy extends WebviewHeaderStrategy
> {
	private client: AxiosInstance;
	private isRefreshing: boolean;
	private failedQueue: {
		resolve: (accessToken?: string) => void;
		reject: (error?: AxiosError) => void;
	}[];
	private refreshingPromise: Promise<AxiosResponse<RefreshResponse>> | null;
	private tokenStrategy: ApiClientTokenStrategy;
	private headerStrategy: ApiClientHeaderStrategy;

	constructor(baseURL: string, tokenStrategy: ApiClientTokenStrategy, headerStrategy: ApiClientHeaderStrategy) {
		this.client = axios.create({
			baseURL: `${baseURL}`,
			headers: {
				'Content-Type': 'application/json',
			},
		});
		this.isRefreshing = false;
		this.failedQueue = [];
		this.tokenStrategy = tokenStrategy;
		this.refreshingPromise = null;
		this.headerStrategy = headerStrategy;

		this.initializeRequestInterceptor();
		this.initializeResponseInterceptor();
	}
	private initializeRequestInterceptor() {
		this.client.interceptors.request.use(
			(config) => {
				const header = this.headerStrategy.getHeaders(this.tokenStrategy, config.headers);

				Object.getOwnPropertyNames(header).forEach((key) => {
					const value = header[key];
					config.headers.set(key, value);
				});

				return config;
			},
			(error) => Promise.reject(error)
		);
	}
	private initializeResponseInterceptor() {
		this.client.interceptors.response.use(
			(response: AxiosResponse) => response,
			async (error) => {
				const originalRequest = error.config;

				const responseBody = error.response?.data as { code: string; message: string };

				if (
					(responseBody?.message === '잘못된 토큰입니다(Payload 형식이 잘못 되었습니다)' ||
						responseBody?.code === 'AUTH:ACCESS_TOKEN_IS_EXPIRED') &&
					!originalRequest._retry
				) {
					if (!this.isRefreshing) {
						const {
							tokenStrategy: { refreshAccessToken },
						} = this;
						this.isRefreshing = true;
						this.refreshingPromise = refreshAccessToken(this.client);
						this.refreshingPromise
							.then(({ data: { access_token: accessToken, refresh_token: refreshToken } }) => {
								this.tokenStrategy.refreshSuccessHandler({
									accessToken,
									refreshToken,
								});
								this.processQueue({ success: true, accessToken });
							})
							.catch((error) => {
								this.tokenStrategy.refreshFailHandler();
								this.processQueue({ success: false, error });
							})
							.finally(() => {
								this.isRefreshing = false;
								this.refreshingPromise = null;
							});
					}

					return new Promise((resolve, reject) => {
						this.failedQueue.push({
							resolve: (token = 'anonymous') => {
								originalRequest.headers['Authorization'] = `Bearer ${token}`;
								resolve(this.client(originalRequest));
							},
							reject,
						});
					});
				}

				return Promise.reject(error);
			}
		);
	}
	private processQueue(payload: { success: true; accessToken: string } | { success: false; error: AxiosError }) {
		this.failedQueue.forEach((failed) => {
			if (payload.success) {
				failed.resolve(payload.accessToken);
			} else {
				failed.reject(payload.error);
			}
		});

		this.failedQueue = [];
	}
	public getApiClient(): AxiosInstance {
		return this.client;
	}
}
