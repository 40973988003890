import { TokenPayload } from '@peoplefund/utils/axios/TokenStrategy/index';
import ReduxTokenStrategy from '@peoplefund/utils/axios/TokenStrategy/ReduxTokenStrategy';
import { Store } from 'redux';
import { State } from '@peoplefund/reducers';
import WebviewHandler from '@peoplefund/utils/webview-handler/index.util';

export default class PhoenixTokenStrategy extends ReduxTokenStrategy {
	private webviewHandler: WebviewHandler;

	constructor(webviewHandler: WebviewHandler, defaultAccessToken?: string, store?: Store<State>) {
		super(defaultAccessToken, store);

		this.webviewHandler = webviewHandler;
	}

	refreshSuccessHandler(payload: TokenPayload): void {
		super.refreshSuccessHandler(payload);

		this.webviewHandler.bridge.updateToken({ access_token: payload.accessToken, refresh_token: payload.refreshToken });
	}

	refreshFailHandler(): void {
		super.refreshFailHandler();

		//this.webviewHandler.bridge.nextAction('logout');
	}
}
