import AuthenticationUrls from './authentication';
import InvestingUrls from './investings';
import InvestingPersonalLoansUrls from './investings/personal-loans';
import LoansAutoPayUrls from './loans/autopay';
import LoansMortgageLoansUrls from './loans/mortgage-loans';
import LoansCompareMortgagesUrls from './loans/mortgage-loans-compare';
import LoansPersonalLoansUrls from './loans/personal-loans';
import StockLoansUrls from './loans/stock-loans';
import LuxuryAssetLoansChicUrls from './luxury-asset-loans/chic';

/**
 * URL을 한 곳에서 관리합니다.
 * 1. 새로 추가 하는 경우 : 서비스 도메인에 맞게 키 + url을 추가해줍니다.
 * 2. url이 너무 많아지면 파일을 분리해서 사용합니다.
 * */

const PageUrls = {
	HOME: '/',
	/** 계정 */
	auth: AuthenticationUrls,
	aml: {
		HOME: '/aml',
		ENGLISH_NAME: '/aml/english-name',
		PHONE_NUMBER: '/aml/phone-number',
		ADDRESS: '/aml/address',
		VERIFY: '/aml/verify',
		COMPLETE: '/aml/complete',
	},
	/** 대출 */
	personalLoans: LoansPersonalLoansUrls,
	loansAutoPay: LoansAutoPayUrls,
	mortgageLoans: LoansMortgageLoansUrls,
	loansCompareMortgage: LoansCompareMortgagesUrls,
	luxuryAssetLoansChic: LuxuryAssetLoansChicUrls,
	stockLoans: StockLoansUrls,
	loansCompare: {
		INTRO: `/loans/compare`,
		RESULT: `/loans/compare/result`,
		PRODUCT: `/loans/compare/product`,
		REJECT: `/loans/compare/reject`,
		APP_DOWNLOAD: `/loans/compare/app-download`,
	},
	/** 투자 */
	personalLoansInvesting: InvestingPersonalLoansUrls,
	investing: InvestingUrls,
	/** 이벤트+프로모션 */
	event: {
		CORPORATE: '/corporate',
		LOSSKING_INVEST: 'lossking-invest',
	},
	/** 기타 */
	mypage: {
		LOAN: {
			HOME: '/loans/mypage',
			DETAIL: (id: string) => `/loans/mypage/${id}`,
			STOCK_LOANS_REPAYMENT: (id: string) => `/loans/mypage/repayment/stock-loans/${id}`,
		},
		INVEST: '/mypage/invest',
		INVEST_PORTFOLIO: '/mypage/invest/#menu=list&listType=portfolio&listStatus=전체',
	},
	company: {
		TEAM: '/team',
		STATS: '/stats',
		FAQ: '/faq',
		NOTICE: '/notice',
		ANNUAL_REPORT: '/company/annual-report',
	},
	vote: {
		HOME: '/vote',
		LIST: '/vote/list',
		VOTE: `/vote/detail`,
		VOTE_COMPLETE: '/vote/complete',
	},
	pfct: {
		HOME: 'https://www.pfct.co.kr',
		NEWS_ROOM: 'https://www.pfct.co.kr/newsroom',
		RECRUIT: 'https://pfct.career.greetinghr.com',
		BLOG: 'https://blog.pfct.co.kr',
	},
	community: {
		PREVIEW: () => `/appview/community/cple`,
		DETAIL: (id: number) => `/appview/community/cple/posts/${id}`,
		REPLY_DETAIL: (id: number) => `/appview/community/cple/replies/${id}`,
		CREATE_POST: () => `/appview/community/cple/create-post`,
		USER_PROFILE: (id: number) => `/appview/community/cple/profiles/${id}`,
	},
	card: {
		HOME: '/appview/card/home',
	},
	tarot: {
		HOME: '/appview/events/tarot',
	},
};

export default PageUrls;

export type MLBaseUrlType = typeof PageUrls.mortgageLoans | typeof PageUrls.loansCompareMortgage;
