import actions from '@peoplefund/actions';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { concat, of, zip } from 'rxjs';
import { ofAction } from '@peoplefund/utils/redux.util';
import { CometEpic } from '@peoplefund/epics/constants.util';
import { convertEnumsToSelectOptionProps, convertInformations } from '@peoplefund/epics/ls-common.util';
import { EMPLOYMENT_TYPE_CODE, FlowType } from '@peoplefund/constants/ls-loan-types';
import { AGREEMENT_SCENE_PRE_EVALUATION_LOANSHOT } from '@peoplefund/constants/ls-select-options';
import { AlertCommonError, convertInAPICommonErrorToAlertCommonError } from '@peoplefund/constants/error/type';
import { Action } from 'redux';
import * as JobInfoSlice from '@peoplefund/slices/lsApplyJobInfoSlice';
import { BlogArticle } from '@peoplefund/actions/ls-financial-product';

import * as smsVerifyAction from '@peoplefund/slices/sms-verify';
import { getInformations, setError, setIntroInformations } from '@peoplefund/slices/common-loan';

const fetchEnums: CometEpic = (action$, _state$, { cometAjax }) =>
	action$.pipe(
		ofAction(actions.lsCommon.fetchEnums.started),
		mergeMap(({ payload }) =>
			cometAjax.loan.get('/v1/metas/enums').pipe(
				map((response) =>
					actions.lsCommon.fetchEnums.done({
						params: payload,
						result: {
							incomeType: response.JobType,
							loanPurposeType: response.LoanPurposeType,
							mortgageLoanPurposeType: response.MortgageLoanPurposeType,
							employmentType: response.EmploymentType,
							medicalInsuranceType: response.MedicalInsuranceType,
							houseOwnershipType: response.HouseholderType,
							sectorsType: response.BusinessIndustryType,
							institutionType: response.InstitutionType,
							incomeTypeOptions: convertEnumsToSelectOptionProps(response.JobType),
							loanPurposeOptions: convertEnumsToSelectOptionProps(response.LoanPurposeType),
							employmentTypeOptions: convertEnumsToSelectOptionProps<EMPLOYMENT_TYPE_CODE>(response.EmploymentType),
							medicalInsuranceTypeOptions: convertEnumsToSelectOptionProps(response.MedicalInsuranceType),
							houseOwnershipTypeOptions: convertEnumsToSelectOptionProps(response.HouseholderType),
							sectorsTypeOptions: convertEnumsToSelectOptionProps(response.BusinessIndustryType),
							institutionTypeOptions: convertEnumsToSelectOptionProps(response.InstitutionType),
						},
					})
				),
				catchError((error) =>
					of(
						actions.lsCommon.fetchEnums.failed({
							params: payload,
							error: convertInAPICommonErrorToAlertCommonError(error),
						})
					)
				)
			)
		)
	);

const intro: CometEpic = (action$, _state$, { cometAjax }) =>
	action$.pipe(
		ofAction(actions.lsCommon.intro.started),
		mergeMap(() =>
			concat(
				of(actions.layout.startLoading()),
				cometAjax.loan.get('/v1/stats/pf/personal').pipe(
					map((response) => {
						return actions.lsCommon.intro.done({
							params: {},
							result: {
								totalLoanAmount: response.total_loan_amount,
								totalLoanCount: response.total_loan_count,
								interestSavings: response.interest_savings,
							},
						});
					}),
					catchError((error) =>
						of(
							actions.lsCommon.intro.failed({
								params: {},
								error: convertInAPICommonErrorToAlertCommonError(error),
							})
						)
					)
				),
				of(actions.layout.endLoading())
			)
		)
	);

const fetchMetas: CometEpic = (action$, _state$, { cometAjax }) =>
	action$.pipe(
		ofAction(actions.lsCommon.fetchMetas.started),
		mergeMap(({ payload }) =>
			cometAjax.loan.get('/v1/metas').pipe(
				map((response) =>
					actions.lsCommon.fetchMetas.done({
						params: payload,
						result: response.meta,
					})
				),
				catchError((error) =>
					of(
						actions.lsCommon.fetchMetas.failed({
							params: payload,
							error: convertInAPICommonErrorToAlertCommonError(error),
						})
					)
				)
			)
		)
	);

const loadLoansCompareIntro: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(actions.lsCommon.loadLoansCompareIntro.started),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					lsCommon: { intro, agreements },
				},
			]) => {
				const firstActions: Action[] = [
					actions.lsCommon.setFlowType(FlowType.LOANSHOT),
					actions.lsLoan.init(),
					actions.lsFinancialProduct.init(),
					actions.lsApplyInfo.init(),
					JobInfoSlice.initialize(),
					smsVerifyAction.initialize(),
				];

				const secondActions: Action[] = [];
				const sceneName = AGREEMENT_SCENE_PRE_EVALUATION_LOANSHOT;
				const agreement = agreements[sceneName];
				if (!agreement) {
					secondActions.push(actions.lsCommon.fetchAgreements.started(sceneName));
				}

				if (intro['loansCompare']) {
					return concat(firstActions, secondActions);
				} else {
					return zip(
						cometAjax.loan.get('/v1/web-intro'),
						cometAjax.pelican.get('/gateway/product-notice/loanshot-notice/')
					).pipe(
						mergeMap(([introInfo, noticeInfo]) => {
							return concat(
								firstActions,
								secondActions,
								of(
									actions.lsCommon.loadLoansCompareIntro.done({
										params: payload,
										result: {
											article: introInfo.articles.map((item: unknown) => item as BlogArticle),
											notice: noticeInfo.notice,
											certificationNumber: noticeInfo.certification_number,
										},
									})
								)
							);
						}),
						catchError((error) =>
							of(
								actions.lsCommon.loadLoansCompareIntro.failed({
									params: payload,
									error: convertInAPICommonErrorToAlertCommonError(error),
								})
							)
						)
					);
				}
			}
		)
	);
};

const getInformationEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(getInformations),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { code, loanType },
				},
			]) =>
				concat(
					of(actions.layout.startLoading()),
					cometAjax.pelican.get(`/gateway/product-notice/affiliate-product-notice/${code}/`).pipe(
						map((res) => setIntroInformations({ loanType, ...convertInformations(res) })),
						catchError(() =>
							of(setError(new AlertCommonError('상품 정보를 불러오는데 실패했습니다.\n잠시후 다시 시도해주세요.')))
						)
					),
					of(actions.layout.endLoading())
				)
		)
	);
};

export default [fetchEnums, intro, fetchMetas, loadLoansCompareIntro, getInformationEpic];
