const BaseUrl = `/investing`;

export default {
	CART: `${BaseUrl}/cart`,
	CPLE_MAIN: `/appview${BaseUrl}/cple/main`,
	DETAIL: `${BaseUrl}/detail`,
	CPLE_GUIDE_ONBOARDING: `/appview${BaseUrl}/cple/guide/onboarding`,
	CPLE_GUIDE_BUILDING: `/appview${BaseUrl}/cple/guide/building`,
	LIST: `${BaseUrl}/list`,
	CLOSED_PRODUCT_LIST: `${BaseUrl}/list/closed-product`,
	BEGINNER_PRODUCT_LIST: `${BaseUrl}/list/beginner`,
	COMPLETE: `${BaseUrl}/complete`,
	PROMOTION: `${BaseUrl}/promotion`,
	REGISTER_INTRO: `${BaseUrl}/register`,
	REGISTER_AML: `${BaseUrl}/register/aml`,
	REGISTER_VERIFY_ID_CARD: `${BaseUrl}/register/verify-id-card`,
	REGISTER_VERIFY_BANK_ACCOUNT: `${BaseUrl}/register/verify-bank-account`,
	REGISTER_UPLOAD_DOCUMENTS_FOR_CORPORATE: `${BaseUrl}/register/corporate/upload-documents`,
	REGISTER_COMPLETE: `${BaseUrl}/register/complete`,
	REGISTER_COMPLETE_FOR_CORPORATE: `${BaseUrl}/register/corporate/complete`,
	WITHDRAW_COMPLETE: `${BaseUrl}/withdraw/complete`,
	ONBOARDING: `${BaseUrl}/onboarding`,
	ONBOARDING_CORE_SUMMARY: `${BaseUrl}/onboarding/core-summary`,
	ONBOARDING_SECOND_SALARY: `${BaseUrl}/onboarding/second-salary`,
	STATISTICS: `${BaseUrl}/statistics`,
	FAQ: `${BaseUrl}/faq`,
	INVEST: `${BaseUrl}/invest`,
	FAIL: `${BaseUrl}/fail`,
};
