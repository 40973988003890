import { reducerWithInitialState } from 'typescript-fsa-reducers';
import Immutable from 'immutability-helper';
import actions from '@peoplefund/actions';
import { CommissionInfo, FinancialProducts } from '@peoplefund/constants/ls-loan-types';
import { InAPICommonError } from '@peoplefund/constants/error/type';

export interface LSFinancialProduct {
	products: FinancialProducts;
	commissionInfo: CommissionInfo;
	error?: InAPICommonError;
}

export const lsFinancialProductInitialState: LSFinancialProduct = {
	products: {},
	commissionInfo: {} as CommissionInfo,
	error: undefined,
};

export default {
	lsFinancialProduct: reducerWithInitialState<LSFinancialProduct>(lsFinancialProductInitialState)
		.cases([actions.common.init, actions.lsFinancialProduct.init], (state) =>
			Immutable(state, { $set: lsFinancialProductInitialState })
		)
		.case(actions.lsFinancialProduct.resetError, (state) =>
			Immutable(state, {
				error: { $set: undefined },
			})
		)
		.cases(
			[actions.lsLoan.loadLoan.done, actions.lsLoan.loadCompareResult.done, actions.lsLoan.loadLoanWithFPA.done],
			(state, { result }) =>
				Immutable(state, {
					products: { $merge: result.fp },
				})
		)
		.cases(
			[actions.lsLoan.loadLoan.failed, actions.lsLoan.loadCompareResult.failed, actions.lsLoan.loadLoanWithFPA.failed],
			(state, { error }) =>
				Immutable(state, {
					error: { $set: error },
				})
		)
		.case(actions.lsFinancialProduct.getFinancialProductCommissionDetail.started, (state) =>
			Immutable(state, {
				error: { $set: undefined },
			})
		)
		.case(actions.lsFinancialProduct.getFinancialProductCommissionDetail.done, (state, { params, result }) =>
			Immutable(state, {
				commissionInfo: {
					[params]: { $set: result },
				},
			})
		)
		.case(actions.lsFinancialProduct.getFinancialProductCommissionDetail.failed, (state, { error }) =>
			Immutable(state, {
				error: { $set: error },
			})
		)
		.case(actions.lsFinancialProduct.setFinancialProductDetail, (state, { id, ...data }) =>
			Immutable(state, {
				products: {
					[id]: {
						information: { $set: data.information },
					},
				},
			})
		),
};
