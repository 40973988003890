import React, { PropsWithChildren, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@peoplefund/store';
import BasicAlert from '@peoplefund/components/common/Alert';
import { useRouter } from 'next/router';
import PageUrls from '@peoplefund/constants/page-urls';
import actions from '@peoplefund/actions';
import WebviewHandler from '@peoplefund/utils/webview-handler/index.util';
const TokenExpiredErrorBoundary: React.FC<PropsWithChildren<unknown>> = ({
  children
}) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const tokenExpired = useAppSelector(state => state.layout.tokenExpired);
  const onClickAlert = useCallback(() => {
    const webviewHandler = WebviewHandler();
    if (webviewHandler.isWebview) {
      webviewHandler.bridge.nextAction('logout');
    } else {
      router.push({
        pathname: PageUrls.auth.LOGIN,
        query: {
          ...router.query,
          r: router.asPath
        }
      }).then(() => {
        dispatch(actions.common.init());
      });
    }
  }, [dispatch, router]);
  return <>
			{tokenExpired ? <BasicAlert colorType="lime" visible={true} title={'로그인이 만료되었어요'} confirmText={'다시 로그인하기'} onConfirm={onClickAlert} onMaskClick={onClickAlert} /> : children}
		</>;
};
export default TokenExpiredErrorBoundary;