import { ProductInformationProps } from '@peoplefund/components/common/ProductInformation';
import { AlertCommonError } from '@peoplefund/constants/error/type';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

const SLICE_NAME = 'commonLoan';
type LOAN_TYPE = 'PERSONAL' | 'MORTGAGE';
export interface CommonLoan {
	error?: AlertCommonError;
	informations: {
		PERSONAL: ProductInformationProps;
		MORTGAGE: ProductInformationProps;
	};
}

export const commonLoanInitialState: CommonLoan = {
	error: undefined,
	informations: {
		PERSONAL: {
			main: [],
			sub: [],
			disclaimer: [],
		},
		MORTGAGE: {
			main: [],
			sub: [],
			disclaimer: [],
		},
	},
};

export const getInformations = createAction<{ loanType: LOAN_TYPE; code: string }>(`${SLICE_NAME}/getInformations`);

export const commonLoanSlice = createSlice({
	name: SLICE_NAME,
	initialState: commonLoanInitialState,
	reducers: {
		initialize: (state) => Object.assign(state, commonLoanInitialState),
		setError: (state, action: PayloadAction<AlertCommonError>) => {
			state.error = action.payload;
		},
		resetError: (state) => {
			state.error = undefined;
		},
		setIntroInformations: (state, action: PayloadAction<{ loanType: LOAN_TYPE } & ProductInformationProps>) => {
			state.informations[action.payload.loanType] = { ...action.payload };
		},
	},
});

export const { initialize, setError, resetError, setIntroInformations } = commonLoanSlice.actions;

export default commonLoanSlice.reducer;
