enum LOG_EVENT_NAME {
	// ONE_APP (파이어베이스 이벤트랑 같이 쓰기 위한 이벤트 이름. 네이밍 법칙이 완전히 달라짐)
	Intro_Banner_Click = 'Intro_Banner_Click',
	Intro_View = 'Intro_View',
	Intro_Next = 'Intro_Next',
	IntroServiceAgreement_View = 'IntroServiceAgreement_View',
	IntroServiceAgreement_Next = 'IntroServiceAgreement_Next',
	Phoneverification_View = 'Phoneverification_View',
	Phoneverification_Next = 'Phoneverification_Next',
	PhoneverificationNumber_View = 'PhoneverificationNumber_View',
	PhoneverificationNumber_Next = 'PhoneverificationNumber_Next',
	PreEvalApplyJobSelection_View = 'PreEvalApplyJobSelection_View',
	PreEvalApplyJobSelection_Next = 'PreEvalApplyJobSelection_Next',
	PreEvalApplyJobInfo_View = 'PreEvalApplyJobInfo_View',
	PreEvalApplyJobInfo_Next = 'PreEvalApplyJobInfo_Next',
	PreEvalChangePurposeRefinance_View = 'PreEvalChangePurposeRefinance_View',
	PreEvalChangePurposeRefinance_Next = 'PreEvalChangePurposeRefinance_Next',
	PreEvalChangePurposeRefinance_Partners = 'PreEvalChangePurposeRefinance_Partners',
	PreEvalRefinance_View = 'PreEvalRefinance_View',
	PreEvalRefinance_Detail = 'PreEvalRefinance_Detail',
	PreEvalRefinanceDetail_View = 'PreEvalRefinanceDetail_View',
	PreEvalRefinanceDetail_Next = 'PreEvalRefinanceDetail_Next',
	PreEvalRefinance_AddLoan = 'PreEvalRefinance_AddLoan',
	PreEvalRefinanceAddLoan_View = 'PreEvalRefinanceAddLoan_View',
	PreEvalRefinanceAddLoan_Next = 'PreEvalRefinanceAddLoan_Next',
	PreEvalRefinance_Next = 'PreEvalRefinance_Next',
	PreEvalLoading_View = 'PreEvalLoading_View',
	WebToAppTrial_View = 'WebToAppTrial_View',
	WebToAppTrial_AppDownload = 'WebToAppTrial_AppDownload',
	PreEvalResult1_View = 'PreEvalResult1_View',
	PreEvalDetail_View = 'PreEvalDetail_View',
	PreEvalDetail_Next = 'PreEvalDetail_Next',

	/** 개신대 */
	loan_preevaluation__denied_vw_ = 'loan_preevaluation__denied_vw_',
	loan_preevaluation__denied_ck_mortgage = 'loan_preevaluation__denied_ck_mortgage',
	loan_preevaluation__denied_ck_loanshot = 'loan_preevaluation__denied_ck_loanshot',
	loan_preevaluation__denied_ck_cta = 'loan_preevaluation__denied_ck_cta',
	loan_detail_notification__ck_ = 'loan_detail_notification__ck_',
	loan_detail_notification__vw_ = 'loan_detail_notification__vw_',

	/** 론샷용 이벤트 (공통) */
	LSIntro_View = 'Intro_LS_View',
	LSIntro_Next = 'Intro_LS_Next',
	LoanSelect_View = 'LoanSelect_View',
	LoanSelect_Next = 'LoanSelect_Next',
	PreEvalResult_View = 'PreEvalResult_View',
	PreEvalResult_HigherLimit = 'PreEvalResult_HigherLimit',
	PreEvalResult_LowerInterest = 'PreEvalResult_LowerInterest',
	PreEvalResult_Apply = 'PreEvalResult_Apply',
	PreEvalResult_Retry = 'PreEvalResult_Retry' /* TODO: 검수 필요 */,
	PreEvalResultRetry_Click = 'PreEvalResultRetry_Click',
	PreEvalResultRetry_View = 'PreEvalResultRetry_View',
	PreEvalResultDenied_View = 'PreEvalResultDenied_View',
	PreEvalResultDenied_Next = 'PreEvalResultDenied_Next',
	PreScreenMaterialReuse_View = 'PreScreenMaterialReuse_View',
	PreScreenMaterialReuse_Next = 'PreScreenMaterialReuse_Next',
	PreScreenMaterialReuse_Cancel = 'PreScreenMaterialReuse_Cancel ',
	PersonalAppDownload_View = 'PersonalAppDownload_View',
	PersonalAppDownload_Next = 'PersonalAppDownload_Next',
	ApplicationApplied_View = 'ApplicationApplied_View',
	ApplicationApplied_Next = 'ApplicationApplied_Next',
	ApplicationApplied_Back = 'ApplicationApplied_Back',

	/** GNB 이벤트 */
	GNBPersonalLoansClick = 'GNB_PersonalLoans_Click',
	GNBMortgageLoansClick = 'GNB_MortgageLoans_Click',
	GNBCompareClick = 'GNB_Compare_Click',

	// 대출 브릿지 페이지
	Landing_View = 'Landing_View',
	Landing_AppDownload = 'Landing_AppDownload',

	// MKT 브릿지 페이지
	DefaultLanding_AppDownload_View = 'DefaultLanding_AppDownload_View',
	DefaultLanding_AppDownload_Next = 'DefaultLanding_AppDownload_Next',

	// 주담대 페이지
	SecuredIntro_View = 'SecuredIntro_View',
	SecuredIntro_Next = 'SecuredIntro_Next',
	SecuredIntro_Call_Click = 'SecuredIntro_Call_Click',
	MortgageServiceAgreement_View = 'MortgageServiceAgreement_View',
	MortgageServiceAgreement_Next = 'MortgageServiceAgreement_Next',
	MortgageServiceAgreement_Back = 'MortgageServiceAgreement_Back',
	MortgagePhoneVerification_View = 'MortgagePhoneVerification_View',
	MortgagePhoneVerification_Next = 'MortgagePhoneVerification_Next',
	MortgagePhoneVerification_Back = 'MortgagePhoneVerification_Back',
	MortgagePhoneVerification_Error = 'MortgagePhoneVerification_Error',
	MortgagePhoneVerificationNumber_View = 'MortgagePhoneVerificationNumber_View',
	MortgagePhoneVerificationNumber_Next = 'MortgagePhoneVerificationNumber_Next',
	MortgagePhoneVerificationNumber_Retry = 'MortgagePhoneVerificationNumber_Retry',
	MortgagePhoneVerificationNumber_Back = 'MortgagePhoneVerificationNumber_Back',
	MortgagePhoneVerificationNumber_Error = 'MortgagePhoneVerificationNumber_Error',
	MortgageLoanPurpose_View = 'MortgageLoanPurpose_View',
	MortgageLoanPurpose_Next = 'MortgageLoanPurpose_Next',
	MortgageLoanPurpose_Back = 'MortgageLoanPurpose_Back',
	MortgageLoanAmount_View = 'MortgageLoanAmount_View',
	MortgageLoanAmount_Next = 'MortgageLoanAmount_Next',
	MortgageLoanAmount_Back = 'MortgageLoanAmount_Back',
	MortgageJobSelection_View = 'MortgageJobSelection_View',
	MortgageJobSelection_Next = 'MortgageJobSelection_Next',
	MortgageJobSelection_Back = 'MortgageJobSelection_Back',
	MortgageAnnualIncome_View = 'MortgageAnnualIncome_View',
	MortgageAnnualIncome_Next = 'MortgageAnnualIncome_Next',
	MortgageAnnualIncome_Back = 'MortgageAnnualIncome_Back',
	MortgageAddressSelection_View = 'MortgageAddressSelection_View',
	MortgageAddressSelection_Other = 'MortgageAddressSelection_Other',
	MortgageAddressSelection_Next = 'MortgageAddressSelection_Next',
	MortgageAddressSelection_Back = 'MortgageAddressSelection_Back',
	MortgageAddressSearch_View = 'MortgageAddressSearch_View',
	MortgageAddressSearch_Back = 'MortgageAddressSearch_Back',
	MortgageAddressSearch_Next = 'MortgageAddressSearch_Next',
	MortgageDongSelection_View = 'MortgageDongSelection_View',
	MortgageDongSelection_Next = 'MortgageDongSelection_Next',
	MortgageDongSelection_Back = 'MortgageDongSelection_Back',
	MortgageHoSelection_View = 'MortgageHoSelection_View',
	MortgageHoSelection_Next = 'MortgageHoSelection_Next',
	MortgageHoSelection_Back = 'MortgageHoSelection_Back',
	MortgageAddressConfirm_View = 'MortgageAddressConfirm_View',
	MortgageAddressConfirm_Edit = 'MortgageAddressConfirm_Edit',
	MortgageAddressConfirm_Next = 'MortgageAddressConfirm_Next',
	MortgageAddressConfirm_Back = 'MortgageAddressConfirm_Back',
	ln_address_confirm_error_vw_ = 'ln_address_confirm_error_vw_',
	ln_address_confirm_error_ck_cta = 'ln_address_confirm_error_ck_cta',
	ln_address_confirm_error_ck_home = 'ln_address_confirm_error_ck_home',
	MortgageResidentSelection_View = 'MortgageResidentSelection_View',
	MortgageResidentSelection_Next = 'MortgageResidentSelection_Next',
	MortgagePreEvaluation_View = 'MortgagePreEvaluation_View',
	MortgagePreEvaluation_Back = 'MortgagePreEvaluation_Back',
	MortgagePreEvaluation_Error = 'MortgagePreEvaluation_Error',
	MortgagePreEvaluationResult_View = 'MortgagePreEvaluationResult_View',
	MortgagePreEvaluationResult_Amount = 'MortgagePreEvaluationResult_Amount',
	MortgagePreEvaluationResult_Tooltip = 'MortgagePreEvaluationResult_Tooltip',
	MortgagePreEvaluationResult_Retry = 'MortgagePreEvaluationResult_Retry',
	MortgagePreEvaluationResultRetry_View = 'MortgagePreEvaluationResultRetry_View',
	MortgagePreEvaluationResultRetry_Click = 'MortgagePreEvaluationResultRetry_Click',
	MortgagePreEvaluationResult_Next = 'MortgagePreEvaluationResult_Next',
	MortgagePreEvaluationResult_Back = 'MortgagePreEvaluationResult_Back',
	MortgagePreEvalResultLoanAmount_View = 'MortgagePreEvalResultLoanAmount_View',
	MortgagePreEvalResultLoanAmount_Next = 'MortgagePreEvalResultLoanAmount_Next',
	MortgageTenantConfirm_View = 'MortgageTenantConfirm_View',
	MortgageTenantConfirm_Next = 'MortgageTenantConfirm_Next',
	MortgageTenantConfirm_Back = 'MortgageTenantConfirm_Back',

	ml_intro___vw_ = 'ml_intro___vw_',
	ml_intro___ck_cta = 'ml_intro___ck_cta',

	// 주담대 전세퇴거자금
	loan_moveout___ck_back = 'loan_moveout___ck_back',
	loan_moveout_renttype__ck_no = 'loan_moveout_renttype__ck_no',
	loan_moveout_renttype__ck_yes = 'loan_moveout_renttype__ck_yes',
	loan_moveout_renttype__vw_ = 'loan_moveout_renttype__vw_',
	loan_moveout___ck_cta = 'loan_moveout___ck_cta',
	loan_moveout___vw_ = 'loan_moveout___vw_',

	// 주담대 연계 (핀다)
	loan_infocheck___vw_ = 'loan_infocheck___vw_',
	loan_infocheck___ck_cta = 'loan_infocheck___ck_cta',
	loan_infocheck___ck_again = 'loan_infocheck___ck_again',

	// 주담대 론샷
	MortgageBizRegistrationNo_View = 'MortgageBizRegistrationNo_View',
	MortgageBizRegistrationNo_Other = 'MortgageBizRegistrationNo_Other',
	MortgageBizRegistrationNo_Next = 'MortgageBizRegistrationNo_Next',
	MortgageBizRegistrationNo_Back = 'MortgageBizRegistrationNo_Back',
	MortgageFirstPurchase_View = 'MortgageFirstPurchase_View',
	MortgageFirstPurchase_Next = 'MortgageFirstPurchase_Next',

	// 투자 페이지
	InvestLanding_AppDownload = 'InvestLanding_AppDownload',
	InvestLanding_Page_View = 'InvestLanding_Page_View',
	// 투자 - 딜페이지
	InvML_View = 'InvML_View',
	InvML_Next = 'InvML_Next',
	InvML_Cancel = 'InvML_Cancel',
	InvML_Change = 'InvML_Change',
	InvML_Schedule_Next = 'InvML_Schedule_Next',
	InvML_Map_Zoom = 'InvML_Map_Zoom',

	InvMLChange_Next = 'InvMLChange_Next',
	InvMLCancel_Next = 'InvMLCancel_Next',

	InvPL_View = 'InvPL_View',
	InvPL_Schedule_Next = 'InvPL_Schedule_Next',

	// 투자
	inv_product__close_vw_ = 'inv_product__close_vw_',
	inv_product__open_ck_cta = 'inv_product__open_ck_cta',
	inv_product__open_ck_schedule = 'inv_product__open_ck_schedule',
	inv_product__open_vw_ = 'inv_product__open_vw_',
	inv_product_cancel__vw_ = 'inv_product_cancel__vw_',
	inv_product_change__ck_exit = 'inv_product_change__ck_exit',
	inv_product_change__ck_cta = 'inv_product_change__ck_cta',
	inv_product_cancel__ck_exit = 'inv_product_cancel__ck_exit',
	inv_product_cancel__ck_cta = 'inv_product_cancel__ck_cta',
	inv_product__live_ck_change = 'inv_product__live_ck_change',
	inv_product__live_ck_cancel = 'inv_product__live_ck_cancel',
	inv_product__live_ck_schedule = 'inv_product__live_ck_schedule',
	inv_product__live_ck_cta = 'inv_product__live_ck_cta',
	inv_product__live_ck_shop = 'inv_product__live_ck_shop',
	inv_product__live_vw_ = 'inv_product__live_vw_',
	inv_investcomplete___ck_cta = 'inv_investcomplete___ck_cta',
	inv_investcomplete___vw_ = 'inv_investcomplete___vw_',
	inv_cart_agree__ck_cta = 'inv_cart_agree__ck_cta',
	inv_cart_agree__vw_ = 'inv_cart_agree__vw_',
	inv_cart_deletecomplete__ck_cta = 'inv_cart_deletecomplete__ck_cta',
	inv_cart_deletecomplete__vw_ = 'inv_cart_deletecomplete__vw_',
	inv_cart_delete__ck_exit = 'inv_cart_delete__ck_exit',
	inv_cart_delete__ck_cta = 'inv_cart_delete__ck_cta',
	inv_cart_delete__vw_ = 'inv_cart_delete__vw_',
	inv_changecart___ck_cta = 'inv_changecart___ck_cta',
	inv_changecart___vw_ = 'inv_changecart___vw_',
	inv_cart___ck_delete = 'inv_cart___ck_delete',
	inv_cart___ck_change = 'inv_cart___ck_change',
	inv_cart_charge__ck_cta = 'inv_cart_charge__ck_cta',
	inv_cart_charge__ck_copy = 'inv_cart_charge__ck_copy',
	inv_cart_charge__vw_ = 'inv_cart_charge__vw_',
	inv_cart___ck_charge = 'inv_cart___ck_charge',
	inv_cart___ck_cta = 'inv_cart___ck_cta',
	inv_cart___vw_ = 'inv_cart___vw_',
	inv_shop_unavailable__ck_exit = 'inv_shop_unavailable__ck_exit',
	inv_shop_unavailable__ck_cta = 'inv_shop_unavailable__ck_cta',
	inv_shop_unavailable__vw_ = 'inv_shop_unavailable__vw_',
	inv_shop_complete__ck_exit = 'inv_shop_complete__ck_exit',
	inv_shop_complete__ck_cta = 'inv_shop_complete__ck_cta',
	inv_shop_complete__vw_ = 'inv_shop_complete__vw_',
	inv_shop___ck_cta = 'inv_shop___ck_cta',
	inv_shop___vw_ = 'inv_shop___vw_',
	inv_apply_agree__ck_cta = 'inv_apply_agree__ck_cta',
	inv_apply_agree__vw_ = 'inv_apply_agree__vw_',
	inv_apply_deposit__vw_ = 'inv_apply_deposit__vw_',
	inv_apply___ck_deposit = 'inv_apply___ck_deposit',
	inv_apply___ck_cta = 'inv_apply___ck_cta',
	inv_apply___vw_ = 'inv_apply___vw_',
	inv_product_login__vw_ = 'inv_product_login__vw_',
	inv_product_login__ck_cta = 'inv_product_login__ck_cta',
	inv_product_login__ck_exit = 'inv_product_login__ck_exit',
	inv_product_amount__vw_ = 'inv_product_amount__vw_',
	inv_product_amount__ck_point = 'inv_product_amount__ck_point',
	inv_product_amount__ck_cta = 'inv_product_amount__ck_cta',
	inv_product_amount__exit_ = 'inv_product_amount__exit_',
	inv_product_amount__ck_charge = 'inv_product_amount__ck_charge',
	inv_product_charge__vw_ = 'inv_product_charge__vw_',
	inv_product_charge__ck_cta = 'inv_product_charge__ck_cta',
	inv_product_charge__ck_back = 'inv_product_charge__ck_back',
	inv_product_charge__ck_exit = 'inv_product_charge__ck_exit',
	inv_product_point__vw_ = 'inv_product_point__vw_',
	inv_product_point__ck_max = 'inv_product_point__ck_max',
	inv_product_point__ck_cta = 'inv_product_point__ck_cta',
	inv_product_point__ck_back = 'inv_product_point__ck_back',
	inv_product_point__ck_exit = 'inv_product_point__ck_exit',
	inv_product_agree__vw_ = 'inv_product_agree__vw_',
	inv_product_agree__ck_agree = 'inv_product_agree__ck_agree',
	inv_product_agree__ck_disagree = 'inv_product_agree__ck_disagree',
	inv_cart_available__vw_ = 'inv_cart_available__vw_',
	inv_cart_available__ck_cta = 'inv_cart_available__ck_cta',
	inv_cart_available__ck_exit = 'inv_cart_available__ck_exit',
	inv_cart___ck_deleteproduct = 'inv_cart___ck_deleteproduct',
	inv_cart__ck_agree_ = 'inv_cart__ck_agree_',
	inv_cart__ck_cta = 'inv_cart__ck_cta',
	inv_cart_agree__ck_disagree = 'inv_cart_agree__ck_disagree',
	inv_cart_none__vw_ = 'inv_cart_none__vw_',
	inv_cart_none__ck_cta = 'inv_cart_none__ck_cta',
	inv_cart_disagree__vw_ = 'inv_cart_disagree__vw_',
	inv_cart_disagree__ck_cta = 'inv_cart_disagree__ck_cta',
	inv_cart_disagree__ck_exit = 'inv_cart_disagree__ck_exit',
	inv_product___ck_applytype = 'inv_product___ck_applytype',
	inv_product___ck_pointall = 'inv_product___ck_pointall',
	inv_sum___vw_ = 'inv_sum___vw_',
	com_gnb___ck_invest = 'com_gnb___ck_invest',
	inv_sum___ck_qna = 'inv_sum___ck_qna',
	inv_sum___ck_personal = 'inv_sum___ck_personal',
	inv_sum___ck_register = 'inv_sum___ck_register',
	inv_sum___ck_tab = 'inv_sum___ck_tab',
	inv_sum___ck_guide = 'inv_sum___ck_guide',
	inv_sum___ck_product = 'inv_sum___ck_product',
	inv_sum___ck_list = 'inv_sum___ck_list',
	inv_sum___ck_special = 'inv_sum___ck_special',
	inv_sum___ck_producttype = 'inv_sum___ck_producttype',
	inv_list__ck_tab_ = 'inv_list__ck_tab_',
	inv_pfguide___ck_cta = 'inv_pfguide___ck_cta',
	inv_productguide___ck_product = 'inv_productguide___ck_product',
	inv_list___ck_productguide = 'inv_list___ck_productguide',
	inv_apartguide___ck_cta = 'inv_apartguide___ck_cta',
	inv_apartguide___ck_product = 'inv_apartguide___ck_product',
	inv_apartguide___vw_ = 'inv_apartguide___vw_',
	inv_pfguide___vw_ = 'inv_pfguide___vw_',
	inv_list_condition__ck_reset = 'inv_list_condition__ck_reset',
	inv_list_condition__ck_cta = 'inv_list_condition__ck_cta',
	inv_list_condition__vw_ = 'inv_list_condition__vw_',
	inv_list___ck_chip = 'inv_list___ck_chip',
	inv_product_amount__ck_max = 'inv_product_amount__ck_max',
	inv_list___ck_cta = 'inv_list___ck_cta',
	inv_product_cancel_live_ck_yes = 'inv_product_cancel_live_ck_yes',
	inv_product_cancel_live_vw_ = 'inv_product_cancel_live_vw_',
	inv_exit___ck_stay = 'inv_exit___ck_stay',
	inv_exit___ck_exit = 'inv_exit___ck_exit',
	inv_exit___vw_ = 'inv_exit___vw_',
	inv_main___ck_mypage = 'inv_main___ck_mypage',
	inv_main___ck_cart = 'inv_main___ck_cart',
	tab_main___ck_ = 'tab_main___ck_',
	inv_cart___ck_cancelalarm = 'inv_cart___ck_cancelalarm',
	inv_cart___ck_setalarm = 'inv_cart___ck_setalarm',
	inv_product__open_ck_setalarm = 'inv_product__open_ck_setalarm',
	inv_product__open_ck_cancelalarm = 'inv_product__open_ck_cancelalarm',
	inv_list___ck_setalarm = 'inv_list___ck_setalarm',
	inv_list___ck_cancelalarm = 'inv_list___ck_cancelalarm',

	inv_list_listingalarm__vw_ = 'inv_list_listingalarm__vw_',
	inv_list_listingalarm__ck_term = 'inv_list_listingalarm__ck_term',
	inv_list_listingalarm__ck_cta = 'inv_list_listingalarm__ck_cta',

	inv_list___ck_listingalarm = 'inv_list___ck_listingalarm',

	inv_investcomplete___ck_listingalarm = 'inv_investcomplete___ck_listingalarm',

	inv_compatentry___vw_ = 'inv_compatentry___vw_',
	inv_compatentry___ck_cta = 'inv_compatentry___ck_cta',

	/** 주담투 리스트 */
	inv_list___vw_ = 'inv_list___vw_',
	inv_list_filter__ck_reset = 'inv_list_filter__ck_reset',
	inv_list_filter__ck_exit = 'inv_list_filter__ck_exit',
	inv_list_filter__ck_cta = 'inv_list_filter__ck_cta',
	inv_list_filter__vw_ = 'inv_list_filter__vw_',
	inv_list___ck_filter = 'inv_list___ck_filter',
	inv_list___ck_status = 'inv_list___ck_status',
	inv_list_guide__vw_ = 'inv_list_guide__vw_',
	inv_list___ck_guide = 'inv_list___ck_guide',
	inv_list___ck_order = 'inv_list___ck_order',

	/** 투자모집TF 법인투자소개페이지 */
	inv_corporate___vw_ = 'inv_corporate___vw_',
	inv_corporate___ck_cta = 'inv_corporate___ck_cta',
	inv_corporate_apply__vw_ = 'inv_corporate_apply__vw_',
	inv_corporate_apply__ck_cta = 'inv_corporate_apply__ck_cta',

	/** 크플 전환 */
	inv_newtocple___ck_pfcontinue = 'inv_newtocple___ck_pfcontinue',
	inv_newtocple___ck_exit = 'inv_newtocple___ck_exit',
	inv_newtocple___ck_cta = 'inv_newtocple___ck_cta',
	inv_newtocple___vw_ = 'inv_newtocple___vw_',

	// 기타
	Common_Load_Data = 'Common_Load_Data',

	//대출 자동이체 신청
	CMSOnboard_View = 'CMSOnboard_View',
	CMSOnboard_Next = 'CMSOnboard_Next',
	CMSLogin_View = 'CMSLogin_View',
	CMSLogin_Next = 'CMSLogin_Next',
	CMSLogin_Back = 'CMSLogin_Back',
	CMSLoginTerms_View = 'CMSLoginTerms_View',
	CMSLoginTerms_Next = 'CMSLoginTerms_Next',
	CMSSMSOTP_View = 'CMSSMSOTP_View',
	CMSSMSOTP_Next = 'CMSSMSOTP_Next',
	CMSSMSOTP_Retry = 'CMSSMSOTP_Retry',
	CMSSMSOTP_Back = 'CMSSMSOTP_Back',
	CMSNoLoan_View = 'CMSNoLoan_View',
	CMSNoLoan_Next = 'CMSNoLoan_Next',
	CMSLoanList_View = 'CMSLoanList_View',
	CMSLoanList_Next = 'CMSLoanList_Next',
	CMSLoanList_Back = 'CMSLoanList_Back',
	CMSLoanList_Alert_View = 'CMSLoanList_Alert_View',
	CMSLoanList_Alert_Next = 'CMSLoanList_Alert_Next',
	CMSTerms_View = 'CMSTerms_View',
	CMSTerms_Next = 'CMSTerms_Next',
	CMSTerms_Back = 'CMSTerms_Back',
	CMSInfo_View = 'CMSInfo_View',
	CMSInfo_Next = 'CMSInfo_Next',
	CMSInfo_Account = 'CMSInfo_Account',
	CMSSameAccount_Error = 'CMSSameAccount_Error',
	CMSAccount_View = 'CMSAccount_View',
	CMSAccount_Next = 'CMSAccount_Next',
	CMSAccount_Back = 'CMSAccount_Back',
	CMSAccount_Error = 'CMSAccount_Error',
	CMSBankType_View = 'CMSBankType_View',
	CMSBankType_Next = 'CMSBankType_Next',
	CMSBankType_Back = 'CMSBankType_Back',
	CMSARS_View = 'CMSARS_View',
	CMSARS_Next = 'CMSARS_Next',
	CMSARS_Help = 'CMSARS_Help',
	CMSARS_Back = 'CMSARS_Back',
	CMSARS_Error = 'CMSARS_Error',
	CMSComplete_View = 'CMSComplete_View',
	CMSComplete_Next = 'CMSComplete_Next',

	// 투자자 투표
	VoteOnboard_View = 'VoteOnboard_View',
	VoteOnboard_Next = 'VoteOnboard_Next',
	VoteNoVote_View = 'VoteNoVote_View',
	VoteNoVote_Next = 'VoteNoVote_Next',
	VoteList_View = 'VoteList_View',
	VoteList_Next = 'VoteList_Next',
	VoteList_Back = 'VoteList_Back',
	VoteDetail_View = 'VoteDetail_View',
	VoteDetail_View_Error = 'VoteDetail_View_Error',
	VoteDetail_Option = 'VoteDetail_Option',
	VoteDetail_Back = 'VoteDetail_Back',
	VoteDetail_Next = 'VoteDetail_Next',
	VoteDetail_Next_Error = 'VoteDetail_Next_Error',
	VoteComplete_View = 'VoteComplete_View',
	VoteComplete_Next = 'VoteComplete_Next',

	// 법인투자 등록 플로우
	inv_bizUpload___vw_ = 'inv_bizUpload___vw_',
	inv_bizBankaccount___ck_cta = 'inv_bizBankaccount___ck_cta',
	inv_bizBankaccount___vw_ = 'inv_bizBankaccount___vw_',
	inv_bizEmail_exist__ck_exit = 'inv_bizEmail_exist__ck_exit',
	inv_bizEmail_exist__ck_cta = 'inv_bizEmail_exist__ck_cta',
	inv_bizEmail_exist__vw_ = 'inv_bizEmail_exist__vw_',
	inv_bizEmail___ck_cta = 'inv_bizEmail___ck_cta',
	inv_bizEmail___vw_ = 'inv_bizEmail___vw_',
	inv_bizcontact___ck_cta = 'inv_bizcontact___ck_cta',
	inv_bizcontact___vw_ = 'inv_bizcontact___vw_',
	inv_bizbridge___ck_cta = 'inv_bizbridge___ck_cta',
	inv_bizbridge___ck_tooltip = 'inv_bizbridge___ck_tooltip',
	inv_bizbridge___vw_ = 'inv_bizbridge___vw_',
	inv_bizbridge_agree__ck_cta = 'inv_bizbridge_agree__ck_cta',
	inv_bizbridge_agree__vw_ = 'inv_bizbridge_agree__vw_',

	// 투자자 온보딩
	inv_register___vw_ = 'inv_register___vw_',
	inv_register___ck_cta = 'inv_register___ck_cta',
	inv_aml___vw_ = 'inv_aml___vw_',
	inv_aml___ck_cta = 'inv_aml___ck_cta',
	inv_idcard___vw_ = 'inv_idcard___vw_',
	inv_idcard___ck_cta = 'inv_idcard___ck_cta',
	inv_idcard_photo__vw_ = 'inv_idcard_photo__vw_',
	inv_idcard_photo__ck_cta = 'inv_idcard_photo__ck_cta',
	inv_idcard_photo__ck_exit = 'inv_idcard_photo__ck_exit',
	inv_idcheck___vw_ = 'inv_idcheck___vw_',
	inv_idcheck_select__vw_ = 'inv_idcheck_select__vw_',
	inv_idcheck_select__ck_cta = 'inv_idcheck_select__ck_cta',
	inv_idinfo__register_vw_ = 'inv_idinfo__register_vw_',
	inv_idinfo__register_ck_cta = 'inv_idinfo__register_ck_cta',
	inv_idinfo__drivers_vw_ = 'inv_idinfo__drivers_vw_',
	inv_idinfo__drivers_ck_cta = 'inv_idinfo__drivers_ck_cta',
	inv_bankselect___vw_ = 'inv_bankselect___vw_',
	inv_bankselect___ck_cta = 'inv_bankselect___ck_cta',
	inv_bankaccount___vw_ = 'inv_bankaccount___vw_',
	inv_bankaccount___ck_cta = 'inv_bankaccount___ck_cta',
	inv_onewon___vw_ = 'inv_onewon___vw_',
	inv_onewon___ck_cta = 'inv_onewon___ck_cta',
	inv_onewon___ck_help = 'inv_onewon___ck_help',
	inv_onewon_help__vw_ = 'inv_onewon_help__vw_',
	inv_onewon_help__ck_cta = 'inv_onewon_help__ck_cta',
	inv_email___vw_ = 'inv_email___vw_',
	inv_email___ck_cta = 'inv_email___ck_cta',
	inv_ready___vw_ = 'inv_ready___vw_',
	inv_ready___ck_cta = 'inv_ready___ck_cta',

	/** 투자자 홈탭 */
	inv_mainv1___vw_end = 'inv_mainv1___vw_end',
	inv_list_5000p__ck_exit = 'inv_list_5000p__ck_exit',
	inv_list_5000p__ck_cta = 'inv_list_5000p__ck_cta',
	inv_list_5000p__vw_ = 'inv_list_5000p__vw_',
	inv_faq___ck_faq = 'inv_faq___ck_faq',
	inv_faq___vw_ = 'inv_faq___vw_',
	inv_aptguide___ck_cancelalarm = 'inv_aptguide___ck_cancelalarm',
	inv_aptguide___ck_setalarm = 'inv_aptguide___ck_setalarm',
	inv_mainv1___ck_cancelscfalarm = 'inv_mainv1___ck_cancelscfalarm',
	inv_mainv1___ck_setscfalarm = 'inv_mainv1___ck_setscfalarm',
	inv_aptguide___ck_cta = 'inv_aptguide___ck_cta',
	inv_aptguide___ck_product = 'inv_aptguide___ck_product',
	inv_aptguide___ck_tab = 'inv_aptguide___ck_tab',
	inv_aptguide___vw_ = 'inv_aptguide___vw_',
	inv_2ndsalary___ck_back = 'inv_2ndsalary___ck_back',
	inv_2ndsalary___ck_cta = 'inv_2ndsalary___ck_cta',
	inv_2ndsalary___vw_ = 'inv_2ndsalary___vw_',
	inv_mainv1___ck_contents = 'inv_mainv1___ck_contents',
	inv_mainv1___ck_faq = 'inv_mainv1___ck_faq',
	inv_onboarding___ck_cta = 'inv_onboarding___ck_cta',
	inv_onboarding___ck_back = 'inv_onboarding___ck_back',
	inv_onboarding___vw_ = 'inv_onboarding___vw_',
	inv_promotion___ck_cancelalarm = 'inv_promotion___ck_cancelalarm',
	inv_promotion___ck_back = 'inv_promotion___ck_back',
	inv_promotion___ck_setalarm = 'inv_promotion___ck_setalarm',
	inv_promotion___ck_product = 'inv_promotion___ck_product',
	inv_promotion___vw_ = 'inv_promotion___vw_',
	inv_freeinvest___ck_back = 'inv_freeinvest___ck_back',
	inv_initialguide___ck_back = 'inv_initialguide___ck_back',
	inv_mainv1___ck_menu = 'inv_mainv1___ck_menu',
	inv_mainv1___ck_alarm = 'inv_mainv1___ck_alarm',
	inv_mainv1___ck_cart = 'inv_mainv1___ck_cart',
	inv_freeinvest___ck_profit = 'inv_freeinvest___ck_profit',
	inv_freeinvest___ck_product = 'inv_freeinvest___ck_product',
	inv_freeinvest___vw_ = 'inv_freeinvest___vw_',
	inv_mainv1___ck_myinvest = 'inv_mainv1___ck_myinvest',
	inv_mainv1___ck_mywallet = 'inv_mainv1___ck_mywallet',
	inv_mainv1___ck_mypoint = 'inv_mainv1___ck_mypoint',
	inv_mainv1___ck_freeinvest = 'inv_mainv1___ck_freeinvest',
	inv_initialguide___ck_cta = 'inv_initialguide___ck_cta',
	inv_initialguide___vw_ = 'inv_initialguide___vw_',
	inv_mainv1___ck_statistics = 'inv_mainv1___ck_statistics',
	inv_mainv1___ck_investable5000p = 'inv_mainv1___ck_investable5000p',
	inv_mainv1___ck_investableall = 'inv_mainv1___ck_investableall',
	inv_mainv1___ck_investable = 'inv_mainv1___ck_investable',
	inv_mainv1___ck_investableproduct = 'inv_mainv1___ck_investableproduct',
	inv_mainv1___ck_taball = 'inv_mainv1___ck_taball',
	inv_mainv1___ck_tabproduct = 'inv_mainv1___ck_tabproduct',
	inv_mainv1___ck_tab = 'inv_mainv1___ck_tab',
	inv_mainv1___ck_scfproduct = 'inv_mainv1___ck_scfproduct',
	inv_mainv1___ck_promotion = 'inv_mainv1___ck_promotion',
	inv_mainv1___ck_list = 'inv_mainv1___ck_list',
	inv_mainv1___ck_shortcuts = 'inv_mainv1___ck_shortcuts',
	inv_mainv1___ck_5000p = 'inv_mainv1___ck_5000p',
	inv_mainv1___ck_profit = 'inv_mainv1___ck_profit',
	inv_mainv1___ck_onboarding = 'inv_mainv1___ck_onboarding',
	inv_mainv1___vw_ = 'inv_mainv1___vw_',
	inv_initialguide___ck_previous = 'inv_initialguide___ck_previous',
	inv_statistics___vw_ = 'inv_statistics___vw_',
	inv_statistics___ck_cta = 'inv_statistics___ck_cta',

	/** 투자자 회원가입 */
	inv_servagree___vw_ = 'inv_servagree___vw_',
	inv_servagree___ck_cta = 'inv_servagree___ck_cta',
	inv_info___vw_ = 'inv_info___vw_',
	inv_info___ck_cta = 'inv_info___ck_cta',
	inv_input___vw_ = 'inv_input___vw_',
	inv_input___ck_cta = 'inv_input___ck_cta',
	inv_number___vw_ = 'inv_number___vw_',
	inv_number___ck_cta = 'inv_number___ck_cta',
	inv_number___ck_retry = 'inv_number___ck_retry',
	inv_newuser___vw_ = 'inv_newuser___vw_',

	/** 투자 플로우 */
	inv_amount_agree__ck_exit = 'inv_amount_agree__ck_exit',
	inv_amount_agree__ck_cta = 'inv_amount_agree__ck_cta',
	inv_amount_agree__vw_ = 'inv_amount_agree__vw_',
	inv_investcomplete___ck_invmain = 'inv_investcomplete___ck_invmain',
	inv_investcharge_copy__ck_exit = 'inv_investcharge_copy__ck_exit',
	inv_investcharge_copy__ck_bankapp = 'inv_investcharge_copy__ck_bankapp',
	inv_investcharge_copy__vw_ = 'inv_investcharge_copy__vw_',
	inv_investcharge___ck_cta = 'inv_investcharge___ck_cta',
	inv_investcharge___ck_copy = 'inv_investcharge___ck_copy',
	inv_investcharge___ck_exit = 'inv_investcharge___ck_exit',
	inv_investcharge___vw_ = 'inv_investcharge__vw_',
	inv_pointcoupon___ck_nopoint = 'inv_pointcoupon___ck_nopoint',
	inv_pointcoupon___ck_maxpoint = 'inv_pointcoupon___ck_maxpoint',
	inv_pointcoupon___ck_cta = 'inv_pointcoupon___ck_cta',
	inv_pointcoupon___ck_back = 'inv_pointcoupon___ck_back',
	inv_pointcoupon___ck_max = 'inv_pointcoupon___ck_max',
	inv_pointcoupon___vw_ = 'inv_pointcoupon___vw_',
	inv_amount___ck_cta = 'inv_amount___ck_cta',
	inv_amount___ck_pointcoupon = 'inv_amount___ck_pointcoupon',
	inv_amount___ck_back = 'inv_amount___ck_back',
	inv_amount___ck_max = 'inv_amount___ck_max',
	inv_amount___vw_ = 'inv_amount___vw_',
	inv_product_shop__ck_exit = 'inv_product_shop__ck_exit',
	inv_product_shop__ck_cta = 'inv_product_shop__ck_cta',
	inv_product_shop__vw_ = 'inv_product_shop__vw_',
	inv_investfail___ck_cta = 'inv_investfail___ck_cta',
	inv_investfail___vw_ = 'inv_investfail___vw_',

	/** 법인투자자 회원가입 */
	inv_bizUpload___ck_list = 'inv_bizUpload___ck_list',
	inv_bizUpload___ck_upload = 'inv_bizUpload___ck_upload',
	inv_bizUpload___ck_delete = 'inv_bizUpload___ck_delete',
	inv_bizUpload___ck_cta = 'inv_bizUpload___ck_cta',
	inv_bizRegisterDone___vw_ = 'inv_bizRegisterDone___vw_',

	/** 공통_GNB */
	com_main_gnb__vw_ = 'com_main_gnb__vw_',
	com_main___ck_gnb = 'com_main___ck_gnb',
	com____ck_mlinv = 'com____ck_mlinv',
	com____ck_plinv = 'com____ck_plinv',
	com____ck_ai = 'com____ck_ai',
	com____ck_ml = 'com____ck_ml',
	com_gnb___ck_luxury = 'com_gnb___ck_luxury',
	com____ck_pl = 'com____ck_pl',
	com____ck_login = 'com____ck_login',
	com____ck_create = 'com____ck_create',
	com_main_gnb__ck_shop = 'com_main_gnb__ck_shop',
	com_main_gnb__ck_pfcthome = 'com_main_gnb__ck_pfcthome',

	/** 로그인 */
	usr_email___vw_ = 'usr_email___vw_',
	usr_email___ck_cta = 'usr_email___ck_cta',
	usr_email___ck_phone = 'usr_email___ck_phone',
	usr_email___ck_id = 'usr_email___ck_id',
	usr_email___ck_password = 'usr_email___ck_password',
	usr_email___ck_create = 'usr_email___ck_create',
	usr_email___ck_combine = 'usr_email___ck_combine',

	/** AML 재이행 */
	inv_product_edd_live_vw_ = 'inv_product_edd_live_vw_',
	inv_product_edd_live_ck_cta = 'inv_product_edd_live_ck_cta',
	inv_product_edd_live_ck_exit = 'inv_product_edd_live_ck_exit',
	usr_edd___vw_ = 'usr_edd___vw_',
	usr_edd___ck_nation = 'usr_edd___ck_nation',
	usr_edd___ck_eng = 'usr_edd___ck_eng',
	usr_edd___ck_phone = 'usr_edd___ck_phone',
	usr_edd___ck_address = 'usr_edd___ck_address',
	usr_edd___ck_job = 'usr_edd___ck_job',
	usr_edd___ck_industry = 'usr_edd___ck_industry',
	usr_edd___ck_source = 'usr_edd___ck_source',
	usr_edd___ck_purpose = 'usr_edd___ck_purpose',
	usr_edd___ck_account = 'usr_edd___ck_account',
	usr_edd___ck_help = 'usr_edd___ck_help',
	usr_edd___ck_back = 'usr_edd___ck_back',
	usr_edd___ck_cta = 'usr_edd___ck_cta',
	usr_edd_job__vw_ = 'usr_edd_job__vw_',
	usr_edd_job__ck_cta = 'usr_edd_job__ck_cta',
	usr_edd_industry__vw_ = 'usr_edd_industry__vw_',
	usr_edd_industry__ck_cta = 'usr_edd_industry__ck_cta',
	usr_edd_source__vw_ = 'usr_edd_source__vw_',
	usr_edd_source__ck_cta = 'usr_edd_source__ck_cta',
	usr_edd_othersource__vw_ = 'usr_edd_othersource__vw_',
	usr_edd_othersource__ck_cta = 'usr_edd_othersource__ck_cta',
	usr_edd_othersource__ck_back = 'usr_edd_othersource__ck_back',
	usr_edd_purpose__vw_ = 'usr_edd_purpose__vw_',
	usr_edd_purpose__ck_cta = 'usr_edd_purpose__ck_cta',
	usr_edd_otherpurpose__vw_ = 'usr_edd_otherpurpose__vw_',
	usr_edd_otherpurpose__ck_cta = 'usr_edd_otherpurpose__ck_cta',
	usr_edd_otherpurpose__ck_back = 'usr_edd_otherpurpose__ck_back',
	usr_edd_account__vw_ = 'usr_edd_account__vw_',
	usr_edd_account__ck_cta = 'usr_edd_account__ck_cta',
	usr_edd_error__vw_ = 'usr_edd_error__vw_',
	usr_edd_help__vw_ = 'usr_edd_help__vw_',
	usr_eng___vw_ = 'usr_eng___vw_',
	usr_eng___ck_cta = 'usr_eng___ck_cta',
	usr_eng___ck_back = 'usr_eng___ck_back',
	usr_eddphone___vw_ = 'usr_eddphone___vw_',
	usr_eddphone___ck_cta = 'usr_eddphone___ck_cta',
	usr_eddphone___ck_back = 'usr_eddphone___ck_back',
	usr_eddnum___vw_ = 'usr_eddnum___vw_',
	usr_eddnum___ck_cta = 'usr_eddnum___ck_cta',
	usr_eddnum___ck_retry = 'usr_eddnum___ck_retry',
	usr_eddnum___ck_back = 'usr_eddnum___ck_back',
	usr_eddnum_done__vw_ = 'usr_eddnum_done__vw_',
	usr_eddnum_done__ck_cta = 'usr_eddnum_done__ck_cta',
	usr_eddaddress___vw_ = 'usr_eddaddress___vw_',
	usr_eddaddress___ck_country = 'usr_eddaddress___ck_country',
	usr_eddaddress___ck_cta = 'usr_eddaddress___ck_cta',
	usr_eddaddress___ck_back = 'usr_eddaddress___ck_back',
	usr_eddaddress_search__vw_ = 'usr_eddaddress_search__vw_',
	usr_eddaddress_search__ck_cta = 'usr_eddaddress_search__ck_cta',
	usr_eddaddress_search__ck_exit = 'usr_eddaddress_search__ck_exit',
	usr_eddaddress_country__vw_ = 'usr_eddaddress_country__vw_',
	usr_eddaddress_country__ck_cta = 'usr_eddaddress_country__ck_cta',
	usr_verifyphone___vw_ = 'usr_verifyphone___vw_',
	usr_verifyphone___ck_cta = 'usr_verifyphone___ck_cta',
	usr_verifyphone___ck_back = 'usr_verifyphone___ck_back',
	usr_verifynum___vw_ = 'usr_verifynum___vw_',
	usr_verifynum___ck_cta = 'usr_verifynum___ck_cta',
	usr_verifynum___ck_retry = 'usr_verifynum___ck_retry',
	usr_verifynum___ck_back = 'usr_verifynum___ck_back',
	usr_eddcomplete___vw_ = 'usr_eddcomplete___vw_',
	usr_eddcomplete___ck_cta = 'usr_eddcomplete___ck_cta',

	/** 토스 주담대 브릿지 A/B 테스트 */
	loan_tossbridge__a_vw_ = 'loan_tossbridge__a_vw_',
	loan_tossbridge__a_ck_cta = 'loan_tossbridge__a_ck_cta',

	/** CHIC */
	chic_intro___vw_ = 'chic_intro___vw_',
	chic_intro___ck_cta = 'chic_intro___ck_cta',

	/** 차입자 마이페이지 */
	ln_mypage_list__vw_ = 'ln_mypage_list__vw_',
	ln_mypage_list_error_vw_ = 'ln_mypage_list_error_vw_',
	ln_mypage_list_error_ck_ = 'ln_mypage_list_error_ck_',
	ln_mypage_list_empty_vw_ = 'ln_mypage_list_empty_vw_',
	ln_mypage_list_empty_ck_ = 'ln_mypage_list_empty_ck_',
	ln_mypage_list__ck_detail = 'ln_mypage_list__ck_detail',
	ln_mypage_contact_cx__vw_ = 'ln_mypage_contact_cx__vw_',
	ln_mypage_contact_cx__ck_close = 'ln_mypage_contact_cx__ck_close',
	ln_mypage_contact_cx__ck_call = 'ln_mypage_contact_cx__ck_call',
	ln_mypage_detail__vw_ = 'ln_mypage_detail__vw_',
	ln_mypage_detail__ck_deposit = 'ln_mypage_detail__ck_deposit',
	ln_mypage_deposit__vw_ = 'ln_mypage_deposit__vw_',
	ln_mypage_deposit__ck_confirm = 'ln_mypage_deposit__ck_confirm',
	ln_mypage_deposit__ck_close = 'ln_mypage_deposit__ck_close',
	ln_mypage_detail__ck_copy = 'ln_mypage_detail__ck_copy',
	ln_mypage_detail__ck_schedule = 'ln_mypage_detail__ck_schedule',
	ln_mypage_schedule__vw_ = 'ln_mypage_schedule__vw_',
	ln_mypage_schedule__ck_close = 'ln_mypage_schedule__ck_close',
	ln_mypage_detail__ck_cms_modify = 'ln_mypage_detail__ck_cms_modify',
	ln_mypage_detail__ck_contact_cx = 'ln_mypage_detail__ck_contact_cx',

	/** 크플주담대 도입 */
	ln_rejected_affiliateinfo__ck_CP = 'ln_rejected_affiliateinfo__ck_CP',
	ln_rejected_affiliateinfo__ck_cta = 'ln_rejected_affiliateinfo__ck_cta',
	ln_rejected_affiliateinfo__vw_ = 'ln_rejected_affiliateinfo__vw_',
	ln_rejected_all__ck_cta = 'ln_rejected_all__ck_cta',
	ln_rejected_all__vw_ = 'ln_rejected_all__vw_',
	ln_appinstall___ck_back = 'ln_appinstall___ck_back',
	ln_appinstall___ck_cta = 'ln_appinstall___ck_cta',
	ln_appinstall___vw_ = 'ln_appinstall___vw_',

	lossking_result_bridge__ck_cta = 'lossking_result_bridge__ck_cta',
	lossking_result_bridge__vw_ = 'lossking_result_bridge__vw_',
	lossking_result___ck_inv = 'lossking_result___ck_inv',
	lossking_result___ck_point = 'lossking_result___ck_point',
	lossking_result___vw_ = 'lossking_result___vw_',
	lossking_result_bts__ck_cta = 'lossking_result_bts__ck_cta',
	lossking_result_bts__vw_ = 'lossking_result_bts__vw_',

	/** 크플 홈탭 웹뷰 */
	home_main___ck_topbanner = 'home_main___ck_topbanner',

	/** 크플 - 타로 이벤트 */
	tarot_intro___vw_ = 'tarot_intro___vw_',
	tarot_intro___ck_ = 'tarot_intro___ck_',
	tarot_intro___ck_back = 'tarot_intro___ck_back',
	tarot_topiclist___vw_ = 'tarot_topiclist___vw_',
	tarot_topiclist___ck_topic = 'tarot_topiclist___ck_topic',
	tarot_topiclist_alert__vw_ = 'tarot_topiclist_alert__vw_',
	tarot_topiclist_alert__ck_appdownload = 'tarot_topiclist_alert__ck_appdownload',
	tarot_topiclist_alert__ck_custom = 'tarot_topiclist_alert__ck_custom',
	tarot_cardselect_ready__vw_ = 'tarot_cardselect_ready__vw_',
	tarot_cardselect_ing__vw_ = 'tarot_cardselect_ing__vw_',
	tarot_cardselect_ing__ck_card = 'tarot_cardselect_ing__ck_card',
	tarot_cardselect_complete__vw_ = 'tarot_cardselect_complete__vw_',
	tarot_loading___vw_ = 'tarot_loading___vw_',
	tarot_result___vw_ = 'tarot_result___vw_',
	tarot_result___ck_share = 'tarot_result___ck_share',
	tarot_result___ck_othertopic = 'tarot_result___ck_othertopic',
	tarot_result___ck_appdownload = 'tarot_result___ck_appdownload',
	tarot_result___ck_main = 'tarot_result___ck_main',
	tarot_result___ck_saveimage = 'tarot_result___ck_saveimage',

	/** 크플 -추석 이벤트 */
	event_chuseok24___ck_back = 'event_chuseok24___ck_back',
	event_chuseok24___ck_cta = 'event_chuseok24___ck_cta',
	event_chuseok24___ck_share = 'event_chuseok24___ck_share',
	event_chuseok24___vw_ = 'event_chuseok24___vw_',

	event_chuseok24on___ck_date = 'event_chuseok24on___ck_date', // 추석 이벤트 (출시ver) 페이지 상단 날짜 클릭
	event_chuseok24on___ck_community = 'event_chuseok24on___ck_community', // 추석 이벤트 (출시ver) 페이지 '커뮤니티 글쓰러 가기' 클릭
	event_chuseok24on___ck_tarot = 'event_chuseok24on___ck_tarot', // 추석 이벤트 (출시ver) 페이지 '타로토끼에게 소원 빌러 가기' 클릭
	event_chuseok24on___ck_alarm = 'event_chuseok24on___ck_alarm', // 추석 이벤트 (출시ver) 페이지 '알림설정' 클릭
	event_chuseok24on___ck_cta = 'event_chuseok24on___ck_cta', // 추석 이벤트 (출시ver) 페이지 '꽝 없는 대박 터뜨리기 클릭
	event_chuseok24on___ck_share = 'event_chuseok24on___ck_share', // 추석 이벤트 (출시ver) 페이지 '공유하기' 클릭 (상하단 무관)
	event_chuseok24on___ck_back = 'event_chuseok24on___ck_back', // 추석 이벤트 (출시ver) 페이지 '뒤로가기' 클릭
	event_chuseok24on___vw_ = 'event_chuseok24on___vw_', // 추석 이벤트 (출시ver) 페이지 뷰

	/** 크플 - 커뮤니티 */
	commu_nickname___ck_cta = 'commu_nickname___ck_cta',
	commu_nickname___vw_ = 'commu_nickname___vw_',
	commu_write___ck_cta = 'commu_write___ck_cta',
	commu_write___vw_ = 'commu_write___vw_',
	commu_post___ck_comment = 'commu_post___ck_comment',
	commu_post___vw_ = 'commu_post___vw_',
	commu_main___ck_new = 'commu_main___ck_new',
	commu_main___ck_post = 'commu_main___ck_post',
	commu_main___vw_ = 'commu_main___vw_',
}

export default LOG_EVENT_NAME;
