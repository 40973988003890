const BaseUrl = `/investing/personal-loans`;
const InvestingPersonalLoansUrls = {
  LIST: `${BaseUrl}/list/running`,
  LIST_RUNNING: `${BaseUrl}/list/running`,
  LIST_CLOSE: `${BaseUrl}/list/close`,
  LIST_CANCEL: `${BaseUrl}/list/cancel`,
  UNSECURED_INTRO: `${BaseUrl}/registration`,
  SURVEY: `${BaseUrl}/registration/survey`,
  SUBSCRIPTION: `${BaseUrl}/subscription`,
  APPLY: `${BaseUrl}/application`,
  APPLY_RESULT: `${BaseUrl}/application/result`,
  CANCEL: `${BaseUrl}/cancel`,
  CANCEL_RESULT: `${BaseUrl}/cancel/result`,
  DEAL: (underlyingAssetId: number) => `/invest/v2/detail/${underlyingAssetId}`
};
export default InvestingPersonalLoansUrls;