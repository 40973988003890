import apiClient from '@peoplefund/utils/axios';
import { PayloadWithAuthentication } from '@peoplefund/services/apis/@type';
import { getRequestConfig } from '@peoplefund/utils/axios/index.util';

const baseUrl = '/pipe/inapi/account';

export type FetchAMLStatusResponse = {
	readonly kyc_status:
		| 'verified'
		| 'not_verified'
		| 're_verification_required'
		| 'edd_expired'
		| 'transaction_not_allowed';
	readonly kyc_detail: {
		/** EDD 정보 등록 여부 */
		readonly edd_registered: boolean;
		/** 신분증 인증 여부 */
		readonly identification_verified: boolean;
		/** 계좌 인증 여부 */
		readonly account_verified: boolean;
		/** 계좌 등록 여부 */
		readonly account_registered: boolean;
		/** 이메일 등록 여부 */
		readonly email_registered: boolean;
		/** EDD 재이행 안내 필요 여부 (현재 EDD 정보 만료 30일 전부터 true) */
		readonly edd_expired_soon: boolean;
		/** EDD 정보 만료일 */
		readonly edd_expiration_date: string | null;
	};
	readonly is_foreigner: boolean;
};

//@ref: https://www.notion.so/KYC-0f5124a76424481da07353d6d82820fd
export const apiFetchAMLStatus = async ({ token }: PayloadWithAuthentication) =>
	apiClient.get<FetchAMLStatusResponse>(`${baseUrl}/users/:user_id/status`, getRequestConfig(token));

export const apiSubmitEdd = async ({
	token,
	jobBusinessCode,
	riskyJobBusinessCode,
	moneySource,
}: PayloadWithAuthentication & {
	readonly jobBusinessCode: string;
	readonly riskyJobBusinessCode: string;
	readonly moneySource: string;
}) =>
	apiClient.post(
		`${baseUrl}/users/:user_id/aml/kyc`,
		{
			job_business_code: jobBusinessCode,
			risky_job_business_code: riskyJobBusinessCode,
			money_source: moneySource,
		},
		getRequestConfig(token)
	);

export const apiCheckWLF = async ({ token }: PayloadWithAuthentication) =>
	apiClient.post(`${baseUrl}/users/:user_id/aml/wlf`, {}, getRequestConfig(token));

export const apiGetVerificationStatus = async ({ token }: PayloadWithAuthentication) =>
	apiClient.get<{ readonly verification_account: boolean; readonly verification_identification: boolean }>(
		`${baseUrl}/users/verification/status/`,
		getRequestConfig(token)
	);

export const apiGetCorpUserDocumentStatus = async ({ token }: PayloadWithAuthentication) =>
	apiClient.get<{ readonly is_exists: boolean }>(
		`${baseUrl}/users/:user_id/corp-user-documents/status`,
		getRequestConfig(token)
	);
