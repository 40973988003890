import { Action, AnyAction, combineReducers, Middleware, Store } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';
import rootEpic from '@peoplefund/epics';
import rootReducer, { initialStates, State } from '@peoplefund/reducers';
import { createLogger } from 'redux-logger';
import { cometAjax } from '@peoplefund/epics/ajax.util';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';
import LogRocket from 'logrocket';
import { SanitizingFields } from '@peoplefund/constants/log-rocket';
import { useDispatch, useSelector } from 'react-redux';

export const checkSessionStorage = (): Promise<void> => {
	return new Promise((resolve) => {
		setTimeout(() => {
			const sessionData = sessionStorage.getItem('yourSessionKey');
			if (sessionData) {
				// 세션 스토리지 데이터가 있으면 적절히 처리
				console.log('Session data found:', sessionData);
			} else {
				console.log('No session data found.');
			}
			resolve();
		}, 500); // 0.5초 동안 세션 스토리지 체크
	});
};

// 로컬 스토리지에 상태를 저장할 키와 관리할 리듀서 목록
const PERSISTED_KEYS: Array<keyof typeof initialStates> = [
	'common',
	'lsApplyInfo',
	'lsCommon',
	'lsApplyJobInfo',
	'unsecuredInvestorRegistration',
	'mlLoan',
	'agreementStore',
	'loansAutopay',
	'vote',
	'aml',
	'luxuryAssetLoansChic',
	'stockLoans',
];
// localStorage에 상태를 저장
export const saveState = (state: Partial<typeof initialStates>): void => {
	try {
		const serializedState = JSON.stringify(
			PERSISTED_KEYS.reduce((acc, key) => {
				const value = state[key];
				if (value !== undefined) {
					acc[key] = value as any;
				}
				return acc;
			}, {} as Partial<typeof initialStates>)
		);
		sessionStorage.setItem('appState', serializedState);
	} catch (e) {
		console.error('Could not save state', e);
	}
};

// localStorage에서 상태를 불러오기
export const loadState = (): Partial<typeof initialStates> | undefined => {
	try {
		const serializedState = sessionStorage.getItem('appState');
		if (serializedState === null) {
			return undefined;
		}
		return JSON.parse(serializedState) as Partial<typeof initialStates>;
	} catch (e) {
		console.error('Could not load state', e);
		return undefined;
	}
};

const persistedState = loadState();

export const makeStore: () => Store<State, Action> = () => {
	/** middleware 등록 */
	const epicMiddleware = createEpicMiddleware<Action, Action, State>({
		dependencies: { cometAjax },
	});
	const middleware: Middleware[] = [epicMiddleware];
	if (process.env.NODE_ENV === 'development' && typeof window !== 'undefined') {
		middleware.push(createLogger({ collapsed: true }));
	}

	// LogRocket middleware 는 마지막에 넣어야 함
	interface RecursiveState {
		[key: string]: RecursiveState | Record<string, RecursiveState> | string;
	}
	const customSanitizer = (state: RecursiveState): RecursiveState => {
		const newState: RecursiveState = {};

		for (const key in state) {
			const subState = state[key];
			switch (typeof subState) {
				case 'object':
					newState[key] = customSanitizer(subState);
					break;

				default:
					if (SanitizingFields.includes(key) && subState) {
						newState[key] = '(sanitized)';
					} else {
						newState[key] = subState;
					}
					break;
			}
		}
		return newState;
	};
	const logRocketMiddleware = LogRocket.reduxMiddleware({
		stateSanitizer: customSanitizer,
		actionSanitizer: customSanitizer,
	});
	middleware.push(logRocketMiddleware);

	const reducer = (state: State, action: AnyAction) => {
		if (action.type === HYDRATE) {
			console.log('reducer', action.type, action);
			return {
				...state,
				...action.payload,
			};
		}
		return combineReducers({ ...rootReducer })(state, action);
	};

	const store = configureStore({
		reducer,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				serializableCheck: false, // TODO 액션은 직렬화 가능한 데이터만 주고 받아야 함
			}).concat(middleware),
		devTools: process.env.NODE_ENV !== 'production',
		preloadedState: persistedState || initialStates,
	});

	store.subscribe(() => {
		saveState(store.getState());
	});

	epicMiddleware.run(rootEpic);

	return store;
};

// Infer the type of makeStore
export type AppStore = ReturnType<typeof makeStore>;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();

export const wrapper = createWrapper(makeStore);
