import { InvestmentTagItem } from './investing.model';
import { ProductV2 } from '@peoplefund/slices/common-investing.model';

interface MortgageInfo {
	certified_copy_of_register: string;
	certified_copy_of_register_after_disburse: string;
	location: string;
}

interface InvestmentItem {
	investment_amount: number;
	loss_amount: number;
	remain_amount: number;
	point_amount: number;
}

interface InvestmentProductServerProps {
	funding_amount: number;
	funding_end_datetime: string | null;
	funding_start_datetime: string | null;
	id: number;
	invested_amount: number;
	investments?: InvestmentItem[];
	is_closed: boolean;
	is_investable: boolean;
	legacy_id: number;
	mortgage_info?: MortgageInfo;
	name: string;
	product_type: string | null;
	raised_rate: number;
	tags: InvestmentTagItem[];
	term: number;
	yield_rate: number;
	uri: string;
	is_notified_open_product: boolean;
}

export const convertProductResponseV2 = (response: InvestmentProductServerProps): ProductV2 => {
	const {
		funding_start_datetime,
		id,
		is_closed,
		is_investable,
		investments,
		yield_rate,
		term,
		funding_amount,
		invested_amount,
		mortgage_info,
		name,
		legacy_id,
		uri,
		product_type,
		raised_rate,
		tags,
		is_notified_open_product,
	} = response;

	const result: ProductV2 = {
		id,
		legacyId: legacy_id,
		fundingClosed: is_closed,
		fundingWaiting: !(is_investable || is_closed),
		invested: Boolean(investments?.length),
		investedAmount: investments?.reduce((acc, cur) => acc + cur.investment_amount, 0) || 0,
		pointAmount: investments?.reduce((acc, cur) => acc + cur.point_amount, 0) || 0,
		interestRate: yield_rate,
		investTerm: term,
		targetAmount: funding_amount,
		accumulatedAmount: invested_amount,
		certifiedCopyOfRegister: mortgage_info?.certified_copy_of_register,
		certifiedCopyOfRegisterAfterDisburse: mortgage_info?.certified_copy_of_register_after_disburse,
		location: mortgage_info?.location,
		name,
		uri,
		productType: product_type ?? '',
		fundingStartDatetime: funding_start_datetime ?? '',
		raisedRate: raised_rate * 100 ?? 0,
		tags,
		isNotifiedOpenProduct: is_notified_open_product,
	};
	return result;
};
