// 권장) 모든 웹뷰 관련 분기는 이곳에서 관리하기

import WebviewHandler from '../webview-handler/index.util';

type AppVersion = [number, number, number];

export const checkIsOverAppVersionFromBase = (params: { base: AppVersion }) => {
	const webviewHandler = WebviewHandler();
	const appVersion = webviewHandler.appVersion;
	const isMillion = webviewHandler.appType === 'Million';

	const [major, minor, patch] = (appVersion.match(/\d+/g) ?? []).map(Number);
	const [baseMajor, baseMinor, basePatch] = params.base;

	/** major는 강업일거라서 생각 안하기로 함 */
	const majorAndMinorVersionCheck = major >= baseMajor && minor >= baseMinor;
	const res =
		majorAndMinorVersionCheck && (majorAndMinorVersionCheck ? true : majorAndMinorVersionCheck && patch >= basePatch);

	return isMillion && res;
};

export function getShowCpleInvestNavigationBar() {
	return {
		home: false,
		flow: true,
	};
}

export function getIsTarotButtonVisible() {
	const visible = checkIsOverAppVersionFromBase({ base: [2, 21, 0] });

	return visible;
}
