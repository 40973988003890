import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EMPLOYMENT_TYPE_CODE, INCOME_TYPE_CODE } from '@peoplefund/constants/ls-loan-types';
import { parseDate, TODAY } from '@peoplefund/utils/date.util';
import dayjs from 'dayjs';
import { CompanyInfoState } from '@peoplefund/actions/ls-apply-info';

type ConfirmCompanySearchModalPayload = {
	companyName: string;
	cretopInfo?: CompanyInfoState;
};

export interface LSApplyJobInfoState {
	readonly incomeType?: INCOME_TYPE_CODE;
	readonly employmentType?: EMPLOYMENT_TYPE_CODE;
	readonly dateJoined?: string;
	readonly companyName: string;
	readonly cretopInfo?: CompanyInfoState;
	readonly annualIncome: number;
	readonly hasIncome: boolean;
	readonly companyModalVisible: boolean;
	readonly sectorType?: string;
	readonly annualIncomeValid: boolean;
	readonly companyNameValid: boolean;
	readonly dateJoinedValid: boolean;
	readonly employmentTypeValid: boolean;
	readonly incomeTypeValid: boolean;
	readonly sectorTypeValid: boolean;
	readonly formValid: boolean;
}

export const lsApplyJobInfoInitialState: LSApplyJobInfoState = {
	incomeType: undefined,
	employmentType: undefined,
	dateJoined: undefined,
	companyName: '',
	annualIncome: 0,
	hasIncome: true,
	companyModalVisible: false,
	sectorType: undefined,
	annualIncomeValid: false,
	companyNameValid: false,
	dateJoinedValid: false,
	employmentTypeValid: false,
	incomeTypeValid: false,
	sectorTypeValid: false,
	formValid: false,
};

export const lsApplyJobInfoSlice = createSlice({
	name: 'lsApplyJobInfo',
	initialState: lsApplyJobInfoInitialState,
	reducers: {
		initialize: (state) => Object.assign(state, lsApplyJobInfoInitialState),
		setCompanyName: (state, action: PayloadAction<string>) => {
			state.companyName = action.payload;
			state.companyNameValid = true;
		},
		showCompanyModal: (state) => {
			state.companyModalVisible = true;
		},
		closeCompanySearchModal: (state) => {
			state.companyName = '';
			state.cretopInfo = undefined;
			state.companyModalVisible = false;
			state.companyNameValid = false;
		},
		confirmCompanySearchModal: (state, action: PayloadAction<ConfirmCompanySearchModalPayload>) => {
			state.companyName = action.payload.companyName;
			state.cretopInfo = action.payload.cretopInfo;
			state.companyNameValid = true;
			state.companyModalVisible = false;
		},
		setHasIncome: (state, action: PayloadAction<boolean>) => {
			const hasIncome = action.payload;
			state.hasIncome = hasIncome;
			state.annualIncomeValid = !hasIncome;

			if (!hasIncome) {
				state.annualIncome = 0;
			}
		},
		setDateJoined: (state, action: PayloadAction<{ dateJoined: string | undefined; minDate: string }>) => {
			const { dateJoined = '', minDate } = action.payload;
			state.dateJoined = dateJoined;

			if (Boolean(dateJoined)) {
				const minDateJoined = parseDate(minDate, 'YYYYMMDD');
				const date = parseDate(dateJoined, 'YYYY.MM');
				const formatValid = dayjs(minDateJoined).isValid() && dayjs(date).isValid();
				if (formatValid) {
					state.dateJoinedValid = date >= minDateJoined && date <= TODAY;
				} else {
					state.dateJoinedValid = false;
				}
			} else {
				state.dateJoinedValid = false;
			}
		},
		setEmploymentType: (state, action: PayloadAction<EMPLOYMENT_TYPE_CODE>) => {
			state.employmentType = action.payload;
			state.employmentTypeValid = true;
		},
		setAnnualIncome: (state, action: PayloadAction<number>) => {
			const income = action.payload;
			const hasIncome = state.hasIncome;
			state.annualIncome = income;
			state.annualIncomeValid = !hasIncome || Number(income) >= 1;
		},
		setSelectedIncomeType: (state, action: PayloadAction<INCOME_TYPE_CODE>) => {
			state.incomeType = action.payload;
			state.incomeTypeValid = true;
		},
		setSectorType: (state, action: PayloadAction<string>) => {
			const sectorType = action.payload;
			state.sectorType = sectorType;
			state.sectorTypeValid = Boolean(sectorType);
		},
		setFormValid: (state, action: PayloadAction<boolean>) => {
			state.formValid = action.payload;
		},
	},
});

export const {
	initialize,
	setCompanyName,
	closeCompanySearchModal,
	confirmCompanySearchModal,
	setHasIncome,
	setDateJoined,
	setEmploymentType,
	showCompanyModal,
	setAnnualIncome,
	setSelectedIncomeType,
	setSectorType,
	setFormValid,
} = lsApplyJobInfoSlice.actions;

export default lsApplyJobInfoSlice.reducer;
