type ResizeResult = string | undefined;

export const toResizedBase64 = (file: Blob, width: number, height: number, format: string): Promise<ResizeResult> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = async (e) => {
			resize(`${e?.target?.result ?? ''}`, width, height, format)
				.then((v) => {
					resolve(v);
				})
				.catch((e) => reject(e));
		};
		reader.readAsDataURL(file);
		reader.onerror = (error) => reject(error);
	});

export const resize = (file: string, width: number, height: number, format: string): Promise<ResizeResult> =>
	new Promise((resolve, reject) => {
		const img = document.createElement('img');
		if (img) {
			img.onload = () => {
				const canvas = document.createElement('canvas');
				const ctx = canvas.getContext('2d');
				if (ctx) {
					const { width: iw, height: ih } = img;
					const scale = Math.min(width / iw, height / ih);
					canvas.width = iw * scale;
					canvas.height = ih * scale;
					ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
					resolve(canvas.toDataURL(format));
				} else {
					reject(undefined);
				}
			};
			img.onerror = () => {
				reject(undefined);
			};
			img.src = file;
		} else {
			reject(undefined);
		}
	});

export const removeBase64Meta = (base64Image: ResizeResult): string => {
	if (!base64Image) {
		return '';
	}

	// Base64 문자열에서 콤마 위치를 찾습니다.
	const commaIndex = base64Image.indexOf(',');
	return commaIndex === -1 ? base64Image : base64Image.substring(commaIndex + 1);
};

export const addBase64Meta = (image: string, imageType: string): string => {
	return `data:image/${imageType};base64,${image}`;
};

export const fillTransparentAreaWithWhite = (pngDataUrl: string): Promise<string> => {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.onload = () => {
			const canvas = document.createElement('canvas');
			canvas.width = img.width;
			canvas.height = img.height;
			const context = canvas.getContext('2d');

			if (context) {
				context.fillStyle = 'white'; // 배경 색상을 흰색으로 설정
				context.fillRect(0, 0, canvas.width, canvas.height);
				context.drawImage(img, 0, 0);

				const newImageDataUrl = canvas.toDataURL('image/png');
				resolve(newImageDataUrl);
			} else {
				reject(new Error('Failed to fill transparent area.'));
			}
		};

		img.src = pngDataUrl;
	});
};
