import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ofAction } from '@peoplefund/utils/redux.util';
import { CometEpic } from './constants.util';
import { concat, of } from 'rxjs';
import actions from '@peoplefund/actions';
import {
	PageData,
	fetchCategories,
	fetchCategoryItemDetail,
	fetchFilters,
	fetchProductInfo,
	fetchProductList,
	fetchProductListV2,
	fetchPromotionDetail,
	fetchPromotions,
	finishFetchCategories,
	finishFetchCategoryItemDetail,
	finishFetchProductInfo,
	finishFetchProductList,
	finishFetchProductListV2,
	finishFetchPromotionDetail,
	finishFetchPromotions,
	finishWithdraw,
	setError,
	withdraw,
} from '@peoplefund/slices/common-investing';
import {
	CategoryItemDetailServerResponse,
	CategoryListServerResponse,
	ProductFilterListServerResponse,
	ProductListServerResponse,
	ProductListV2ServerResponse,
	PromotionDetailServerResponse,
	PromotionListServerResponse,
} from './investing.model';
import { findComponentInPageData } from './pl-investing.util';
import { AlertCommonError, convertInAPICommonErrorToAlertCommonError } from '@peoplefund/constants/error/type';
import { PhoenixErrorCode } from '@peoplefund/constants/error/code';
import { convertProductResponseV2 } from './investing.util';
import { getCsrfToken } from './account/index.util';

const fetchPromotionsEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(fetchPromotions),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { action },
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					cometAjax.investment.get(`/v2/products/promotions`).pipe(
						mergeMap((response: PromotionListServerResponse) => {
							const list = response.promotions;
							action({ networkStatus: 'success', list });
							return of(finishFetchPromotions());
						}),
						catchError(() => {
							action({ networkStatus: 'fail', list: [] });
							return of();
						})
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const fetchPromotionDetailEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(fetchPromotionDetail),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { type, action },
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					cometAjax.investmentGateway.get(`/api/v2/products/promotions/${type}`).pipe(
						mergeMap((response: PromotionDetailServerResponse) => {
							action({ networkStatus: 'success', promotionDetail: response });
							return of(finishFetchPromotionDetail());
						}),
						catchError(() => {
							action({ networkStatus: 'fail', promotionDetail: null });
							return of(finishFetchPromotionDetail());
						})
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const fetchCategoriesEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(fetchCategories),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { action },
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					cometAjax.investment.get(`/v2/products/categories`).pipe(
						mergeMap((response: CategoryListServerResponse) => {
							const list = response.categories;
							action({ networkStatus: 'success', list });
							return of(finishFetchCategories());
						}),
						catchError(() => {
							action({ networkStatus: 'fail', list: [] });
							return of();
						})
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const fetchCategoryDetailEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(fetchCategoryItemDetail),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { categoryType, action },
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					cometAjax.investment.get(`/v2/products/categories/detail/${categoryType}`).pipe(
						mergeMap((response: CategoryItemDetailServerResponse) => {
							action({ networkStatus: 'success', value: response });
							return of(finishFetchCategoryItemDetail());
						}),
						catchError(() => {
							action({ networkStatus: 'fail', value: null });
							return of();
						})
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const fetchProductListEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(fetchProductList),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { query, action },
				},
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				let queries = '';

				if (query) {
					queries = Object.keys(query)
						.map(function (key) {
							return encodeURIComponent(key) + '=' + encodeURIComponent(query[key]);
						})
						.join('&');
				}

				return concat(
					of(actions.layout.startLoading()),
					cometAjax.investmentGateway
						.get(`/api/v1/products?${queries}&is_closed=false`, {
							token,
						})
						.pipe(
							mergeMap((response: ProductListServerResponse) => {
								action({ networkStatus: 'success', listInfo: response });
								return of(finishFetchProductList());
							}),
							catchError(() => {
								action({ networkStatus: 'fail', listInfo: null });
								return of();
							})
						),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const fetchProductListV2Epic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(fetchProductListV2),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { query, action },
				},
			]) => {
				let queries = '';

				if (query) {
					queries = Object.keys(query)
						.map(function (key) {
							return encodeURIComponent(key) + '=' + encodeURIComponent(query[key]);
						})
						.join('&');
				}

				return concat(
					of(actions.layout.startLoading()),
					cometAjax.investmentGateway.get(`/api/v1/products?${queries}`).pipe(
						mergeMap((response: ProductListV2ServerResponse) => {
							action({ networkStatus: 'success', listInfo: response });
							return of(finishFetchProductListV2());
						}),
						catchError(() => {
							action({ networkStatus: 'fail', listInfo: null });
							return of();
						})
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const fetchFiltersEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(fetchFilters),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { action, query },
				},
			]) => {
				const querys = query ? `type=${query.type}` : '';
				return concat(
					of(actions.layout.startLoading()),
					cometAjax.investment.get(`/v3/products/filters?${querys}`).pipe(
						mergeMap((response: ProductFilterListServerResponse) => {
							const list = response.filters;
							action({ networkStatus: 'success', list });
							return of(finishFetchCategories());
						}),
						catchError(() => {
							action({ networkStatus: 'fail', list: [] });
							return of();
						})
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const fetchProductInfoEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(fetchProductInfo),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { action, productInfoAction, id },
				},
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					cometAjax.investmentGateway
						.get(`/api/v1/products/${id}`, {
							token,
						})
						.pipe(
							mergeMap((productDataFromInvestmentServer) => {
								const productInfo = convertProductResponseV2(productDataFromInvestmentServer);
								return cometAjax.investment.get(`/v2/products/${id}/product_page`).pipe(
									mergeMap((pageDataResponse) => {
										const accumulatedAmount = productDataFromInvestmentServer.invested_amount ?? 0;
										const targetAmount = productDataFromInvestmentServer.funding_amount ?? 0;

										const pageDatas: PageData[] = pageDataResponse.contents ?? [];
										const componentToPropsMatchingMap: Record<string, any> = {
											ProgressItem: {
												percentage: Number(((accumulatedAmount / targetAmount) * 100).toFixed(1)),
												accumulatedAmount,
												targetAmount,
											},
											AddressMap: {
												location: productDataFromInvestmentServer.mortgage_info?.location ?? '',
											},
											CertifiedButton: {
												certifiedCopyOfRegister:
													productDataFromInvestmentServer.mortgage_info?.certified_copy_of_register,
												certifiedCopyOfRegisterAfterDisburse:
													productDataFromInvestmentServer.mortgage_info?.certified_copy_of_register_after_disburse,
											},
											TagList: {
												tags: productDataFromInvestmentServer.tags,
											},
										};

										for (const key in componentToPropsMatchingMap) {
											findComponentInPageData(pageDatas, key, componentToPropsMatchingMap[key]);
										}
										productInfoAction({ value: productInfo, networkStatus: 'success' });
										action({
											pagedatas: pageDataResponse.contents,
											networkStatus: 'success',
										});
										return [finishFetchProductInfo()];
									})
								);
							}),
							catchError((error) => {
								if (error.code === 'INAPI0404') {
									return of(
										setError(new AlertCommonError('딜페이지가 존재하지 않습니다.', PhoenixErrorCode.DEALPAGE_NOT_FOUND))
									);
								}
								productInfoAction({ value: null, networkStatus: 'fail' });
								action({
									pagedatas: [],
									networkStatus: 'fail',
								});
								return of(setError(error));
							})
						),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

export const withdrawEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(withdraw),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { action, props },
				},
				{
					account: {
						auth: { token = '' },
					},
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					getCsrfToken(cometAjax, token).pipe(
						mergeMap((csrfToken) =>
							cometAjax.inapi
								.delete('/showcase/v1/users/:user_id/statements/', {
									headers: {
										'X-CSRF-TOKEN': csrfToken,
									},
									body: { ...props },
									token,
								})
								.pipe(
									mergeMap(() => {
										action({
											networkStatus: 'success',
										});
										return [finishWithdraw()];
									})
								)
						),
						catchError((error) => {
							action({
								networkStatus: 'fail',
							});
							return of(setError(convertInAPICommonErrorToAlertCommonError(error)));
						})
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

export default [
	fetchPromotionsEpic,
	fetchCategoriesEpic,
	fetchCategoryDetailEpic,
	fetchFiltersEpic,
	fetchProductListEpic,
	fetchProductListV2Epic,
	fetchProductInfoEpic,
	fetchPromotionDetailEpic,
	withdrawEpic,
];
