import { AxiosInstance, AxiosResponse } from 'axios';
import TokenStrategy from '@peoplefund/utils/axios/TokenStrategy';

export type RefreshResponse = { readonly access_token: string; readonly refresh_token: string };

export default abstract class AxiosTokenStrategy extends TokenStrategy<AxiosInstance, AxiosResponse<RefreshResponse>> {
	async refreshAccessToken(apiClient: AxiosInstance): Promise<AxiosResponse<RefreshResponse>> {
		return new Promise((resolve, reject) => {
			const { getRefreshToken } = this;
			const refreshToken = getRefreshToken();
			return apiClient
				.post<RefreshResponse>(`/pipe/loanshot/auth/refresh`, {
					refresh_token: refreshToken,
				})
				.then((response) => {
					return resolve(response);
				})
				.catch((error) => {
					return reject(error);
				});
		});
	}
}
