const BaseUrl = '/loans/mortgage-loans';

const LoansMortgageLoansUrls = {
	INTRO: `${BaseUrl}`,
	APP_DOWNLOAD: `${BaseUrl}/app-download`,

	PARTNER_AGREEMENT: `${BaseUrl}/partner-agreement`,
	AGREEMENTS: `${BaseUrl}/agreements`,
	SMS_SEND: `${BaseUrl}/sms-verify`,
	PURPOSE: `${BaseUrl}/purpose`,
	AMOUNT: `${BaseUrl}/amount`,
	JOB_SELECT: `${BaseUrl}/job-select`,
	JOB_SALARY: `${BaseUrl}/job-salary`,
	PROPERTY_SELECT: `${BaseUrl}/select`,
	ADDRESS_SEARCH: `${BaseUrl}/search`,
	ADDRESS_DONG_SEARCH: `${BaseUrl}/search-dong`,
	ADDRESS_HO_SEARCH: `${BaseUrl}/search-ho`,
	ADDRESS_CONFIRM: `${BaseUrl}/address-confirm`,
	ADDRESS_SEARCH_FAIL: `${BaseUrl}/search-fail`,
	TENANT_CONFIRM: `${BaseUrl}/tenant-confirm`,
	TENANT_LEAVE_INFO: `${BaseUrl}/tenant-leave-info`,
	INQUIRE: `${BaseUrl}/inquire`,
	RESULT: `${BaseUrl}/result`,
	PRODUCT: `${BaseUrl}/product`,
	BRIDGE: ``, // 아직 없지만, loansCompareMortgage 와 type 맞추기위해 추가
	REJECT: `${BaseUrl}/reject`,
	DRAFT_SUBMITTED: `${BaseUrl}/draft/submitted`,
	ADDITIONAL_CONTRACT: (id: string) => `${BaseUrl}/additional-contract/${id}`,
	COLLATERAL_PROVIDER_CONTRACT: (id: string) => `${BaseUrl}/collateral-contract/${id}`,
	ADDITIONAL_CONTRACT_SUBMITTED: `${BaseUrl}/additional-contract/submitted`,
	COLLATERAL_PROVIDER_CONTRACT_SUBMITTED: `${BaseUrl}/collateral-contract/submitted`,
	PARTNERS_VERIFY: `${BaseUrl}/partners/verify`,
	PARTNERS_SUMMARY: `${BaseUrl}/partners/summary`,
	SMS_SIGN_UP: `${BaseUrl}/sms-signup`,
};

export default LoansMortgageLoansUrls;
