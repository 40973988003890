import { catchError, delay, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { concat, EMPTY, mapTo, Observable, of, zip } from 'rxjs';
import { ofAction } from '@peoplefund/utils/redux.util';
import { CometEpic } from '@peoplefund/epics/constants.util';
import {
	applyFilter,
	applySortingListForClose,
	fetchInvestProfitInfo,
	fetchProduct,
	fetchProductList,
	fetchSummary,
	loadFilter,
	resetCheckedObj,
	resetFilter,
	resetInvestApplyIdList,
	resetInvestApplyResult,
	resetInvestCancelResult,
	resetInvestCancleIdList,
	resetPageIndex,
	resetSummaryBottomSheet,
	setError,
	setFilterApplied,
	setInvestApplyResult,
	setInvestCancelResult,
	setInvestProfitInfo,
	setPageDatas,
	setPageIndex,
	setProductList,
	setProductState,
	setSummaryBottomSheet,
	setSummaryTable,
	setTableInputBox,
	setToggleAllCheckBox,
	setToggleCheckBox,
	startInvestApply,
	startInvestCancel,
} from '@peoplefund/slices/pl-investing';
import actions from '@peoplefund/actions';
import {
	INVEST_PRODUCT_STATE,
	INVESTOR_STATUS,
	QUERY_KEY,
	TABLE_SORT_OPTIONS,
} from '@peoplefund/constants/pl-investing';
import {
	convertProductListIdsResponse,
	convertProductListResponse,
	convertProductResponse,
	convertProfitResponse,
	convertSummaryResponse,
	filterSaveKey,
	findComponentInPageData,
	LIMIT_PAGE_SIZE,
} from '@peoplefund/epics/pl-investing.util';
import { FilterValue } from '@peoplefund/components/investing/personal-loans/list/FilterBox/useFilterBoxData.util';
import { getUserId } from '@peoplefund/utils/jwt.util';
import { fetchInvestorStatus, getUnsecuredInvestmentStatus } from '@peoplefund/epics/account/index.util';
import { SetProductListParam } from '@peoplefund/slices/pl-investing.model';
import { AlertCommonError } from '@peoplefund/constants/error/type';
import { PhoenixErrorCode } from '@peoplefund/constants/error/code';
import { AMLStatus, initialInvestorInfo, InvestorInfo } from '@peoplefund/reducers/account/index.model';
import { AmlStatusParam } from '@peoplefund/actions/account';
import { PageData } from '@peoplefund/slices/common-investing';

const updateProductStateEpic: CometEpic = (action$) =>
	action$.pipe(
		ofAction(setProductState),
		mergeMap(() => concat(of(resetPageIndex()), of(fetchProductList())))
	);

const applyFilterEpic: CometEpic = (action$, state$) =>
	action$.pipe(
		ofAction(applyFilter),
		withLatestFrom(state$),
		mergeMap(
			([
				,
				{
					account: {
						auth: { token },
					},
					plInvesting: { filter },
				},
			]) => {
				const userId = getUserId(token ?? '');
				if (userId) {
					localStorage.setItem(filterSaveKey(userId), JSON.stringify(filter));
				}
				return concat(of(resetPageIndex()), of(fetchProductList()), of(setFilterApplied()));
			}
		)
	);

const paginationProductListEpic: CometEpic = (action$, state$) =>
	action$.pipe(
		ofAction(setPageIndex),
		withLatestFrom(state$),
		mergeMap(() => {
			return of(fetchProductList());
		})
	);

const orderingProductListEpic: CometEpic = (action$, state$) =>
	action$.pipe(
		ofAction(applySortingListForClose),
		withLatestFrom(state$),
		mergeMap(() => {
			return concat(of(resetPageIndex()), of(fetchProductList()));
		})
	);

const resetFilterEpic: CometEpic = (action$) => action$.pipe(ofAction(resetFilter), mapTo(applyFilter()));

const loadFilterEpic: CometEpic = (action$) => action$.pipe(ofAction(loadFilter), mapTo(setFilterApplied()));

// inputBox 값이 update 되면 하는 작업입니다
const updateTableInputBoxEpic: CometEpic = (action$) =>
	action$.pipe(
		ofAction(setTableInputBox),
		mergeMap(({ payload }) => {
			const { id, value } = payload;
			const actionResult = [];
			// 1. 입력값이 유효하면, checkedObj 에 넣기
			if (value > 0) {
				actionResult.push(setToggleCheckBox({ id, checked: true }));
			} else {
				actionResult.push(setToggleCheckBox({ id, checked: false }));
			}

			// 2. bottomSheet 요약 정보 update
			actionResult.push(setSummaryBottomSheet());

			return actionResult;
		})
	);

// chekcbox 값이 update 되면
const updateCheckBoxEpic: CometEpic = (action$) =>
	//ofAction(setToggleCheckBox),
	action$.pipe(ofAction(setToggleCheckBox), mapTo(setSummaryBottomSheet()));

const updateAllCheckBoxEpic: CometEpic = (action$) =>
	//ofAction(setToggleCheckBox),
	action$.pipe(ofAction(setToggleAllCheckBox), mapTo(setSummaryBottomSheet()));

const fetchProductListEpic: CometEpic = (action$, state$, { cometAjax }) => {
	const fetchInvestorInfo = (
		token?: string
	): Observable<InvestorInfo & { amlStatus: AMLStatus } & Pick<AmlStatusParam, 'kycExpireDate'>> => {
		if (token) {
			return fetchInvestorStatus(cometAjax, token).pipe(
				mergeMap(({ investorStatus, amlStatus, kycExpireDate }) => {
					if (investorStatus === INVESTOR_STATUS.AML_ALL_PASSED) {
						return getUnsecuredInvestmentStatus(cometAjax, token).pipe(
							map((result) => ({
								...result,
								amlStatus,
							}))
						);
					} else {
						return of({
							...initialInvestorInfo,
							status: investorStatus,
							tryFetchInvestorInfo: true,
							amlStatus,
							kycExpireDate,
						});
					}
				})
			);
		} else {
			return of({ ...initialInvestorInfo, tryFetchInvestorInfo: true, amlStatus: AMLStatus.INITIAL });
		}
	};

	const getProductList = (
		token: string,
		productInvestLimit: number,
		productState: INVEST_PRODUCT_STATE,
		api: string,
		queryString: string
	): Observable<SetProductListParam> =>
		cometAjax.inapi.get(`/showcase/v2/loan_applications/${api}/${queryString}`, { token }).pipe(
			map((response) => {
				const { count, next, previous, results } = response;
				return {
					data: convertProductListResponse(results, productInvestLimit),
					productState,
					count,
					next,
					previous,
					sortingIds: convertProductListIdsResponse(results),
				};
			})
		);

	return action$.pipe(
		ofAction(fetchProductList),
		withLatestFrom(state$),
		mergeMap(
			([
				,
				{
					account: {
						auth: { token = '' },
					},
					plInvesting: { productState, filter, pagination, sorting },
				},
			]) => {
				const fetchCancelList = productState === INVEST_PRODUCT_STATE.RUNNING && Boolean(token);

				let api = 'ready';
				switch (productState) {
					case INVEST_PRODUCT_STATE.RUNNING:
						api = 'funding';
						break;
					case INVEST_PRODUCT_STATE.CANCLE_AVAILABLE:
						api = 'cancelable';
						break;
					case INVEST_PRODUCT_STATE.CLOSE:
						api = 'completed';
						break;
				}

				let queryString = '?';

				// 1. 필터 option
				const filterQueryString = (Object.values(filter) as FilterValue[])
					.map(({ queryKey, start, end }) => `${queryKey}_min=${start}&${queryKey}_max=${end}`)
					.join('&');
				queryString += filterQueryString;

				if (productState === INVEST_PRODUCT_STATE.CLOSE) {
					// 2. 페이지네이션 option
					queryString += `&page=${pagination.page}`;
					queryString += `&page_size=${LIMIT_PAGE_SIZE}`;

					// 3. 정렬 option
					// TODO: 추후 전체적인 정렬 기능을 server 측으로 옮길수도
					let orderingQueryString = '';
					if (sorting.targetKey !== QUERY_KEY.ID && sorting.option !== TABLE_SORT_OPTIONS.origin) {
						if (sorting.option === TABLE_SORT_OPTIONS.decrease) {
							orderingQueryString += '-';
						}

						orderingQueryString += `${sorting.targetKey}`;
					}
					if (orderingQueryString) {
						queryString += `&ordering=${orderingQueryString}`;
					}
				}

				return concat(
					of(actions.layout.startLoading()),
					//초기화
					of(resetCheckedObj()),
					of(resetSummaryBottomSheet()),
					of(resetInvestApplyIdList()),
					of(resetInvestApplyResult()),
					of(resetInvestCancleIdList()),
					of(resetInvestCancelResult()),
					//데이터 fetching
					fetchInvestorInfo(token).pipe(
						mergeMap(({ amlStatus, kycExpireDate, ...investorInfo }) => {
							return zip(
								getProductList(token, investorInfo.productInvestLimit, productState, api, queryString),
								fetchCancelList
									? getProductList(
											token,
											investorInfo.productInvestLimit,
											INVEST_PRODUCT_STATE.CANCLE_AVAILABLE,
											'cancelable',
											// FYI. 취소 상품군은 filter option 만 지정해서 요청합니다
											`?${filterQueryString}`
									  )
									: of(null)
							).pipe(
								mergeMap((productResult) => {
									return [
										...productResult
											.filter((result) => result as SetProductListParam)
											.map((result) => setProductList(result as SetProductListParam)),
										actions.account.setInvestorInfo(investorInfo),
										actions.account.setAmlStatus({ status: amlStatus, kycExpireDate, fetched: true }),
									];
								})
							);
						}),
						catchError((error) =>
							of(setError(error), actions.account.setAmlStatus({ status: AMLStatus.INITIAL, fetched: true }))
						)
					),
					// 상단 summary 데이터 업데이트 (fetchList 와 같은 api, queryString)을 가지고
					of(fetchSummary({ api, queryString })),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const fetchSummaryEpic: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(fetchSummary),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { api, queryString },
				},
				{
					account: {
						auth: { token },
					},
				},
			]) =>
				concat(
					of(actions.layout.startLoading()),
					cometAjax.inapi
						.get(`/showcase/v2/loan_applications/${api}/summary/${queryString}`, {
							token,
						})
						.pipe(
							map((response) => setSummaryTable(convertSummaryResponse(response))),
							catchError((error) => of(setError(error)))
						),
					of(actions.layout.endLoading())
				)
		)
	);

const investmentCancelEpic: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(startInvestCancel),
		withLatestFrom(state$),
		delay(1000),
		mergeMap(
			([
				,
				{
					account: {
						auth: { token },
					},
					plInvesting: { investCancleIdList },
				},
			]) => {
				const cancelId = investCancleIdList[0];
				const nextState = investCancleIdList.length > 1 ? 'processing' : 'done';
				const nextActions = (success: boolean) =>
					concat(
						of(setInvestCancelResult({ investmentId: cancelId, success })),
						nextState === 'done' ? EMPTY : of(startInvestCancel({ init: false }))
					);
				return concat(
					cometAjax.inapi.post(`/secured/v1/investments/${cancelId}/cancel/`, { token }).pipe(
						mergeMap(() => nextActions(true)),
						catchError(() => nextActions(false))
					)
				);
			}
		)
	);

const investmentApplyEpic: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(startInvestApply),
		withLatestFrom(state$),
		delay(1000),
		mergeMap(
			([
				,
				{
					account: {
						auth: { token },
					},
					plInvesting: { investApplyIdList, productSet },
				},
			]) => {
				const runningProductList = Object.values(productSet[INVEST_PRODUCT_STATE.RUNNING]);
				const applyInvestItem = runningProductList.filter((item) => investApplyIdList.includes(item.id));
				const userId = getUserId(token ?? '');

				const applyObj = {
					amount: applyInvestItem[0].investedAmount,
					user_id: userId,
					loan_application_id: Number(applyInvestItem[0].id),
					invest_type: '일반',
					pointAmount: 0,
					investment_platform: '크플',
				};

				const nextState = investApplyIdList.length > 1 ? 'processing' : 'done';
				const nextActions = (success: boolean) =>
					concat(
						of(setInvestApplyResult({ id: applyInvestItem[0].id, success })),
						nextState === 'done' ? EMPTY : of(startInvestApply({ init: false }))
					);
				return concat(
					cometAjax.inapi.post(`/secured/v1/investments/`, { token, body: applyObj }).pipe(
						mergeMap(() => nextActions(true)),
						catchError(() => nextActions(false))
					)
				);
			}
		)
	);

const investProfitinfoEpic: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(fetchInvestProfitInfo),
		withLatestFrom(state$),

		mergeMap(
			([
				,
				{
					account: {
						auth: { token },
					},
					plInvesting: { investApplyIdList, productSet },
				},
			]) => {
				const runningProductList = Object.values(productSet[INVEST_PRODUCT_STATE.RUNNING]);

				const result = runningProductList
					.filter((item) => investApplyIdList.includes(item.id))
					.map((item) => {
						return {
							amount: item.investedAmount,
							loan_id: Number(item.id),
						};
					});

				return concat(
					of(actions.layout.startLoading()),
					cometAjax.inapi
						.post(`/showcase/v1/users/:user_id/expected_profit/`, {
							token,
							body: { investment_list: result },
						})
						.pipe(
							map((response) => setInvestProfitInfo(convertProfitResponse(response))),
							catchError((error) => of(setError(error)))
						),
					of(actions.layout.endLoading())
				);
			}
		)
	);

const fetchProductEpic: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(fetchProduct),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { loanApplicationId },
				},
			]) =>
				concat(
					of(actions.layout.startLoading()),
					cometAjax.inapi.get(`/unsecured/products/${loanApplicationId}/deal/`).pipe(
						mergeMap((response) => {
							return of(convertProductResponse(response)).pipe(
								mergeMap((productInfo) =>
									cometAjax.investment.post(`/products/${loanApplicationId}`).pipe(
										map((pageDataResponse) => {
											const pageDatas: PageData[] = pageDataResponse.contents ?? [];
											const progressItemComponentName = 'ProgressItem';

											findComponentInPageData(pageDatas, progressItemComponentName, {
												percentage: Number((productInfo.fundingRate * 100).toFixed(1)),
												accumulatedAmount: productInfo.fundedAmount,
												targetAmount: productInfo.totalFundingAmount,
											});
											return setPageDatas({ pageDatas: pageDataResponse.contents });
										})
									)
								)
							);
						}),
						catchError((error) => {
							if (error.code === 'INAPI0404') {
								return of(
									setError(new AlertCommonError('딜페이지가 존재하지 않습니다.', PhoenixErrorCode.DEALPAGE_NOT_FOUND))
								);
							}
							return of(setError(error));
						})
					),
					of(actions.layout.endLoading())
				)
		)
	);

export default [
	updateProductStateEpic,
	updateTableInputBoxEpic,
	updateCheckBoxEpic,
	updateAllCheckBoxEpic,
	fetchProductListEpic,
	fetchSummaryEpic,
	applyFilterEpic,
	resetFilterEpic,
	loadFilterEpic,
	investmentCancelEpic,
	investmentApplyEpic,
	investProfitinfoEpic,
	paginationProductListEpic,
	orderingProductListEpic,
	fetchProductEpic,
];
