import WebviewHeaderStrategy from '@peoplefund/utils/axios/HeaderStrategy/WebviewHeaderStrategy';
import ApiClient from '@peoplefund/utils/axios/ApiClient';
import { Store } from 'redux';
import { State } from '@peoplefund/reducers';
import PhoenixTokenStrategy from '@peoplefund/utils/axios/TokenStrategy/PhoenixTokenStrategy';
import WebviewHandler from '@peoplefund/utils/webview-handler/index.util';

export const PhoenixApiClient = (() => {
	const tokenStrategy = new PhoenixTokenStrategy(WebviewHandler(), 'anonymous');
	const headerStrategy = new WebviewHeaderStrategy();

	const apiClient = new ApiClient(`${process.env.NEXT_PUBLIC_COMET_URL}`, tokenStrategy, headerStrategy);

	return {
		setStore: (store?: Store<State>) => {
			tokenStrategy.setStore(store);
		},
		instance: apiClient.getApiClient(),
	};
})();

export default PhoenixApiClient.instance;
