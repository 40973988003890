import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
dayjs.extend(isBetween);

export const TODAY = dayjs().startOf('day').toDate();
export const CURRENT_DATETIME = () => dayjs().format('YYYY-MM-DD HH:mm:ss');
export const formatDate = (date: Date, format: string): string => dayjs(date).format(format);
export const parseDate = (dateString: string, format?: string): Date => dayjs(dateString, format, true).toDate();
export const getRemainingTime = (expire: string, now = new Date()): number => {
	const endTime = parseDate(expire);
	const leftTime = endTime.getTime() - now.getTime();
	// [ms] 단위로 반환합니다.
	return leftTime > 0 ? leftTime : 0;
};

export const betweenDate = (startDate: string, endDate: string): boolean => {
	return dayjs().isBetween(startDate, endDate);
};

/**
 * YYMMDD 형식의 생년월일을 YYYYDDMM 형식의 생년월일로 변경해줍니다.
 * @param rrn: 주민번호 앞 6자리 + 뒤의 한자리를 반드시 포함해야 함
 */
export const getFullYearBirthdate = (rrn: string): string => {
	const nineties = ['1', '2'];
	const twenties = ['3', '4'];

	if (rrn.length < 7 || ![...nineties, ...twenties].includes(rrn[6])) {
		return '';
	}

	const century = nineties.includes(rrn[6]) ? '19' : '20';
	const fullBirthDate = `${century}${rrn.substring(0, 6)}`;
	const date = parseDate(fullBirthDate, 'YYYYMMDD');

	if (Number.isNaN(date.getTime())) {
		return '';
	}

	return fullBirthDate;
};

/**
 * 8자리 포맷의 생년월일과 성별을 이용해서 rrn7까지 리턴하는 함수
 *
 * @param birthDate
 * @param gender
 */
export const getRrn7 = (birthDate: string, gender: 'MALE' | 'FEMALE') => {
	if (birthDate.length !== 8) {
		throw new Error('생년월일은 8자리여야 합니다. ex) 19900824');
	}
	if (gender !== 'MALE' && gender !== 'FEMALE') {
		throw new Error('성별은 MALE과 FEMALE 중 하나여야 합니다.');
	}

	const nineties = {
		MALE: 1,
		FEMALE: 2,
	};
	const twenties = {
		MALE: 3,
		FEMALE: 4,
	};

	const century = birthDate.slice(0, 2) === '19' ? nineties : twenties;
	const rrn6 = birthDate.slice(2);
	const genderNumber = century[gender];

	return rrn6 + genderNumber;
};

/**
 * 은행 점검 시간인지 확인하는 함수
 * @param now
 * @returns
 */
export const bankMaintenanceTime = (now = new Date()) => {
	const nowInMinutes = now.getHours() * 60 + now.getMinutes();

	const start = 23 * 60 + 30; //23:30~
	const end = 30; //0:30

	return nowInMinutes <= end || nowInMinutes >= start;
};
/*
 * @param fullBirthDate YYYYMMDD (8자리)만 사용해야합니다.
 * @param gender 'MALE' | 'FEMALE'
 */

export const getRRN7Only = (fullBirthDate: string, gender: string): string => {
	let result = '';

	const date = parseDate(fullBirthDate, 'YYYYMMDD');

	if (Number.isNaN(date.getTime())) {
		return '';
	}

	if (gender === 'MALE') {
		if (fullBirthDate[0] === '2') {
			result = '3';
		} else {
			result = '1';
		}
	} else if (gender === 'FEMALE') {
		if (fullBirthDate[0] === '2') {
			result = '4';
		} else {
			result = '2';
		}
	}
	return result;
};

export const getTimeStamp = () => {
	return dayjs(new Date()).valueOf();
};

export const daysBetween = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
	return dayjs(endDate).diff(dayjs(startDate), 'day');
};
