import { ofAction } from '@peoplefund/utils/redux.util';
import {
	getARSVerifyStatus,
	loadList,
	requestARSVerify,
	requestChangeBankInfo,
	setARSVerifyStatus,
	setARSVerifyVerificationId,
	setBankList,
	setError,
	setLoanList,
	updateBankAccountInfo,
} from '@peoplefund/slices/loans-autopay';
import { CometEpic } from './constants.util';
import { catchError, concat, delay, map, mergeMap, Observable, of, throwError, withLatestFrom, zip } from 'rxjs';
import actions from '@peoplefund/actions';
import { AlertCommonError, convertInAPICommonErrorToAlertCommonError } from '@peoplefund/constants/error/type';
import { ARSVerifyStatus, CMSAccountStatus, CMSLoan } from '../slices/loans-autopay/index.model';
import { Action } from 'redux';
import { PhoenixErrorCode } from '@peoplefund/constants/error/code';
import { fetchBankListObservable } from './bankCode.util';

type CMSLoanServerType = {
	verification_key: string;
	loan_id: number;
	loan_type: string;
	execution_date: string;
	loan_amount: number;
	interest_rate: number;
	repayment_day: string;
	cms_account_status: string;
	cms_account_number: string;
	cms_account_bank_code: string;
};

const loadListEpic: CometEpic = (action$, state$, { cometAjax }) => {
	const emptyLoanError = new AlertCommonError(``, PhoenixErrorCode.EMPTY_CMS_LOAN_LIST);

	const fetchLoanList = (token: string): Observable<CMSLoan[]> =>
		cometAjax.inapi.get('/moneyflow/loans/cms/account', { token }).pipe(
			map((response) => {
				const { cms_registrable_details } = response;
				const list: CMSLoan[] = [];

				if (cms_registrable_details) {
					cms_registrable_details.forEach((item: CMSLoanServerType) => {
						list.push({
							loanId: item.loan_id,
							loanType: item.loan_type,
							executionDate: item.execution_date,
							loanAmount: item.loan_amount,
							interestRate: item.interest_rate,
							repaymentDay: item.repayment_day,
							cmsAccountStatus: item.cms_account_status.toLocaleUpperCase() as CMSAccountStatus,
							cmsAccountNumber: item.cms_account_number,
							cmsAccountBankCode: item.cms_account_bank_code,
							verificationKey: item.verification_key,
						});
					});
				}

				if (list.length === 0) {
					throw emptyLoanError;
				}

				return list;
			})
		);

	return action$.pipe(
		ofAction(loadList),
		withLatestFrom(state$),
		mergeMap(
			([
				,
				{
					account: {
						auth: { token = '' },
					},
					loansAutopay: { bankList },
				},
			]) => {
				if (!token) {
					return of(setError(emptyLoanError));
				}

				return zip(
					bankList.length === 0 ? fetchBankListObservable(cometAjax, false) : of(null),
					fetchLoanList(token)
				).pipe(
					mergeMap(([bankList, loanList]) => {
						const resultAction: Action[] = [setLoanList({ list: loanList })];

						if (bankList && bankList.length > 0) {
							resultAction.push(setBankList({ list: bankList }));
						}

						return resultAction;
					}),
					catchError((error) => of(setError(convertInAPICommonErrorToAlertCommonError(error))))
				);
			}
		)
	);
};

type ARSResultServerType = {
	verification_id: string;
	verification_key: string;
	tx_number: string;
	auth_no: string;
	state: string;
};

const requestARSVerifyEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(requestARSVerify),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token = '' },
					},
				},
			]) => {
				return cometAjax.cert
					.post('/v1/ars', {
						body: {
							verification_key: payload.verificationKey,
							phone_number: payload.phoneNumber,
							auth_number: payload.code,
							bank_code: payload.bankCode,
							bank_name: payload.bankName,
							account_number: payload.accountNumber,
							user_name: payload.userName,
							birth: payload.birth,
						},
						token,
					})
					.pipe(
						mergeMap((response: ARSResultServerType) => {
							const { verification_id, tx_number, state } = response;
							return [
								setARSVerifyVerificationId(verification_id),
								setARSVerifyStatus(state as ARSVerifyStatus),
								getARSVerifyStatus({ verificationKey: payload.verificationKey, txNumber: tx_number }),
							];
						}),
						catchError((error) => of(setError(convertInAPICommonErrorToAlertCommonError(error))))
					);
			}
		)
	);
};

const getARSVerifyStatusEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(getARSVerifyStatus),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token = '' },
					},
					loansAutopay: { currLoan, verificationId },
				},
			]) => {
				return cometAjax.cert
					.get('/v1/ars', {
						body: {
							tx_number: payload.txNumber,
						},
						token,
					})
					.pipe(
						delay(3000),
						mergeMap((response: ARSResultServerType) => {
							const { tx_number, state } = response;

							switch (state) {
								case 'W':
									return [
										setARSVerifyStatus('WAITING'),
										getARSVerifyStatus({ verificationKey: payload.verificationKey, txNumber: tx_number }),
									];
								case 'S':
									return cometAjax.inapi
										.post(`/moneyflow/loans/cms/account`, {
											body: {
												verification_id: verificationId,
												verification_key: payload.verificationKey,
												loan_id: currLoan?.loanId,
											},
											token,
										})
										.pipe(map(() => setARSVerifyStatus('DONE')));
								default:
									throw new AlertCommonError('');
							}
						}),
						catchError(() =>
							of(
								setError(
									new AlertCommonError(
										``,
										PhoenixErrorCode.CMS_ARS_VERIFICATION_FAILED,
										`ARS인증이 완료되지 않았어요\n다시 시도해 주세요`
									)
								)
							)
						)
					);
			}
		)
	);
};

const requestChangeBankInfoEpic: CometEpic = (action$, state$, { cometAjax }) => {
	const commonError = new AlertCommonError(
		'본인 명의의 계좌를 입력해 주세요',
		PhoenixErrorCode.CMS_CHANGE_BANK_ACCOUNT_FAILED,
		'계좌 확인에 실패했어요'
	);

	const checkBankInfoChangeIsNeeded = (
		bankCode: string,
		bankAccountNumber: string,
		currLoan?: CMSLoan
	): Observable<null> => {
		if (!currLoan) {
			return throwError(commonError);
		}

		const { cmsAccountStatus, cmsAccountBankCode, cmsAccountNumber } = currLoan;

		if (cmsAccountStatus === 'REGISTRABLE') {
			if (bankCode === cmsAccountBankCode && bankAccountNumber === cmsAccountNumber) {
				return throwError(
					new AlertCommonError(
						'',
						PhoenixErrorCode.CMS_CHANGE_BANK_ACCOUNT_CHANGE_REQUIRED,
						`현재 등록된 계좌와\n다른 계좌를 입력해 주세요`
					)
				);
			}
		}

		return of(null);
	};

	return action$.pipe(
		ofAction(requestChangeBankInfo),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					loansAutopay: { currLoan },
					account: {
						auth: { token = '' },
						userInfo,
						corporateInfo,
					},
				},
			]) => {
				if (!userInfo) {
					return of(
						setError(new AlertCommonError('사용자 정보가 올바르지 않습니다', PhoenixErrorCode.USERINFO_NOT_FETCHED))
					);
				}

				return concat(
					of(actions.layout.startLoading()),
					checkBankInfoChangeIsNeeded(payload.bankCode, payload.bankAccountNumber, currLoan).pipe(
						mergeMap(() =>
							cometAjax.cert
								.post('/v1/account/owner/check', {
									body: {
										identification_number: corporateInfo?.bizRegNumber || userInfo.rrn6,
										name: userInfo.userName,
										bank_code: payload.bankCode,
										account_number: payload.bankAccountNumber,
										is_korean: userInfo.isKorean,
										is_corp: Boolean(corporateInfo),
									},
									token,
								})
								.pipe(
									map(() => updateBankAccountInfo(payload)),
									catchError(() => {
										throw commonError;
									})
								)
						),
						catchError((error) => of(setError(error)))
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

export default [loadListEpic, requestARSVerifyEpic, getARSVerifyStatusEpic, requestChangeBankInfoEpic];
