import actions from '@peoplefund/actions';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { concat, of } from 'rxjs';
import { ofAction } from '@peoplefund/utils/redux.util';
import { CometEpic } from '@peoplefund/epics/constants.util';

const apply: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(actions.lsFinancialProductApplication.apply.started),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token = '' },
					},
				},
			]) =>
				concat(
					of(actions.layout.startLoading()),
					cometAjax.loan
						.post(`/v1/personal-loan/loan-product-applications/${payload.id}/apply`, {
							body: {
								amount: payload.amount,
							},
							token,
						})
						.pipe(
							map((response) => {
								return actions.lsFinancialProductApplication.apply.done({
									params: payload,
									result: {
										id: payload.id,
										amount: payload.amount,
										processType: response.process_type,
										externalContractUrl: response.url,
									},
								});
							}),
							catchError((error) =>
								of(
									actions.lsFinancialProductApplication.apply.failed({
										params: payload,
										error,
									})
								)
							)
						),
					of(actions.layout.endLoading())
				)
		)
	);

export default [apply];
