import { ColorType } from '@pfct/purple';

export interface VerifyIdCardCommonProps {
	readonly colorType: ColorType;
	readonly onButtonClick: () => void;
	readonly isLoading?: boolean;
}

export enum VerifyType {
	ID_CARD = '주민등록증',
	DRIVERS_LICENSE = '운전면허증',
}

export const VerifyTypeToCode = {
	[VerifyType.ID_CARD]: '01',
	[VerifyType.DRIVERS_LICENSE]: '02',
};

export const VerifyTypeToCodeV2 = {
	[VerifyType.ID_CARD]: 'registration_card',
	[VerifyType.DRIVERS_LICENSE]: 'driver_license',
};

export const IdOptions = [VerifyType.ID_CARD, VerifyType.DRIVERS_LICENSE].map((item) => ({ value: item, label: item }));

export type VerifyTwoPlusOneIdCardSetting = Pick<VerifyIdCardCommonProps, 'colorType'> & {
	readonly moveToNext: () => void;
};
