import { reducerWithInitialState } from 'typescript-fsa-reducers';
import Immutable from 'immutability-helper';
import actions from '@peoplefund/actions';
import { SelectOptionProps } from '@pfct/purple';
import {
	EMPLOYMENT_TYPE_CODE,
	FlowType,
	INCOME_TYPE_CODE,
	Institution,
	IntroKey,
	SelectOption,
} from '@peoplefund/constants/ls-loan-types';
import { InAPICommonError } from '@peoplefund/constants/error/type';
import { FetchMetaResult, ILoanshotAgreements, IntroInfo, LoanStatistic } from '@peoplefund/actions/ls-common';
import { PartialRecord } from '@peoplefund/constants/pl-investing';

export interface LSCommonState {
	/** PfOnly로만 들어온 경우 */
	readonly flowType: FlowType;
	readonly mainPath?: string;
	readonly institutions: Institution[];

	readonly incomeType?: SelectOption[];
	readonly loanPurposeType?: SelectOption[];
	readonly mortgageLoanPurposeType?: SelectOption[];
	readonly employmentType?: SelectOption[];
	readonly medicalInsuranceType?: SelectOption[];
	readonly houseOwnershipType?: SelectOption[];
	readonly sectorsType?: SelectOption[];
	readonly institutionType?: SelectOption[];

	readonly incomeTypeOptions?: SelectOptionProps<INCOME_TYPE_CODE>[];
	readonly loanPurposeOptions?: SelectOptionProps[];
	readonly employmentTypeOptions?: SelectOptionProps<EMPLOYMENT_TYPE_CODE>[];
	readonly medicalInsuranceTypeOptions?: SelectOptionProps[];
	readonly houseOwnershipTypeOptions?: SelectOptionProps[];
	readonly sectorsTypeOptions?: SelectOptionProps[];
	readonly institutionTypeOptions?: SelectOptionProps[];

	readonly agreements: ILoanshotAgreements;
	readonly agreementSent: boolean;
	readonly error?: InAPICommonError;

	readonly statistic: LoanStatistic;

	readonly metas: FetchMetaResult[];

	/** 인트로 정보 */
	readonly intro: PartialRecord<IntroKey, IntroInfo>;
	/** 제휴사 코드 */
	readonly platformCode: string;
}

export const lsCommonInitialState: LSCommonState = {
	flowType: FlowType.LOANSHOT,
	institutions: [],
	agreements: {},
	agreementSent: false,
	error: undefined,
	statistic: {
		totalLoanAmount: 0,
		totalLoanCount: 0,
		interestSavings: 0,
	},
	metas: [],
	intro: {},
	platformCode: '',
};

export default {
	lsCommon: reducerWithInitialState<LSCommonState>(lsCommonInitialState)
		.cases([actions.common.init, actions.lsCommon.init], (state) => Immutable(state, { $set: lsCommonInitialState }))
		.case(actions.lsCommon.setFlowType, (state, param) =>
			Immutable(state, {
				flowType: { $set: param },
			})
		)
		.case(actions.lsCommon.setMainPath, (state, param) =>
			Immutable(state, {
				mainPath: { $set: param },
			})
		)
		.case(actions.lsCommon.fetchEnums.done, (state, { result }) =>
			Immutable(state, {
				incomeType: { $set: result.incomeType },
				loanPurposeType: { $set: result.loanPurposeType },
				mortgageLoanPurposeType: { $set: result.mortgageLoanPurposeType },
				employmentType: { $set: result.employmentType },
				medicalInsuranceType: { $set: result.medicalInsuranceType },
				houseOwnershipType: { $set: result.houseOwnershipType },
				sectorsType: { $set: result.sectorsType },
				institutionType: { $set: result.institutionType },
				incomeTypeOptions: { $set: result.incomeTypeOptions },
				loanPurposeOptions: { $set: result.loanPurposeOptions },
				employmentTypeOptions: { $set: result.employmentTypeOptions },
				medicalInsuranceTypeOptions: { $set: result.medicalInsuranceTypeOptions },
				houseOwnershipTypeOptions: { $set: result.houseOwnershipTypeOptions },
				sectorsTypeOptions: { $set: result.sectorsTypeOptions },
				institutionTypeOptions: { $set: result.institutionTypeOptions },
			})
		)
		.case(actions.lsCommon.resetError, (state) =>
			Immutable(state, {
				error: { $set: undefined },
			})
		)
		.cases([actions.lsCommon.fetchAgreements.done], (state, { result }) =>
			Immutable(state, {
				agreements: {
					[result.name]: {
						$set: {
							agreed: false,
							disagreements: result.disagreements,
							agreements: result.agreements,
							tip: result.tip,
						},
					},
				},
				agreementSent: { $set: false },
			})
		)
		.cases([actions.lsCommon.fetchAgreements.failed, actions.lsCommon.fetchAgreements.failed], (state, { error }) =>
			Immutable(state, {
				error: { $set: error },
			})
		)
		.case(actions.lsCommon.sendAgreements.done, (state, { params }) =>
			Immutable(state, {
				agreements: {
					[params.sceneName]: {
						agreed: { $set: true },
					},
				},
				agreementSent: { $set: true },
			})
		)
		.case(actions.lsCommon.sendAgreements.failed, (state, { error }) =>
			Immutable(state, {
				error: { $set: error },
			})
		)
		.case(actions.lsCommon.intro.started, (state) =>
			Immutable(state, {
				error: { $set: undefined },
			})
		)
		.case(actions.lsCommon.intro.done, (state, { result }) =>
			Immutable(state, {
				statistic: {
					totalLoanAmount: { $set: result.totalLoanAmount },
					totalLoanCount: { $set: result.totalLoanCount },
					interestSavings: { $set: result.interestSavings },
				},
			})
		)
		.case(actions.lsCommon.intro.failed, (state, { error }) =>
			Immutable(state, {
				error: { $set: error },
			})
		)
		.case(actions.lsCommon.fetchMetas.started, (state) =>
			Immutable(state, {
				error: { $set: undefined },
			})
		)
		.case(actions.lsCommon.fetchMetas.done, (state, { result }) =>
			Immutable(state, {
				metas: { $set: result },
			})
		)
		.cases([actions.lsCommon.fetchMetas.failed, actions.lsCommon.loadLoansCompareIntro.failed], (state, { error }) =>
			Immutable(state, {
				error: { $set: error },
			})
		)
		.case(actions.lsCommon.loadLoansCompareIntro.done, (state, { result }) =>
			Immutable(state, {
				intro: { ['loansCompare']: { $set: result } },
			})
		)
		.case(actions.lsCommon.resetChannelPlatformCode, (state) =>
			Immutable(state, {
				platformCode: { $set: '' },
			})
		)
		.case(actions.lsCommon.setChannelPlatformCode, (state, payload) =>
			Immutable(state, {
				platformCode: { $set: payload },
			})
		),
};
