import immutable from 'immutability-helper';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import actions from '@peoplefund/actions';

interface LoadingState {
	stack: number;
	visible: boolean;
}

export interface LayoutState {
	loading: LoadingState;
	tokenExpired: boolean;
	gnb: {
		showLoansMypageMenu: boolean;
	};
}

export const layoutInitialState: LayoutState = {
	loading: {
		stack: 0,
		visible: false,
	},
	tokenExpired: false,
	gnb: {
		showLoansMypageMenu: false,
	},
};

export default {
	layout: reducerWithInitialState<LayoutState>(layoutInitialState)
		.cases([actions.common.init, actions.layout.init], (state) =>
			immutable(state, {
				$set: layoutInitialState,
			})
		)
		.case(actions.layout.startLoading, (state) => {
			const loadingStack = state.loading.stack + 1;
			return immutable(state, {
				loading: {
					stack: { $set: loadingStack },
					visible: { $set: loadingStack > 0 },
				},
			});
		})
		.case(actions.layout.endLoading, (state) => {
			let loadingStack = state.loading.stack - 1;
			if (loadingStack < 0) {
				loadingStack = 0;
			}
			return immutable(state, {
				loading: {
					stack: { $set: loadingStack },
					visible: { $set: loadingStack > 0 },
				},
			});
		})
		.case(actions.layout.tokenExpired, (state) =>
			immutable(state, {
				tokenExpired: { $set: true },
			})
		)
		.case(actions.layout.showLoansMyPageMenu, (state, payload) =>
			immutable(state, {
				gnb: {
					showLoansMypageMenu: { $set: payload },
				},
			})
		),
};
