import actions from '@peoplefund/actions';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import Immutable from 'immutability-helper';
import { InAPICommonError } from '@peoplefund/constants/error/type';
import { RegistorInvestorParam } from '@peoplefund/actions/unsecured-investor-registration';

export interface UnsecuredInvestorRegistrationState {
	readonly sendParam?: RegistorInvestorParam;
	readonly sent?: boolean;
	readonly error?: InAPICommonError;
	readonly sentFaild?: boolean;
}

export const unsecuredInvestorRegistrationInitalState: UnsecuredInvestorRegistrationState = {
	sendParam: undefined,
	sent: false,
	sentFaild: false,
	error: undefined,
};

export default {
	unsecuredInvestorRegistration: reducerWithInitialState<UnsecuredInvestorRegistrationState>(
		unsecuredInvestorRegistrationInitalState
	)
		.cases([actions.common.init, actions.unsecuredInvestorRegistration.init], (state) =>
			Immutable(state, {
				$set: unsecuredInvestorRegistrationInitalState,
			})
		)
		.case(actions.unsecuredInvestorRegistration.resetError, (state) =>
			Immutable(state, {
				sentFaild: { $set: false },
				error: { $set: undefined },
				sent: { $set: false },
			})
		)
		.case(actions.unsecuredInvestorRegistration.send.started, (state, params) => {
			return Immutable(state, {
				sendParam: { $set: params },
				sent: { $set: false },
				sentFaild: { $set: false },
				error: { $set: undefined },
			});
		})
		.case(actions.unsecuredInvestorRegistration.send.done, (state) => {
			return Immutable(state, {
				sent: { $set: true },
			});
		})
		.case(actions.unsecuredInvestorRegistration.send.failed, (state, { error }) => {
			return Immutable(state, {
				sendParam: { $set: undefined },
				sent: { $set: false },
				sentFaild: { $set: true },
				error: { $set: error },
			});
		}),
};
