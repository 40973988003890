import dynamic from 'next/dynamic';
import 'regenerator-runtime/runtime';
import 'intersection-observer';
import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { AppContext, AppInitialProps, AppProps } from 'next/app';
import { NextComponentType } from 'next';
import { GlobalStyle, THROTTLE_KEYWORD } from '@peoplefund/styles/global';
import { CacheProvider, Global } from '@emotion/react';
import createCache from '@emotion/cache';
import Head from 'next/head';
import actions from '@peoplefund/actions';
import { PHOENIX_SESSION_ID } from '@peoplefund/constants/session-id';
import { useRouter } from 'next/router';
import { removeSessionCookie } from '@peoplefund/utils/cookie.util';
import TagManager from 'react-gtm-module';
import UtmFactory from '@peoplefund/utils/marketing-script/UtmModule';
import { registerHookForOverlayPreventScroll } from '@pfct/purple';
import { getJWTByCookie } from '@peoplefund/utils/jwt.util';
import TokenExpiredErrorBoundary from '@peoplefund/components/common/TokenExpiredErrorBoundary';
import { PhoenixApiClient } from '@peoplefund/utils/axios';
import WebviewHandler from '@peoplefund/utils/webview-handler/index.util';
import Script from 'next/script';
import { checkSessionStorage, wrapper } from '@peoplefund/store';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import PageUrls from '@peoplefund/constants/page-urls';
const AppWrapper = dynamic(() => import('@peoplefund/components/_app'));
const KAKAO_VERSION = '2.7.2';
const INTEGRITY_VALUE = 'sha384-TiCUE00h649CAMonG018J2ujOgDKW/kVWlChEuu4jK2vxfAAD0eZxzCKakxg55G4';
const JAVASCRIPT_KEY = '6248922864195f17f270625134137262';
const gtmIds = ['GTM-K86PBTV' // RT 스크립트 설치용
];
export const WEBPAGE_TITLE = '크플 - 요즘 돈 버는 법';
export const WEBPAGE_IMAGE = 'https://static.cple.co.kr/assets/share_banner_cple.png';
const PeopleFund: NextComponentType<AppContext, AppInitialProps, AppProps<any>> = ({
  Component,
  ...rest
}) => {
  const {
    store,
    props
  } = wrapper.useWrappedStore(rest);
  const {
    pageProps
  } = props;
  const ogTagInfo = {
    title: pageProps?.ogTagInfo?.title || WEBPAGE_TITLE,
    description: pageProps?.ogTagInfo?.description || WEBPAGE_TITLE,
    image: pageProps?.ogTagInfo?.image || WEBPAGE_IMAGE
  };
  const router = useRouter();
  const cache = createCache({
    key: 'phoenix'
  });
  const isWebview = WebviewHandler().isWebview;
  const [hasCheckedSessionStorage, setHasCheckedSessionStorage] = useState(false);
  useEffect(() => {
    const html = document.getElementsByTagName('html')?.[0];
    if (html) {
      const funcName = isWebview ? 'add' : 'remove';
      html.classList[funcName]('isWebview');
    }
  }, [isWebview]);
  useEffect(() => {
    const initialize = async () => {
      await checkSessionStorage();
      setHasCheckedSessionStorage(true);
    };
    initialize();
  }, []);
  useEffect(() => {
    if ((process as any).browser) {
      // 여러개 설치하기
      for (let idx = 0; idx < gtmIds.length; idx++) {
        TagManager.initialize({
          gtmId: gtmIds[idx]
        });
      }
    }
  }, []);
  useEffect(() => {
    // 여기서 세팅했으니깐 다른 곳은 꺼내 쓰기만 하면 됨
    const utmModule = UtmFactory.instance.setRouter(router);
    utmModule?.setQueryString(router.isReady);
  }, [router, router.isReady]);
  useEffect(() => {
    if (!hasCheckedSessionStorage) {
      return;
    }
    PhoenixApiClient.setStore(store);
    const signInResult = getJWTByCookie();
    if (signInResult) {
      store.dispatch(actions.account.signIn(signInResult));
    } else {
      removeSessionCookie(PHOENIX_SESSION_ID);
    }
  }, [hasCheckedSessionStorage, store]);
  useEffect(() => {
    registerHookForOverlayPreventScroll({
      throttleKeyword: THROTTLE_KEYWORD,
      globalScrollTarget: document.body
    });
  }, []);
  const queryClient = new QueryClient();

  /**
   * TODO: SSR 시 redux와 디펜던시 끊어야 함.
   */
  const isAppviewHome = rest.router.pathname.includes(PageUrls.card.HOME);
  if (!isAppviewHome && !hasCheckedSessionStorage) {
    return <></>;
  }
  return <>
			<Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
				<meta content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" name="viewport" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
				<title>{ogTagInfo.title}</title>
				<meta property="og:type" content="website" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
				<meta property="og:locale" content="ko_KR" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
				<meta property="og:site_name" content="크플" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
				<meta property="og:title" content={ogTagInfo.title} data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
				{ogTagInfo.description && <>
						<meta name="description" content={ogTagInfo.description} />
						<meta property="og:description" content={ogTagInfo.description} />
					</>}
				{ogTagInfo.image && <>
						<meta property="og:image" content={ogTagInfo.image} />
						<meta property="og:image:width" content="1200" />
						<meta property="og:image:height" content="630" />
					</>}
				<meta name="naver-site-verification" content="48d71d731ae17fb6fa9aca78a984c5084fff3c19" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
			</Head>
			<Provider store={store} data-sentry-element="Provider" data-sentry-source-file="_app.tsx">
				<CacheProvider value={cache} data-sentry-element="CacheProvider" data-sentry-source-file="_app.tsx">
					<QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="_app.tsx">
						<Global styles={GlobalStyle} data-sentry-element="Global" data-sentry-source-file="_app.tsx" />
						<TokenExpiredErrorBoundary data-sentry-element="TokenExpiredErrorBoundary" data-sentry-source-file="_app.tsx">
							<AppWrapper data-sentry-element="AppWrapper" data-sentry-source-file="_app.tsx">
								<Script src={`https://t1.kakaocdn.net/kakao_js_sdk/${KAKAO_VERSION}/kakao.min.js`} integrity={INTEGRITY_VALUE} crossOrigin="anonymous" onLoad={() => {
                if (Kakao && !Kakao.isInitialized()) {
                  Kakao.init(JAVASCRIPT_KEY);
                }
              }} data-sentry-element="Script" data-sentry-source-file="_app.tsx" />
								<Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
							</AppWrapper>
						</TokenExpiredErrorBoundary>
						<ReactQueryDevtools initialIsOpen={false} data-sentry-element="ReactQueryDevtools" data-sentry-source-file="_app.tsx" />
					</QueryClientProvider>
				</CacheProvider>
			</Provider>
		</>;
};
export default PeopleFund;