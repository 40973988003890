import actions from '@peoplefund/actions';
import { InAPICommonError } from '@peoplefund/constants/error/type';
import { concat, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ofAction } from '@peoplefund/utils/redux.util';
import { CometEpic } from '@peoplefund/epics/constants.util';
import { encryptObservable, getUserInfo } from './account/index.util';
import { PhoenixErrorCode } from '@peoplefund/constants/error/code';
import * as smsVerifySlice from '@peoplefund/slices/sms-verify';
import { getSignInResult } from '@peoplefund/utils/jwt.util';
import { SignInResponse } from '@peoplefund/epics/account/index.model';

export const verificationSendByRrn7: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(actions.smsVerification.smsSendRrn7.started),
		withLatestFrom(state$),
		mergeMap(([{ payload }, { account }]) =>
			concat(
				of(actions.layout.startLoading()),
				encryptObservable(cometAjax, {
					name: payload.name,
					rrn7: payload.rrn7Digits,
					phone_number: payload.mobilePhoneNumber,
					telecom: payload.mobileServiceProviderCode,
					genderCodeFromRrn7Digits: payload.rrn7Digits[6],
					channel_code: 'CREDIT',
				}).pipe(
					mergeMap(({ eData, headers }) => {
						if (account.userInfo?.phoneNumber === payload.mobilePhoneNumber) {
							throw new InAPICommonError('', PhoenixErrorCode.CHANGE_USING_PHONE_NUMBER);
						}

						return cometAjax.pfUser
							.post(`/auth/v1/cert/sms-otp/send`, {
								headers,
								body: { eData },
							})
							.pipe(
								mergeMap((response) =>
									of(
										actions.smsVerification.smsSendRrn7.done({
											params: payload,
											result: { svcTxSeqno: response.transaction_number },
										})
									)
								)
							);
					}),
					catchError((error: InAPICommonError) => of(smsVerifySlice.setError(error)))
				),
				of(actions.layout.endLoading())
			)
		)
	);

export const requestCodeWithRRNEpic: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(smsVerifySlice.requestCodeWithRRN),
		withLatestFrom(state$),
		mergeMap(([, { smsVerify, account }]) =>
			concat(
				of(actions.layout.startLoading()),
				encryptObservable(
					cometAjax,
					{
						name: smsVerify.name,
						rrn: smsVerify.rrn,
						telecom: smsVerify.provider,
						phone_number: smsVerify.mobile,
						country: 'KR',
						channel_code: 'CREDIT',
					},
					account.auth.token
				).pipe(
					mergeMap(({ eData, headers }) =>
						cometAjax.pfUser
							.post('/auth/v1/cert/sms-otp/send-rrn', {
								token: account.auth.token,
								headers,
								body: { eData },
							})
							.pipe(
								mergeMap((response) => of(smsVerifySlice.setRequestCodeResult(response.transaction_number))),
								catchError((error: InAPICommonError) => of(smsVerifySlice.setError(error)))
							)
					)
				),
				of(actions.layout.endLoading())
			)
		)
	);
export const requestCodeEpic: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(smsVerifySlice.requestCode),
		withLatestFrom(state$),
		mergeMap(([, { smsVerify, account }]) => {
			return concat(
				of(actions.layout.startLoading()),
				encryptObservable(
					cometAjax,
					{
						name: smsVerify.name,
						rrn7: smsVerify.rrn,
						telecom: smsVerify.provider,
						phone_number: smsVerify.mobile,
						genderCodeFromRrn7Digits: smsVerify.rrn[6],
						channel_code: 'CREDIT',
					},
					account.auth.token
				).pipe(
					mergeMap(({ eData, headers }) =>
						cometAjax.pfUser
							.post(`/auth/v1/cert/sms-otp/send`, {
								headers,
								body: { eData },
								token: account.auth.token,
							})
							.pipe(mergeMap((response) => of(smsVerifySlice.setRequestCodeResult(response.transaction_number))))
					),
					catchError((error: InAPICommonError) => of(smsVerifySlice.setError(error)))
				),
				of(actions.layout.endLoading())
			);
		})
	);

export const requestVerifyEpic: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(smsVerifySlice.requestVerify),
		withLatestFrom(state$),
		mergeMap(([, { smsVerify, account }]) =>
			concat(
				of(actions.layout.startLoading()),
				encryptObservable(
					cometAjax,
					{
						phone_number: smsVerify.mobile,
						transaction_number: smsVerify.svcTxSeqno,
						verification_code: smsVerify.code,
					},
					account.auth.token
				).pipe(
					mergeMap(({ eData, headers }) =>
						cometAjax.pfUser
							.post('/auth/v1/cert/sms-otp/verify', {
								token: account.auth.token,
								headers,
								body: { eData },
							})
							.pipe(mergeMap(() => of(smsVerifySlice.setVerifyStatusComplete())))
					),
					catchError((error: InAPICommonError) => of(smsVerifySlice.setError(error)))
				),
				of(actions.layout.endLoading())
			)
		)
	);

export const requestVerifyAndSignUpEpic: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(smsVerifySlice.requestVerifyAndSignUp),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { updateRRN },
				},
				{
					smsVerify,
					account: {
						auth: { token },
					},
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					encryptObservable(
						cometAjax,
						{
							phone_number: smsVerify.mobile,
							transaction_number: smsVerify.svcTxSeqno,
							verification_code: smsVerify.code,
							platform: 'PEOPLEFUND',
							sub_platform: 'WEB',
							channel_code: 'CREDIT',
						},
						token
					).pipe(
						mergeMap(({ eData, headers }) =>
							cometAjax.pfUser
								.post('/auth/v1/ci/signup', {
									headers,
									body: { eData },
									token,
								})
								.pipe(
									mergeMap((response) => {
										const signInResult = getSignInResult(response);
										return [
											actions.account.signIn(signInResult),
											updateRRN
												? smsVerifySlice.requestUpdateRRN({ updateRRN, token: signInResult.accessToken })
												: smsVerifySlice.setVerifyStatusComplete(),
										];
									})
								)
						),
						catchError((error: InAPICommonError) => of(smsVerifySlice.setError(error)))
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);

const requestVerifyAndSignInEpic: CometEpic<SignInResponse> = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(smsVerifySlice.requestVerifyAndSignIn),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { updateRRN },
				},
				{
					smsVerify,
					account: {
						auth: { token },
					},
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					encryptObservable(
						cometAjax,
						{
							phone_number: smsVerify.mobile,
							transaction_number: smsVerify.svcTxSeqno,
							verification_code: smsVerify.code,
							platform: 'WEB',
						},
						token
					).pipe(
						mergeMap(({ eData, headers }) =>
							cometAjax.pfUser
								.post('/auth/v1/ci/signin', {
									headers,
									body: { eData },
									token,
								})
								.pipe(
									mergeMap((response) => {
										const signInResult = getSignInResult(response);
										return [
											actions.account.signIn(signInResult),
											updateRRN
												? smsVerifySlice.requestUpdateRRN({ updateRRN, token: signInResult.accessToken })
												: smsVerifySlice.setVerifyStatusComplete(),
										];
									})
								)
						),
						catchError((error: InAPICommonError) => of(smsVerifySlice.setError(error)))
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);

const requestUpdateRRNEpic: CometEpic<SignInResponse> = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(smsVerifySlice.requestUpdateRRN),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { updateRRN, token },
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					getUserInfo(cometAjax, token).pipe(
						mergeMap((userInfo) => {
							if (userInfo.hasRRN) {
								return of(smsVerifySlice.setVerifyStatusComplete());
							} else {
								return encryptObservable(
									cometAjax,
									{
										rrn_last: updateRRN.slice(6),
									},
									token
								).pipe(
									mergeMap(({ eData, headers }) =>
										cometAjax.pfUser
											.patch('/user/v1/member/rrn', {
												headers,
												body: { eData },
												token,
											})
											.pipe(mergeMap(() => of(smsVerifySlice.setVerifyStatusComplete())))
									)
								);
							}
						}),
						catchError((error: InAPICommonError) => of(smsVerifySlice.setError(error)))
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);

export default [
	verificationSendByRrn7,
	requestCodeWithRRNEpic,
	requestCodeEpic,
	requestVerifyEpic,
	requestVerifyAndSignUpEpic,
	requestVerifyAndSignInEpic,
	requestUpdateRRNEpic,
];
