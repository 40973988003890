const BaseUrl = `/loans/luxury-asset-loans`;

export default {
	INTRO: `${BaseUrl}`,
	STATUS: `${BaseUrl}/status`,
	APPLY: `${BaseUrl}/apply`,
	APPLY_RESULT: `${BaseUrl}/apply-result`,
	REJECT: `${BaseUrl}/reject`,
	CONDITION: `${BaseUrl}/condition`,
	SHIPPING: `${BaseUrl}/shipping`,
	SHIPPING_BY_DELIVERY: `${BaseUrl}/shipping/by-delivery`,
	SHIPPING_DIRECT: `${BaseUrl}/shipping/by-visit`,
	CONTRACT: `${BaseUrl}/contract`,
	RETURN: `${BaseUrl}/return`,
	RETURN_BY_DELIVERY: `${BaseUrl}/return/by-delivery`,
};
