const BaseUrl = '/authentication';

const AuthenticationUrls = {
	LOGIN: `${BaseUrl}/login`,
	SETTING: `${BaseUrl}/setting`,
	SMS_LOGIN: `${BaseUrl}/sms-login`,
	SMS_LOGIN_FOR_INTEGRATION: `${BaseUrl}/sms-login-for-integration`,
	FIND_EMAIL: `${BaseUrl}/find-email`,
	FIND_PASSWORD: `${BaseUrl}/find-password`,
	SET_PASSWORD: `${BaseUrl}/set-password`,
	SET_EMAIL: `${BaseUrl}/set-email`,
	CHANGE_PASSWORD: `${BaseUrl}/change-password`,
	CHANGE_PHONE_NUMBER: `${BaseUrl}/change-phone-number`,
	CHANGE_BANK_ACCOUNT: `${BaseUrl}/change-bank-account`,
	SIGNUP_FOR_INVESTING: `${BaseUrl}/signup-for-investing`,
	SIGNUP_FOR_INVESTING_INFO: `${BaseUrl}/signup-for-investing/info`,
	SIGNUP_FOR_INVESTING_COMPLETE: `${BaseUrl}/signup-for-investing/complete`,
	INTEGRATE_ACCOUNT: `${BaseUrl}/integrate-account`,
	WITHDRAW_COMPLETE: `${BaseUrl}/withdraw-account-complete`,
	SIGNUP_FOR_INVESTING_FOR_CORPORATE: `${BaseUrl}/signup-for-investing/corporate`,
	SIGNUP_FOR_INVESTING_AGENT_INFO: `${BaseUrl}/signup-for-investing/corporate/agent-info`,
	SIGNUP_FOR_INVESTING_ACCOUNT_INFO: `${BaseUrl}/signup-for-investing/corporate/account-info`,
};

export default AuthenticationUrls;
