import actions from '@peoplefund/actions';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import immutable from 'immutability-helper';
import { InAPICommonError } from '@peoplefund/constants/error/type';
import { SendParam } from '@peoplefund/actions/sms-verification';

export interface LsAuthenticationState {
	verify: {
		/** 입력 정보 **/
		sendParam?: SendParam;
		/** 거래고유번호 */
		readonly svcTxSeqno: string;
		/** SMS 발송 여부 */
		readonly sentSMS: boolean;
		/** 본인인증 확인 여부 */
		readonly verified?: boolean;
	};
	error?: InAPICommonError;
}

export const lsAuthenticationInitialState: LsAuthenticationState = {
	error: undefined,
	verify: {
		svcTxSeqno: '',
		sentSMS: false,
	},
};

export default {
	lsAuthentication: reducerWithInitialState<LsAuthenticationState>(lsAuthenticationInitialState)
		.cases([actions.common.init, actions.lsAuthentication.init], (state) =>
			immutable(state, {
				$set: lsAuthenticationInitialState,
			})
		)
		.case(actions.lsAuthentication.initVerify, (state) =>
			immutable(state, {
				verify: {
					$set: {
						sendParam: undefined,
						svcTxSeqno: '',
						sentSMS: false,
						verified: undefined,
					},
				},
				error: { $set: undefined },
			})
		)
		.case(actions.lsAuthentication.errorReset, (state) =>
			immutable(state, {
				error: { $set: undefined },
			})
		)
		.case(actions.lsAuthentication.verifySendSMS.started, (state, params) =>
			immutable(state, {
				verify: {
					$set: {
						sendParam: params,
						svcTxSeqno: '',
						sentSMS: false,
						verified: undefined,
					},
				},
				error: { $set: undefined },
			})
		)
		.case(actions.lsAuthentication.verifySendSMS.done, (state, { result }) =>
			immutable(state, {
				verify: { svcTxSeqno: { $set: result.svcTxSeqno }, sentSMS: { $set: true } },
			})
		)
		.case(actions.lsAuthentication.verifySendSMS.failed, (state, { error }) =>
			immutable(state, {
				verify: { verified: { $set: false } },
				error: { $set: error },
			})
		)
		.case(actions.lsAuthentication.verifySignUp.started, (state) =>
			immutable(state, {
				verify: { verified: { $set: undefined } },
				error: { $set: undefined },
			})
		)
		.cases([actions.lsAuthentication.verifySignUp.done, actions.lsAuthentication.verifyPartnerDone], (state) =>
			immutable(state, {
				verify: { verified: { $set: true }, svcTxSeqno: { $set: '' } },
			})
		)
		.case(actions.lsAuthentication.verifySignUp.failed, (state, { error }) =>
			immutable(state, {
				verify: { verified: { $set: false } },
				error: { $set: error },
			})
		),
};
