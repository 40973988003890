// TODO: 현재는 investing table 에 맞춘 포맷이지만 나중에는 generalize

import { TABLE_SORT_OPTIONS, QUERY_KEY } from '@peoplefund/constants/pl-investing';
import { Product } from '@peoplefund/slices/pl-investing.model';

export const sortCallback = (itemA: Product, itemB: Product, targetKey: QUERY_KEY, sortOption: TABLE_SORT_OPTIONS) => {
	const compareFn = (a: string | number, b: string | number): number => {
		const flag = a > b;
		// FYI.
		// string type as ASCII compare ex. '11' < '2' (X)
		// number type simple compare ex. 11 > 2 (O)

		if (sortOption === TABLE_SORT_OPTIONS.increase) {
			return flag ? 1 : -1;
		}
		// 오름차순 기준이여서 converting
		return flag ? -1 : 1;
	};

	const comparePfGrade = (a: string, b: string): number => {
		// 오름차순
		let flag: boolean;

		const [alphabetA, numberA] = [a[0], Number(a[1])];
		const [alphabetB, numberB] = [b[0], Number(b[1])];

		if (alphabetA === alphabetB) {
			// 오름차순: 3 < 2 < 1
			flag = numberA < numberB;
		} else {
			// 오름차순: C < B < A
			flag = alphabetA < alphabetB;
		}

		// 오름차순 기준이여서 converting
		if (sortOption === TABLE_SORT_OPTIONS.decrease) {
			return flag ? -1 : 1;
		}

		return flag ? 1 : -1;
	};

	if (sortOption === TABLE_SORT_OPTIONS.origin) {
		// TODO: id 는 형변환 할 필요 없이 숫자였으면
		return compareFn(Number(itemA.id), Number(itemB.id));
	}

	switch (targetKey) {
		case QUERY_KEY.INTEREST_RATE:
			return compareFn(itemA.interestRate, itemB.interestRate);
		case QUERY_KEY.INVEST_TERM:
			return compareFn(itemA.investTerm, itemB.investTerm);
		case QUERY_KEY.PF_GRADE:
			return comparePfGrade(itemA.pfGrade, itemB.pfGrade);
		case QUERY_KEY.CREDIT_SCORE:
			return compareFn(itemA.creditScore, itemB.creditScore);
		case QUERY_KEY.TARGET_AMOUNT:
			return compareFn(itemA.targetAmount, itemB.targetAmount);
		case QUERY_KEY.FUNDING_RATE:
			return compareFn(itemA.accumulatedAmount / itemA.targetAmount, itemB.accumulatedAmount / itemB.targetAmount);
		case QUERY_KEY.INVESTED_AMOUNT:
			return compareFn(itemA.investedAmount, itemB.investedAmount);
		case QUERY_KEY.ID:
		default:
			return compareFn(Number(itemA.id), Number(itemB.id));
	}
};

// EVENT: 필드기준 정렬
// 기본은 투자상품 id (최신순 = 내림차순)
// sort 는 필드 한개씩만 3번 클릭시 돌아옴.
// const [sortData, setSortData] = useState({
// 	targetKey: QUERY_KEY.ID,
// 	sortOption: TABLE_SORT_OPTIONS.origin,
// 	runList,
// 	investedList,
// });

export const applySortToList = (
	list: Record<string, Product>,
	targetKey: QUERY_KEY,
	sortOption: TABLE_SORT_OPTIONS
): string[] => {
	const ids = Object.keys(list);

	return ids.sort((idA, idB) => {
		return sortCallback(list[idA], list[idB], targetKey, sortOption);
	});
};
