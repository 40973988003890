import actions from '@peoplefund/actions';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { concat, EMPTY, of } from 'rxjs';
import { ofAction } from '@peoplefund/utils/redux.util';
import { CometEpic } from '@peoplefund/epics/constants.util';
import { convertAgreementsResult } from '@peoplefund/epics/agreement.util';
import { AlertCommonError, convertInAPICommonErrorToAlertCommonError } from '@peoplefund/constants/error/type';
import { getAgreementsFromS3, setAgreements, setError } from '@peoplefund/slices/agreementSlice';
import { getAgreementsLatestHistory, sendAgreements, setApplyAgreementSent } from '@peoplefund/slices/ml-loan';
import { PhoenixErrorCode } from '@peoplefund/constants/error/code';

const fetchAgreementsEpic: CometEpic = (action$, _, { cometAjax }) =>
	action$.pipe(
		ofAction(actions.lsCommon.fetchAgreements.started),
		mergeMap(({ payload }) => {
			const sceneName = payload;
			if (!sceneName) {
				return EMPTY;
			}

			return concat(
				of(actions.layout.startLoading()),
				cometAjax.newStatic.get(`/files/terms-set/${sceneName}.json`).pipe(
					map((response) => {
						return actions.lsCommon.fetchAgreements.done({
							params: payload,
							result: {
								id: sceneName,
								name: sceneName,
								title: response.code ?? '',
								tip: response.tip,
								disagreements: [],
								agreements: convertAgreementsResult(response.termset),
							},
						});
					}),
					catchError((error) =>
						of(
							actions.lsCommon.fetchAgreements.failed({
								params: payload,
								error: convertInAPICommonErrorToAlertCommonError(error),
							})
						)
					)
				),
				of(actions.layout.endLoading())
			);
		})
	);

const sendAgreementsEpic: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(actions.lsCommon.sendAgreements.started),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token = '' },
					},
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					cometAjax.terms
						.post('/v2/agreements', {
							body: {
								set_code: payload.code,
								agree_codes: payload.agreementIds,
								disagree_codes: payload.disagreementIds,
							},
							token,
						})
						.pipe(
							map(() => {
								return actions.lsCommon.sendAgreements.done({
									params: payload,
									result: {},
								});
							}),
							catchError((error) =>
								of(
									actions.lsCommon.sendAgreements.failed({
										params: payload,
										error: convertInAPICommonErrorToAlertCommonError(error),
									})
								)
							)
						),
					of(actions.layout.endLoading())
				);
			}
		)
	);

const getAgreementsForMorgageLoanEpic: CometEpic = (action$, _, { cometAjax }) =>
	action$.pipe(
		ofAction(getAgreementsFromS3),
		mergeMap(({ payload }) => {
			const sceneName = payload;
			return concat(
				of(actions.layout.startLoading()),
				cometAjax.newStatic.get(`/files/terms-set/${sceneName}.json`).pipe(
					map((response) => {
						return setAgreements({
							sceneKey: sceneName,
							agreements: convertAgreementsResult(response.termset),
						});
					}),
					catchError(() =>
						of(
							setError(
								new AlertCommonError(
									'약관 정보를 불러오는데 실패했습니다.\n잠시후 다시 시도해주세요.',
									'',
									'앗, 에러가 발생했습니다.'
								)
							)
						)
					)
				),
				of(actions.layout.endLoading())
			);
		})
	);

const sendAgreementsForMorgageLoanEpic: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(sendAgreements),
		withLatestFrom(state$),
		mergeMap(
			([
				,
				{
					account: {
						auth: { token },
					},
					mlLoan: {
						apply: { code, agreements, disagreements },
					},
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					cometAjax.terms
						.post('/v2/agreements', {
							body: {
								set_code: code,
								agree_codes: agreements,
								disagree_codes: disagreements,
							},
							token,
						})
						.pipe(
							map(() => setApplyAgreementSent()),
							catchError(() =>
								of(
									setError(
										new AlertCommonError(
											'약관동의에 실패했습니다.\n잠시후 다시 시도해주세요.',
											'',
											'앗, 에러가 발생했습니다.'
										)
									)
								)
							)
						),
					of(actions.layout.endLoading())
				);
			}
		)
	);

const getAgreementsLatestHistoryEpic: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(getAgreementsLatestHistory),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { termsCode },
				},
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				if (!token) {
					return of(setError(new AlertCommonError('로그인이 필요한 기능입니다.', PhoenixErrorCode.LOGIN_REQUIRED)));
				}

				return concat(
					of(actions.layout.startLoading()),
					cometAjax.newStatic
						.get(`/v1/agreements/${termsCode}/latest-history`, { token })
						.pipe(catchError((error) => of(setError(error)))),
					of(actions.layout.endLoading())
				);
			}
		)
	);

export default [
	fetchAgreementsEpic,
	sendAgreementsEpic,
	getAgreementsForMorgageLoanEpic,
	sendAgreementsForMorgageLoanEpic,
	getAgreementsLatestHistoryEpic,
];
