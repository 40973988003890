import { catchError, debounceTime, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ofAction } from '@peoplefund/utils/redux.util';
import {
	fetchCartList,
	fetchMaxInvestableAmountAndDeposit,
	fetchNewProductNotificationSetting,
	fetchPointInfo,
	fetchProductFilterOptions,
	fetchProductInfo,
	fetchProductList,
	fetchServerTimeForInvestment,
	fetchTagGuideList,
	fetchUserAccountExtraInformation,
	fetchUserAccountInfo,
	finishAddCartResult,
	finishDeleteSelectedCartItems,
	finishFetchCartList,
	finishFetchServerTimeForInvestment,
	finishInvestCancelDone,
	finishInvestChanged,
	finishNotificateSelectedCartItem,
	finishProductInfo,
	MortgageInvestProductListItem,
	postNewProductNotificationSetting,
	setCartListCount,
	setError,
	setMaxInvestableAmountAndDeposit,
	setNewProductNotificationSetting,
	setPageDatas,
	setPointInfo,
	setProductFilterOptions,
	setProductList,
	setSelectedCartList,
	setTagGuideList,
	setUserAccountExtraInformation,
	setUserAccountInfo,
	startAddCart,
	startDeleteSelectedCartItems,
	startInvestCancel,
	startInvestChange,
	startNotificateSelectedCartItem,
} from '@peoplefund/slices/ml-investing';
import { CometEpic } from './constants.util';
import { concat, EMPTY, Observable, of } from 'rxjs';
import actions from '@peoplefund/actions';
import { getUserId } from '@peoplefund/utils/jwt.util';
import { AlertCommonError } from '@peoplefund/constants/error/type';
import { PhoenixErrorCode } from '@peoplefund/constants/error/code';
import { convertCartItemToDisplayCartItem, convertProductResponse } from './ml-investing.util';
import { CometAjax } from './ajax.util';
import { findComponentInPageData } from './pl-investing.util';
import { PageData, setInvestedProductList, startInvestment } from '@peoplefund/slices/common-investing';
import {
	GetCartProductListServerResponse,
	InvestementProductListServerResponse,
	MortgageInvestmentProductFilterServerResponse,
	MortgageInvestmentTagGuideListServerResponse,
	ServerTimeForInvestmentServerResponse,
	UserAccountInformationApiServerResponse,
} from '@peoplefund/slices/ml-investing.model';
import { encryptObservable, getCsrfToken } from './account/index.util';
import { Action } from 'redux';
import { MortgageInvestmentBulkServerResponse } from '@peoplefund/slices/common-investing.model';
import { getInvestableCartItem } from '@peoplefund/components/investing/hooks/useMortgageInvestCartClient/index.logic';

export const RANGE_DELIMITER = '-';
export const SELECTED_DELIMITER = ',';

const getInvestmentInfo = (
	token: string,
	userId: number,
	loanApplicationId: number,
	cometAjax: CometAjax<any>
): Observable<number> =>
	cometAjax.inapi
		.get(
			`/secured/v1/investments/?user_id=${userId}&investment_complete__in=investment_complete_in=Y,N&loan_application_id=${loanApplicationId}`,
			{ token }
		)
		.pipe(
			map((response) => {
				const { results } = response;

				if (!results.length) {
					throw new AlertCommonError('투자한 적이 없는 상품이에요', PhoenixErrorCode.IS_NOT_INVESTOR);
				}
				return Number(results[0].investment_id);
			})
		);

const investmentCancelEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(startInvestCancel),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { action, loanApplicationId },
				},
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				const userId = getUserId(token ?? '');

				if (!userId) {
					return of(setError(new AlertCommonError('로그인이 필요한 기능입니다.', PhoenixErrorCode.LOGIN_REQUIRED)));
				}

				return concat(
					of(actions.layout.startLoading()),
					getInvestmentInfo(token ?? '', userId, loanApplicationId, cometAjax).pipe(
						mergeMap((investmentId) => {
							return cometAjax.inapi.post(`/secured/v1/investments/${investmentId}/cancel/`, { token }).pipe(
								mergeMap(() => {
									action({ canceled: true, networkStatus: 'success' });
									return concat([finishInvestCancelDone()]);
								})
							);
						}),
						catchError((error) => {
							action({ canceled: false, networkStatus: 'fail' });
							return of(setError(error));
						})
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const investmentChangeEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(startInvestChange),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { action, loanApplicationId, subInvestmentPlatform, amount, pointAmount },
				},
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				const userId = getUserId(token ?? '');

				if (!userId) {
					return of(setError(new AlertCommonError('로그인이 필요한 기능입니다.', PhoenixErrorCode.LOGIN_REQUIRED)));
				}

				return concat(
					of(actions.layout.startLoading()),
					getCsrfToken(cometAjax, token).pipe(
						mergeMap((csrfToken) => {
							return getInvestmentInfo(token ?? '', userId, loanApplicationId, cometAjax).pipe(
								mergeMap((investmentId) => {
									return cometAjax.inapi
										.patch(`/secured/v2/users/:user_id/investments/${investmentId}`, {
											token,
											body: {
												sub_investment_platform: subInvestmentPlatform,
												amount: amount,
												point_amount: pointAmount,
											},
											headers: {
												'X-CSRF-TOKEN': csrfToken,
											},
										})
										.pipe(
											mergeMap(() => {
												action({ changed: true, networkStatus: 'success' });
												return concat([
													finishInvestChanged(),
													fetchMaxInvestableAmountAndDeposit({ loanApplicationId }),
													fetchPointInfo({ userId }),
												]);
											})
										);
								}),
								catchError((error) => {
									action({ changed: false, networkStatus: 'fail' });
									return of(setError(error));
								})
							);
						})
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const deleteSelectedCartItemsEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(startDeleteSelectedCartItems),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { action, idList },
				},
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				const userId = getUserId(token ?? '');

				if (!userId) {
					return of(setError(new AlertCommonError('로그인이 필요한 기능입니다.', PhoenixErrorCode.LOGIN_REQUIRED)));
				}

				return concat(
					of(actions.layout.startLoading()),
					cometAjax.inapi
						.delete('/secured/users/:user_id/cart-investments', {
							token,
							body: {
								id_list: idList,
							},
						})
						.pipe(
							mergeMap(() => {
								action({ deleted: true, networkStatus: 'success' });
								return of(finishDeleteSelectedCartItems());
							}),
							catchError((error) => {
								action({ deleted: false, networkStatus: 'fail' });
								return of(setError(error));
							})
						),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const notificateSelectedCartItemEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(startNotificateSelectedCartItem),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { action, params },
				},
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				const userId = getUserId(token ?? '');

				if (!userId) {
					return of(setError(new AlertCommonError('로그인이 필요한 기능입니다.', PhoenixErrorCode.LOGIN_REQUIRED)));
				}

				return concat(
					// of(actions.layout.startLoading()),
					cometAjax.investmentGateway
						.post(`/api/v1/products/${params.productId}/funding-start-notification/update`, {
							token,
							body: {
								is_active: params.isActive,
								entry_point: params.entryPoint,
							},
						})
						.pipe(
							mergeMap(() => {
								action({ networkStatus: 'success' });
								return of(finishNotificateSelectedCartItem());
							}),
							catchError((error) => {
								action({ networkStatus: 'fail' });
								return of(setError(error));
							})
						)
					// of(actions.layout.endLoading())
				);
			}
		)
	);
};

const fetchProductInfoEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(fetchProductInfo),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { action, uri },
				},
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				let query = '';

				if (token) {
					const userId = getUserId(token);
					query += `?investor_id=${userId}`;
				}

				return concat(
					of(actions.layout.startLoading()),
					cometAjax.inapi.get(`/showcase/v1/loan_applications/${uri}/${query}`, { token }).pipe(
						mergeMap((response) => {
							return of(convertProductResponse(response)).pipe(
								mergeMap((productInfo) =>
									cometAjax.investment.get(`/products/${productInfo.legacyId}`).pipe(
										mergeMap((productDataFromInvestmentServer) => {
											return cometAjax.investment.post(`/products/${productInfo.legacyId}`).pipe(
												mergeMap((pageDataResponse) => {
													const pageDatas: PageData[] = pageDataResponse.contents ?? [];
													const componentToPropsMatchingMap: Record<string, any> = {
														ProgressItem: {
															percentage: Number(
																((productInfo.accumulatedAmount / productInfo.targetAmount) * 100).toFixed(1)
															),
															accumulatedAmount: productInfo.accumulatedAmount ?? 0,
															targetAmount: productInfo?.targetAmount ?? 0,
														},
														AddressMap: {
															location: productInfo?.location ?? '',
														},
														CertifiedButton: {
															certifiedCopyOfRegister: productInfo?.certifiedCopyOfRegister,
															certifiedCopyOfRegisterAfterDisburse: productInfo?.certifiedCopyOfRegisterAfterDisburse,
														},
														TagList: {
															tags: productDataFromInvestmentServer.tags,
														},
													};

													for (const key in componentToPropsMatchingMap) {
														findComponentInPageData(pageDatas, key, componentToPropsMatchingMap[key]);
													}

													action({
														value: { ...productInfo, name: productDataFromInvestmentServer.name },
														networkStatus: '',
													});
													return [finishProductInfo(), setPageDatas({ pageDatas: pageDataResponse.contents })];
												})
											);
										})
									)
								)
							);
						}),
						catchError((error) => {
							if (error.code === 'INAPI0404') {
								return of(
									setError(new AlertCommonError('딜페이지가 존재하지 않습니다.', PhoenixErrorCode.DEALPAGE_NOT_FOUND))
								);
							}
							return of(setError(error));
						})
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const fetchProductListEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(fetchProductList),
		debounceTime(100),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: {
						page,
						size,
						terms,
						productTypes,
						yieldRateRange,
						ltvRatioRange,
						fundraisingRateRange,
						sido,
						sigungu,
						tags,
						status,
						orderBy,
					},
				},
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				const queryList = [];

				if (page) {
					queryList.push(`page=${page}`);
				}
				if (size) {
					queryList.push(`size=${size}`);
				}
				if (status && status.length) {
					queryList.push(`status=${status}`);
				}
				if (orderBy) {
					queryList.push(`order_by=${orderBy}`);
				}
				if (tags && tags.length) {
					queryList.push(`tag=${tags.join(SELECTED_DELIMITER)}`);
				}
				if (yieldRateRange) {
					queryList.push(`yield_rate_range=${yieldRateRange.join(RANGE_DELIMITER)}`);
				}
				if (ltvRatioRange) {
					queryList.push(`ltv_ratio_range=${ltvRatioRange.join(RANGE_DELIMITER)}`);
				}
				if (sido) {
					queryList.push(`sido=${sido}`);
				}
				if (sigungu) {
					queryList.push(`sigungu=${sigungu}`);
				}
				if (fundraisingRateRange) {
					queryList.push(`raised_rate_range=${fundraisingRateRange.join(RANGE_DELIMITER)}`);
				}
				if (productTypes && productTypes.length) {
					queryList.push(`product_type=${productTypes.join(SELECTED_DELIMITER)}`);
				}
				if (terms && terms.length) {
					queryList.push(`term=${terms.join(SELECTED_DELIMITER)}`);
				}

				const query = queryList.length ? `?${queryList.join('&')}` : '';

				return concat(
					of(actions.layout.startLoading()),
					cometAjax.investment.get(`/products${query}`, { token }).pipe(
						mergeMap((listResponse: InvestementProductListServerResponse) => {
							const list = listResponse.items.map((product) => {
								const {
									funding_amount,
									funding_end_datetime,
									funding_start_datetime,
									raised_amount,
									remain_amount,
									yield_rate,
									ltv_ratio,
									product_type,
									is_closed,
									is_investable,
								} = product;
								return {
									...product,
									productType: product_type,
									fundingAmount: funding_amount,
									fundingStartDatetime: funding_start_datetime,
									fundingEndDatetime: funding_end_datetime,
									raisedAmount: raised_amount,
									remainAmount: remain_amount,
									yieldRate: yield_rate,
									ltvRatio: ltv_ratio,
									isClosed: is_closed,
									isInvestable: is_investable,
								};
							});
							return of(setProductList(list));
						}),
						catchError((error) => of(setError(error)))
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const fetchNewProductNotificationSettingEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(fetchNewProductNotificationSetting),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload: props },
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				if (!token) {
					return EMPTY;
				}

				const { action, assetProductType } = props;

				return concat(
					of(actions.layout.startLoading()),
					cometAjax.million
						.get(`/v1/users/notification-settings/new-investment-product?asset_product_type=${assetProductType}`, {
							token,
						})
						.pipe(
							mergeMap((data) => {
								action({
									isAgreed: data.notification_item.is_agreed,
									isOn: data.notification_item.is_on,
									bannerImage: data.notification_item.banner_image,
								});

								return of(
									setNewProductNotificationSetting({
										notificationItem: {
											isAgreed: data.notification_item.is_agreed,
											isOn: data.notification_item.is_on,
											bannerImage: data.notification_item.banner_image,
										},
									})
								);
							}),
							catchError((error) => of(setError(error)))
						),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const onNewProductNotificationSettingEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(postNewProductNotificationSetting),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload: props },
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				if (!token) {
					return of(setError(new AlertCommonError('로그인이 필요한 기능입니다.', PhoenixErrorCode.LOGIN_REQUIRED)));
				}

				const { action, assetProductType } = props;
				action({ networkStatus: 'idle' });

				return concat(
					of(actions.layout.startLoading()),
					cometAjax.million
						.post(`/v1/users/notification-settings/new-investment-product/on`, {
							token,
							body: { asset_product_type: assetProductType },
						})
						.pipe(
							mergeMap(() => {
								action({ networkStatus: 'success' });
								return EMPTY;
							}),
							catchError((error) => {
								action({ networkStatus: 'fail' });
								return of(setError(error));
							})
						),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const fetchTagGuideListEpic: CometEpic = (action$, _, { cometAjax }) => {
	return action$.pipe(
		ofAction(fetchTagGuideList),
		mergeMap(() => {
			return concat(
				of(actions.layout.startLoading()),
				cometAjax.investment.get(`/products/tags`).pipe(
					mergeMap((response: MortgageInvestmentTagGuideListServerResponse) => {
						const list = response.tags;
						return of(setTagGuideList(list));
					}),
					catchError((error) => of(setError(error)))
				),
				of(actions.layout.endLoading())
			);
		})
	);
};

const fetchProductFilterOptionsEpic: CometEpic = (action$, _, { cometAjax }) => {
	return action$.pipe(
		ofAction(fetchProductFilterOptions),
		mergeMap(() => {
			return concat(
				of(actions.layout.startLoading()),
				cometAjax.investment.get(`/products/filters`).pipe(
					mergeMap((response: MortgageInvestmentProductFilterServerResponse) => {
						const { yield_rates, ltv_ratios, funding_rates, product_types } = response.filters;
						const options = {
							...response.filters,
							yieldRates: yield_rates,
							ltvRatios: ltv_ratios,
							fundingRates: funding_rates,
							productTypes: product_types,
						};
						return of(setProductFilterOptions(options));
					}),
					catchError((error) => of(setError(error)))
				),
				of(actions.layout.endLoading())
			);
		})
	);
};

const fetchCartListEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(fetchCartList),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { action },
				},
				{
					account: {
						auth: { token },
					},
					mlInvesting: { selectedCartList },
				},
			]) => {
				if (!token) {
					return of(setError(new AlertCommonError('로그인이 필요한 기능입니다.', PhoenixErrorCode.LOGIN_REQUIRED)));
				}
				const query = '?page=1&page_size=100';

				return concat(
					of(actions.layout.startLoading()),
					cometAjax.investmentGateway
						.get(`/api/v1/carts${query}`, {
							token,
						})
						.pipe(
							mergeMap((response: GetCartProductListServerResponse) => {
								const convertedList = convertCartItemToDisplayCartItem(response.results);

								action({ value: convertedList, networkStatus: 'success' });

								const actions: Action[] = [finishFetchCartList(), setCartListCount(convertedList.length)];
								const list: MortgageInvestProductListItem[] =
									selectedCartList.list.length === 0 ? convertedList.filter(getInvestableCartItem) : [];

								convertedList.forEach((item) => {
									const { cartId } = item;
									const selected = selectedCartList.list?.find((selectedItem) => selectedItem.cartId === cartId);
									if (selected) {
										list.push(item);
									}
								});

								actions.push(
									setSelectedCartList({
										list,
										status: 'success',
									})
								);

								return of(...actions);
							}),
							catchError((error) => {
								action({ value: [], networkStatus: 'fail' });

								return of(
									setError(error),
									finishFetchCartList(),
									setSelectedCartList({
										list: [],
										status: 'fail',
									})
								);
							})
						),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const fetchMaxInvestableAmountAndDepositEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(fetchMaxInvestableAmountAndDeposit),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { loanApplicationId },
				},
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				if (!token) {
					return of(setError(new AlertCommonError('로그인이 필요한 기능입니다.', PhoenixErrorCode.LOGIN_REQUIRED)));
				}

				const request = '?is_pre_raising=True';

				return concat(
					of(actions.layout.startLoading()),
					cometAjax.inapi
						.get(
							`/secured/v1/users/:user_id/loan_applications/${loanApplicationId}/investments/max_investable_amount/${request}`,
							{ token }
						)
						.pipe(
							mergeMap((maxInvestableAmountAndDeposit) => {
								const { max_investable_amount, cash } = maxInvestableAmountAndDeposit;
								return of(setMaxInvestableAmountAndDeposit({ maxInvestableAmount: max_investable_amount, cash }));
							}),
							catchError((error) =>
								of(setError(error), setMaxInvestableAmountAndDeposit({ maxInvestableAmount: -1, cash: -1 }))
							)
						),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

export interface PointInfoServerResponse {
	id: number;
	balance: number;
	to_expire_amount: number;
}
const fetchPointInfoEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(fetchPointInfo),
		withLatestFrom(state$),
		mergeMap(
			([
				,
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				if (!token) {
					return of(setError(new AlertCommonError('로그인이 필요한 기능입니다.', PhoenixErrorCode.LOGIN_REQUIRED)));
				}

				return concat(
					of(actions.layout.startLoading()),
					cometAjax.point.get(`/api/v1/user-balances-with-expire/:user_id/`, { token }).pipe(
						mergeMap((pointInfo: PointInfoServerResponse) => {
							const { id, balance, to_expire_amount } = pointInfo;
							return of(setPointInfo({ id, balance, toExpireAmount: to_expire_amount }));
						}),
						catchError((error) => of(setError(error), setPointInfo({ balance: -1, id: -1, toExpireAmount: -1 })))
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};
const investmentEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(startInvestment),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { investmentProductList, entryPoint },
				},
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				const userId = getUserId(token ?? '');

				if (!userId) {
					return of(setError(new AlertCommonError('로그인이 필요한 기능입니다.', PhoenixErrorCode.LOGIN_REQUIRED)));
				}

				return concat(
					of(actions.layout.startLoading()),
					encryptObservable(
						cometAjax,
						{
							investment_sub_platform: 'WEB',
							invest_entry: entryPoint,
							investments: investmentProductList.map((item) => ({
								loan_application_id: item.loanApplicationId,
								amount: item.amount,
								point_amount: item.pointAmount,
							})),
						},
						token
					).pipe(
						mergeMap(({ eData, headers }) =>
							cometAjax.inapi
								.post(`/secured/v1/users/:user_id/investments/bulk`, {
									token,
									headers,
									body: {
										eData,
									},
								})
								.pipe(
									map((response: MortgageInvestmentBulkServerResponse) => {
										const { results } = response;
										return setInvestedProductList({
											list: results.map((item) => ({
												investedAmount: item.amount,
												name: item.product_title,
												isSuccessful: item.invested,
												uri: item.product_uri,
												productType: '',
											})),
											status: 'success',
										});
									})
								)
						),
						catchError((error) =>
							of(
								setError(error),
								setInvestedProductList({
									list: [],
									status: 'fail',
								})
							)
						)
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const addCartEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(startAddCart),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { action, productInformation },
				},
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				if (!token) {
					return of(setError(new AlertCommonError('로그인이 필요한 기능입니다.', PhoenixErrorCode.LOGIN_REQUIRED)));
				}

				return concat(
					of(actions.layout.startLoading()),
					cometAjax.inapi
						.post('/secured/users/:user_id/cart-investments', {
							token,
							body: {
								loan_application_id: productInformation.loanApplicationId,
								invest_amount: productInformation.investAmount,
							},
						})
						.pipe(
							mergeMap(() => {
								action({ added: true, networkStatus: 'success' });
								return concat([finishAddCartResult()]);
							}),
							catchError((error) => {
								action({ added: false, networkStatus: 'fail' });
								return of(setError(error));
							})
						),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const fetchUserAccountInformationEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(fetchUserAccountInfo),
		withLatestFrom(state$),
		mergeMap(
			([
				,
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				const defaultUserAccountInfoAction = setUserAccountInfo({
					cash: -1,
					accountInvestBankName: '',
					accountInvestNumFormatted: '',
					accountVirtualBankCode: '',
					accountVirtualBankName: '',
					accountVirtualHolder: '',
					accountVirtualNum: '',
					accountVirtualNumFormatted: '',
					accountInvestHolder: '',
				});
				if (!token) {
					return of(
						setError(new AlertCommonError('로그인이 필요한 기능입니다.', PhoenixErrorCode.LOGIN_REQUIRED)),
						defaultUserAccountInfoAction
					);
				}
				return concat(
					of(actions.layout.startLoading()),
					cometAjax.inapi.get(`/showcase/users/:user_id/balances/summary/`, { token }).pipe(
						mergeMap((userAccountInformation: UserAccountInformationApiServerResponse) => {
							const {
								cash,
								account_invest_bank_name,
								account_invest_num_formatted,
								account_invest_holder,
								account_virtual_bank_code,
								account_virtual_bank_name,
								account_virtual_holder,
								account_virtual_num,
								account_virtual_num_formatted,
							} = userAccountInformation;
							return of(
								setUserAccountInfo({
									cash,
									accountInvestBankName: account_invest_bank_name,
									accountInvestNumFormatted: account_invest_num_formatted,
									accountVirtualBankCode: account_virtual_bank_code,
									accountVirtualBankName: account_virtual_bank_name,
									accountVirtualHolder: account_virtual_holder,
									accountVirtualNum: account_virtual_num,
									accountVirtualNumFormatted: account_virtual_num_formatted,
									accountInvestHolder: account_invest_holder,
								})
							);
						}),
						catchError((error) => of(setError(error), defaultUserAccountInfoAction))
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const fetchUserAccountExtraInformationEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(fetchUserAccountExtraInformation),
		withLatestFrom(state$),
		mergeMap(
			([
				,
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				if (!token) {
					return of(setError(new AlertCommonError('로그인이 필요한 기능입니다.', PhoenixErrorCode.LOGIN_REQUIRED)));
				}

				const query = '?request_data=max_investable_amount|max_investable_amount_real_estate';

				return concat(
					of(actions.layout.startLoading()),
					cometAjax.inapi.get(`/account/users/:user_id/extra/${query}`, { token }).pipe(
						mergeMap((extraInfo) => {
							const { max_investable_amount, max_investable_amount_real_estate } = extraInfo.data;
							return of(
								setUserAccountExtraInformation({
									maxInvestableAmount: max_investable_amount,
									maxInvestableAmountRealEstate: max_investable_amount_real_estate,
								})
							);
						}),
						catchError((error) =>
							of(
								setError(error),
								setUserAccountExtraInformation({ maxInvestableAmount: 0, maxInvestableAmountRealEstate: 0 })
							)
						)
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const fetchServerTimeForInvestmentEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(fetchServerTimeForInvestment),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { action },
				},
			]) => {
				return concat(
					cometAjax.inapi.get('/utils/server-time').pipe(
						mergeMap((response: ServerTimeForInvestmentServerResponse) => {
							const { server_datetime } = response;
							action({ time: server_datetime, networkStatus: 'success' });

							return of(finishFetchServerTimeForInvestment());
						}),
						catchError(() => {
							action({ time: '', networkStatus: 'fail' });
							return of(finishFetchServerTimeForInvestment());
						})
					)
				);
			}
		)
	);
};

export default [
	investmentCancelEpic,
	investmentChangeEpic,
	deleteSelectedCartItemsEpic,
	notificateSelectedCartItemEpic,
	fetchProductInfoEpic,
	fetchProductListEpic,
	fetchTagGuideListEpic,
	fetchProductFilterOptionsEpic,
	fetchCartListEpic,
	fetchMaxInvestableAmountAndDepositEpic,
	fetchPointInfoEpic,
	fetchUserAccountInformationEpic,
	fetchUserAccountExtraInformationEpic,
	fetchServerTimeForInvestmentEpic,
	investmentEpic,
	addCartEpic,
	fetchNewProductNotificationSettingEpic,
	onNewProductNotificationSettingEpic,
];
