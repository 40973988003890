const baseUrl = '/loans/autopay';

const LoansAutoPayUrls = {
	INTRO: `${baseUrl}`,
	VERIFY: `${baseUrl}/verify`,
	LIST: `${baseUrl}/list`,
	AGREEMENT: `${baseUrl}/agreement`,
	CHANGE_BANK_ACCOUNT: `${baseUrl}/change-bank-account`,
	REGISTER: `${baseUrl}/register`,
	REGISTER_ARS: `${baseUrl}/register/ars`,
	REGISTER_COMPLETE: `${baseUrl}/register/complete`,
};

export default LoansAutoPayUrls;
