import { ErrorCodeMap } from '@peoplefund/epics/common';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FETCH_STATUS } from './ml-loan.model';

const SLICE_NAME = 'common';

export interface ErrorJsonResponse {
	json: ErrorCodeMap;
	networkStatus: FETCH_STATUS;
}

export type BankCodeInfo = {
	readonly bankCode: string;
	readonly bankName: string;
	readonly bankFullName: string;
	readonly bankType: BankType;
	readonly bankLogo: string;
	readonly isPasswordVerificationAvailable: boolean;
	readonly isActive: boolean;
};

export enum BankType {
	'은행' = '00',
	'증권사' = '01',
}

export type CommonState = {
	bankCodes: BankCodeInfo[];
};

export const commonInitialState: CommonState = {
	bankCodes: [],
};

export const fetchErrorJson = createAction<{ callbackAction: (props: ErrorJsonResponse) => void }>(
	`${SLICE_NAME}/fetchErrorJson`
);
export const finishFetchErrorJson = createAction(`${SLICE_NAME}/finishFetchErrorJson`);

export const commonSlice = createSlice({
	name: SLICE_NAME,
	initialState: commonInitialState,
	reducers: {
		initialize: (state) => Object.assign(state, commonInitialState),
		setBankCodes: (state, { payload }: PayloadAction<BankCodeInfo[]>) => {
			state.bankCodes = payload;
		},
	},
});
export const { initialize, setBankCodes } = commonSlice.actions;

export default commonSlice.reducer;
