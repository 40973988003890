import actions from '@peoplefund/actions';
import { InAPICommonError } from '@peoplefund/constants/error/type';
import { concat, of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ofAction } from '@peoplefund/utils/redux.util';
import { CometEpic } from '@peoplefund/epics/constants.util';

export const serveySend: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(actions.unsecuredInvestorRegistration.send.started),
		withLatestFrom(state$),
		mergeMap(([{ payload }, { account }]) =>
			concat(
				of(actions.layout.startLoading()),
				cometAjax.inapi
					.post(`/unsecured/investment_request/`, {
						token: account.auth.token,
						body: { ...payload },
					})
					.pipe(
						map((response) =>
							actions.unsecuredInvestorRegistration.send.done({
								params: payload,
								result: response,
							})
						),
						catchError((error: InAPICommonError) =>
							of(
								actions.unsecuredInvestorRegistration.send.failed({
									params: payload,
									error,
								})
							)
						)
					),
				of(actions.layout.endLoading())
			)
		)
	);

export default [serveySend];
